import { RequestsQueryCoordinator } from './../../services/requests-query-coordinator'
import { RequestsTableFactory } from './../../factories/requests-table.factory'
import { RequestsViewModel } from './../../viewmodels/requests-view-model'
import { Component, OnInit } from '@angular/core'
import { RequestsFormFactory } from '../../factories/requests-form-factory'

@Component({
    selector: 'pfr-request',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss'],
    providers: [RequestsFormFactory, RequestsTableFactory, RequestsViewModel, RequestsQueryCoordinator],
})
export class RequestsComponent implements OnInit {
    constructor(readonly viewModel: RequestsViewModel, readonly tableFactory: RequestsTableFactory) {}

    ngOnInit() {
        this.viewModel.init()
        this.viewModel.checkTrialPermissions()
    }
}
