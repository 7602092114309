import { ChangeDetectorRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Directive } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSort, Sort } from '@angular/material/sort'
import { formatDate } from '@angular/common'
import { AppLocaleProvider } from '../../../core/i18n/locale.provider'

import { get } from 'lodash'

import {
    ColumnDefinition,
    ColumnFormat,
    FileLink,
    TableAction,
    TableActionStyle,
    TableActionConfig,
    TableDefinition,
    TableQueryParams,
    KeySet,
    Disposables,
    ExceptionMessageFormatter,
    TypeHelper,
    SerializedOperationQueue,
    MigrationHelper,
    IconStatusHelper,
} from '@plantandfood/kup.core'

import { UsersMigrationHelper } from './users-migration-helper'

interface ImportStatus {
    matchStatus: string
    ligature: string
}

export const IMPORT_STATUSES: ImportStatus[] = [
    {
        matchStatus: 'Complete',
        ligature: 'complete',
    },
    {
        matchStatus: 'Pending processing',
        ligature: 'processing',
    },
    {
        matchStatus: 'Complete with errors',
        ligature: 'complete_with_errors',
    },
    {
        matchStatus: 'Uploading, Please Wait...',
        ligature: 'processing',
    },
    {
        matchStatus: 'Processing',
        ligature: 'processing',
    },
]

export class ImportStatusHelper {
    static getStatusLigature(status: string): string {
        const statusIcon = IMPORT_STATUSES.find(s => s.matchStatus === status)
        return statusIcon ? statusIcon.ligature : 'fatal_error'
    }
}

export class ColumnCheckboxChange {
    constructor(readonly column: string, readonly checked: boolean) {}
}

export class TableCheckboxChange<TData> extends ColumnCheckboxChange {
    constructor(column: string, checked: boolean, readonly data: TData) {
        super(column, checked)
    }
}

export type ColumnFormatResolver<TData> = (item: TData, columnDef: string) => ColumnFormat

interface ValueResolverDictionary {
    [key: string]: (model: any) => any
}

interface TableActionDictionary {
    [key: string]: TableActionStyle
}

const DefaultActions: TableActionDictionary = {
    delete: {
        svgIcon: 'delete',
        color: 'primary',
        tooltip: 'Delete',
        class: 'delete-button',
    },
    add: {
        icon: 'add_circle',
        color: 'primary',
        tooltip: 'Add',
        class: 'add-button',
    },
    clone: {
        svgIcon: 'clone_entity',
        tooltip: 'Clone',
        class: 'clone-button',
    },
}

/**
 * Creates valid id from string
 */
function idfy(s: string): string {
    return s.replace(/([^A-Za-z0-9[\]{}_.:-])\s?/g, '')
}

@Directive()
export abstract class SimpleTableComponentBaseDirective implements OnInit, OnDestroy {
    private _disposables: Disposables = new Disposables()
    private _initialising = true
    private _loading = false
    private _opQueue = new SerializedOperationQueue(true)
    private _activePageSize = 20
    private _defaultExportPageSize = 2147483647
    private _masterPaginator: MatPaginator
    private _actions: TableAction[]
    private _tableDefinition: TableDefinition
    private _selectedColumns = new KeySet()
    private _domParser: DOMParser
    readonly pageSizeOptions: number[] = [20, 50, 100]

    @ViewChild('topPaginator')
    topPaginator: MatPaginator
    @ViewChild('bottomPaginator')
    bottomPaginator: MatPaginator
    @ViewChild(MatSort)
    sort: MatSort

    displayedColumns: string[]
    columns: ColumnDefinition[]
    sortDirection: string
    sortActive: string

    @Input()
    readOnly: boolean
    @Input()
    selectable: boolean
    @Input() dateFormat = 'dd/MM/yyyy'
    @Input() dateTimeFormat = 'dd/MM/yyyy HH:mm'
    @Input()
    paged = true
    @Input() canDrag = false
    /**
     * If true, page parameters (page, size, sort, etc) are not written to the active route's query parameter collection.
     */
    @Input()
    suppressPageParams = false
    @Input() hasActions = false
    @Input()
    set actions(actionConfig: TableActionConfig) {
        this._actions = Object.keys(actionConfig).map<TableAction>((name: string) => {
            let action: TableAction = <any>actionConfig[name]
            if (typeof action === 'function') {
                action = {
                    name: name,
                    handlerFn: action,
                    class: `${name}-button`,
                }
            } else {
                action.name = name
                action.class = action.class || `${name}-button`
            }

            return action
        })
    }
    @Input() resolvers: ValueResolverDictionary
    @Input() hasSearch = false
    @Input() disabledEvalFn: (item: any) => boolean
    @Input() warningEvalFn: (item: any) => boolean
    @Input() subtleEvalFn: (item: any) => boolean
    /** An optional function used to resolve a formatter for dynamically formatted columns  */
    @Input() columnFormatter: ColumnFormatResolver<any>
    /** An optional id key prop */
    @Input() idKey?: string = 'uuid'
    @Input() id?: string

    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter()
    @Output() sortChange: EventEmitter<Sort> = new EventEmitter()
    @Output()
    selectRow: EventEmitter<any> = new EventEmitter()
    @Output()
    deleteRow: EventEmitter<any> = new EventEmitter()
    @Output() clickFileLink: EventEmitter<FileLink> = new EventEmitter()
    @Output()
    columnSelect: EventEmitter<ColumnCheckboxChange> = new EventEmitter()
    @Output()
    checkboxSelect: EventEmitter<TableCheckboxChange<any>> = new EventEmitter()

    constructor(protected readonly changeDetector: ChangeDetectorRef, protected readonly usersFacade: UsersMigrationHelper, protected readonly activeNavFacade: MigrationHelper) {
        const pageSize = usersFacade.currentProfile.userData.defaultPageSize
        if (pageSize != undefined) {
            this._activePageSize = pageSize
        }
        // TODO: Refactor to remove the row.isDisabled evaluation.
        // We should not expect the model to have to be mutated (potentially violating its interface) in order
        // to effect a change in this component's behaviour. Instead, we should only use the disabledEvalFn.
        this.disabledEvalFn = (row: any) => row.isDisabled === true
    }

    abstract get dataSource(): MatTableDataSource<any> | any[]
    abstract page: any
    abstract hasNoResults(): boolean

    @Input()
    get tableDefinition(): TableDefinition {
        return this._tableDefinition
    }

    @Input() get loading(): boolean {
        return this._loading
    }

    set loading(value: boolean) {
        this.setLoading(value)
    }

    set tableDefinition(value: TableDefinition) {
        if (this._tableDefinition === value) return
        this._tableDefinition = value
        this.loadTableDefinition()
        this.changeDetector.detectChanges()
    }

    get masterPaginator(): MatPaginator {
        return this._masterPaginator
    }

    get activePageSize(): number {
        return this._activePageSize
    }

    protected get domParser(): DOMParser {
        if (this._domParser == undefined) {
            this._domParser = new DOMParser()
        }

        return this._domParser
    }
    get initialising(): boolean {
        return this._initialising
    }

    get showPager(): boolean {
        return this.paged && !this.initialising && !this.hasNoResults()
    }

    get showNoResults(): boolean {
        return !this.initialising && this.hasNoResults()
    }

    protected setInitialising(value: boolean): void {
        this._initialising = value === true
    }

    protected setLoading(value: boolean) {
        this._loading = value === true
    }

    ngOnInit(): void {
        if (this.tableDefinition == undefined) {
            throw new Error(ExceptionMessageFormatter.propertyNotAssigned('tableDefinition', this))
        }

        this._masterPaginator = this.topPaginator

        this._disposables.addSubscription(
            this.topPaginator.page.subscribe((event: PageEvent) => {
                this.onPaginatorEvent(this.topPaginator, event)
            })
        )

        this._disposables.addSubscription(
            this.bottomPaginator.page.subscribe((event: PageEvent) => {
                this.onPaginatorEvent(this.bottomPaginator, event)
            })
        )

        this.loadTableDefinition(true)
        this.hydrateFromParams()
            .then(() => {
                if (!this.paged) {
                    this._masterPaginator.pageSize = this._defaultExportPageSize
                    this._activePageSize = this._defaultExportPageSize
                }
            })
            .then(() => {
                this.sort.sortChange.subscribe((sort: Sort) => {
                    this.sortActive = sort.active
                    this.sortDirection = sort.direction
                    this.resetPaging()
                    this.updateQueryParams()
                    this.sortChange.emit(sort)
                })
                this.updateQueryParams()
                this.replicatePaginator(this.topPaginator, true)
            })
    }

    ngOnDestroy(): void {
        this._disposables.dispose()
    }

    detectChanges(): void {
        this.changeDetector.detectChanges()
    }

    markForCheck(): void {
        this.changeDetector.markForCheck()
    }

    /**
     * The columnTracker method enables dynamic changes to column headers. Without it, changes to columns would not be applied unless the
     * columnDef changed (which would mean the model property couldn't be referenced).
     */
    columnTracker(index: number, _item: any): any {
        return index
    }

    protected onMasterPaginatorChanged(_master: MatPaginator): void {}

    protected resetPaging(): void {
        this.masterPaginator.pageIndex = 0
        this.replicatePaginator(this.masterPaginator)
    }

    protected resyncPaginators(): void {
        if (this.masterPaginator == undefined) return
        // There is currently no public paginator datasource event that is raised when a paginator's state is initially updated
        // after having been connected to a datasource (as with the `SimpleTableComponent`). Furthermore, a paginator's state is
        // not guaranteed to be updated during the same event cycle within which it was assigned to a datasource.
        // For this reason it is necessary to use setTimeout to handle replication to the slave after the framework has propagated the
        // state change.
        this.detectChanges()

        const master = this.masterPaginator
        const slave: MatPaginator = master === this.topPaginator ? this.bottomPaginator : this.topPaginator

        setTimeout(() => {
            slave.length = master.length
            slave.pageIndex = master.pageIndex
            slave.pageSize = master.pageSize
        }, 0)
    }

    getActions(): TableAction[] {
        return this._actions
    }

    stripHTML(htmlFragment: string): string {
        if (htmlFragment == undefined || htmlFragment === '') return ''
        const doc = this.domParser.parseFromString(htmlFragment, 'text/html')
        return doc && doc.body ? doc.body.textContent : ''
    }

    onColumnSelectChange(event: MatCheckboxChange, columnDef: string): void {
        if (event.checked) {
            this._selectedColumns.set(columnDef)
        } else {
            this._selectedColumns.remove(columnDef)
        }
        this.columnSelect.emit(new ColumnCheckboxChange(columnDef, event.checked))
    }

    applyColumnSelected(columnDef: string, selected: boolean): void {
        this._selectedColumns.apply(columnDef, selected)
    }

    selectColumn(columnDef: string): void {
        this._selectedColumns.set(columnDef)
    }

    unSelectColumn(columnDef: string): void {
        this._selectedColumns.remove(columnDef)
    }

    clearSelectedColumns(): void {
        this._selectedColumns.clear()
    }

    getSelectedColumnDefs(): string[] {
        return this._selectedColumns.getKeys()
    }

    isColumnSelected(columnDef: string): boolean {
        return this._selectedColumns.containsKey(columnDef)
    }

    onCheckboxExChange(event: MatCheckboxChange, row: any, columnDef: string): void {
        let target = row[columnDef]
        if (target == undefined) {
            target = {}
            row[columnDef] = target
        }
        target.checked = event.checked
        this.checkboxSelect.emit(new TableCheckboxChange(columnDef, event.checked, row))
    }

    onCheckboxChange(event: MatCheckboxChange, row: any, columnDef: string): void {
        this.checkboxSelect.emit(new TableCheckboxChange(columnDef, event.checked, row))
    }

    resolveValueAsAttribute(row: any, columnDef: string): boolean | undefined {
        return this.resolveValue(row, columnDef) ? true : undefined
    }

    getStatusIcon(status: string): string {
        return status ? ImportStatusHelper.getStatusLigature(status) : undefined
    }

    getTrueIcon(row: any): string {
        return row.operationsPlanTasks.length ? 'complete' : undefined
    }

    getIcon(value: string): string {
        return value ? IconStatusHelper.getStatusLigature(value) : undefined
    }

    getIconTooltip(value: string): string {
        return value ? IconStatusHelper.getStatusTooltip(value) : undefined
    }

    rowIsSubtle(row: any): boolean {
        return evalFnAsBoolean(row, this.subtleEvalFn)
    }

    rowShowWarningIcon(row: any): boolean {
        return evalFnAsBoolean(row, this.warningEvalFn)
    }

    rowHighlightWarning(row: any): boolean {
        return evalFnAsBoolean(row, this.warningEvalFn) && !this.rowIsSubtle(row)
    }

    rowIsReadOnly(row: any): boolean {
        return this.readOnly === true || evalFnAsBoolean(row, this.disabledEvalFn)
    }

    renderCellValue = (row: any, columnDef: string): string => get(row, columnDef)

    resolveValue(row: any, columnDef: string): any {
        const resolver = this.resolvers ? this.resolvers[columnDef] : undefined
        return resolver ? resolver(row) : row[columnDef]
    }

    //#region Common action handler methods

    onClickRow(row): void {
        this.selectRow.emit(row)
    }

    onDeleteRow(event: Event, row): void {
        event.stopPropagation()
        this.deleteRow.emit(row)
    }

    onClickAction(row: any, action: TableAction, event: Event): void {
        event.stopPropagation()
        action.handlerFn(row)
    }

    onClickFile(file: FileLink): void {
        this.clickFileLink.emit({ ...file })
    }

    getActionColour(action: TableAction) {
        if (action.color != undefined) return action.color
        const d = DefaultActions[action.name]
        return d ? d.color : undefined
    }

    hasSvg(action: TableAction): boolean {
        if (action.svgIcon != undefined) return true
        return DefaultActions[action.name] && DefaultActions[action.name].svgIcon ? true : false
    }

    getActionIcon(action: TableAction) {
        if (action.icon != undefined) return action.icon
        if (action.svgIcon != undefined) return action.svgIcon
        const d = DefaultActions[action.name]

        if (d && d.svgIcon != undefined) {
            return d.svgIcon
        }
        if (d && d.icon != undefined) {
            return d.icon
        }
        return undefined
    }

    getColumnTooltip(item: any, column: ColumnDefinition): string {
        if (column.tooltipKey == undefined) return undefined
        const value = item[column.tooltipKey]
        return column.formatter === 'truncate' ? this.stripHTML(value) : value
    }

    getDefaultColumnTooltip(item: any, column: ColumnDefinition): string {
        if (item[column.columnDef] === null) return undefined

        let value = item[column.tooltipKey]

        // Get the correct format before generating the text for tooltip, otherwise tooltip will be generated with raw value.
        let format = this.resolveFormat(item, column)

        if (format === 'truncate') {
            value = this.stripHTML(value)
        } else if (format === 'date') {
            value = this.getShortDateTimeFormat(value, this.dateFormat)
        } else if (format === 'date-time') {
            value = this.getShortDateTimeFormat(value, this.dateTimeFormat)
        }
        return value
    }

    getShortDateTimeFormat(dateTime: string, format: string): string {
        return formatDate(dateTime, format, AppLocaleProvider.useValue)
    }

    getActionTooltip(action: TableAction) {
        if (action.tooltip != undefined) return action.tooltip
        const d = DefaultActions[action.name]
        return d ? d.tooltip : undefined
    }

    resolveFormat(item: any, column: ColumnDefinition): ColumnFormat {
        return column.formatter !== 'dynamic' || this.columnFormatter == undefined ? column.formatter : this.columnFormatter(item, column.columnDef)
    }

    //#endregion

    //#region Private methods

    private updateQueryParams(): void {
        if (this.suppressPageParams) return
        const paginator = this.masterPaginator
        const tableParams: TableQueryParams = {
            page: `${paginator.pageIndex}`,
            size: `${paginator.pageSize}`,
        }

        if (this.sortActive != undefined && this.sortDirection != undefined) {
            tableParams.sort = `${this.sortActive},${this.sortDirection}`
        } else {
            this.activeNavFacade.removeQuery(['sort'])
        }

        this.activeNavFacade.addQuery(tableParams)
    }

    private loadTableDefinition(initial: boolean = false): void {
        // The table definition can be changed dynamically, but we need to prevent the loading step when set prior to ngOnInit.
        if (this.columns == undefined && !initial) return
        this.columns = this.tableDefinition.columns
        this.displayedColumns = this.selectDisplayedColumns(this.columns)
        // Apply default sort on init or revert if existing sort not on current columns
        if (initial || (this.sortActive != undefined && this.columns.find(item => item.columnDef === this.sortActive) == undefined)) {
            this.sortActive = this.tableDefinition.sortActive
            this.sortDirection = this.tableDefinition.sortDirection
        }
    }

    private selectDisplayedColumns(columns: ColumnDefinition[]): string[] {
        const displayedColumns = columns.map(x => x.columnDef)
        if (this.hasActions) {
            displayedColumns.push('actions')
        }
        return displayedColumns
    }

    private onPaginatorEvent(src: MatPaginator, page: PageEvent): void {
        this.replicatePaginator(src)
        this.updateQueryParams()

        if (this.activePageSize !== page.pageSize) {
            this._activePageSize = page.pageSize
            this.persistPageSize(page.pageSize)
        }
        this.pageChange.emit(page)
    }

    private replicatePaginator(master: MatPaginator, forceMasterUpdate: boolean = false): void {
        if (forceMasterUpdate || this._masterPaginator !== master) {
            this._masterPaginator = master
            this.onMasterPaginatorChanged(master)
        }

        this.resyncPaginators()
    }

    private persistPageSize(pageSize: number): void {
        this._opQueue.enqueue(() => {
            return this.usersFacade.saveQueryPageSize(pageSize)
        })
    }

    private hydrateFromParams(): Promise<void> {
        if (this.suppressPageParams) return Promise.resolve()
        const nav = this.activeNavFacade

        return nav.getQueryParams().then(params => {
            if (this.paged && (params.size || params.page)) {
                this.masterPaginator.pageIndex = TypeHelper.safeConvertInteger(params.page, 0)
                this.masterPaginator.pageSize = TypeHelper.safeConvertInteger(params.size, this.activePageSize)
            }

            if (!TypeHelper.isStringNullOrEmpty(params.sort)) {
                const parts = params.sort.split(',')
                this.sortActive = parts[0]
                this.sortDirection = parts.length > 1 ? <any>sanitizeSortDirection(parts[1]) : 'asc'
            }

            return Promise.resolve()
        })
    }

    public rowId(row: any): string {
        if (this.idKey && row && row[this.idKey]) {
            return `${this.id ? `${this.id}-` : ''}${idfy(row[this.idKey])}`
        }
    }

    //#endregion
}

function sanitizeSortDirection(value: string): string {
    value = value != undefined ? value.toLowerCase() : undefined
    switch (value) {
        case 'asc':
        case 'desc':
            return value

        default:
            return 'asc'
    }
}

function evalFnAsBoolean(item: any, fn: (item: any) => boolean): true | undefined {
    return (item && fn ? fn(item) : false) ? true : undefined
}
