import { Router } from '@angular/router'
import { NavFacadeBase } from './nav-facade-base'

export class SamplesNavFacade extends NavFacadeBase {
    constructor(router: Router) {
        super(router)
    }

    listSamples(): Promise<boolean> {
        return this.router.navigate([`/samples`])
    }

    listCollectionSamples(collectionId: string): Promise<boolean> {
        return this.router.navigate([`/samples/${collectionId}`])
    }
}
