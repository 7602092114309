import { Router, Params } from '@angular/router'
// import { GlobalStateManager } from '../../app-lib/state/global-state-manager';

export type NavParams = Params | string

export abstract class NavFacadeBase {
    protected constructor(protected readonly router: Router) {}

    // protected navigigateWithParams(
    //   commands: any[],
    //   queryParams: NavParams
    // ): Promise<boolean> {
    //   return this.router.navigate(commands, {
    //     queryParams: this.resolveParams(queryParams)
    //   });
    // }

    // protected resolveParams(params: NavParams): Params {
    //   return typeof params === 'string'
    //     ? GlobalStateManager.singleton.getParams(params)
    //     : params;
    // }
}
