import { FilteredQueryBase } from '../filters/filtered-query-base'
import { QueryBinding } from '../filters/query-binding'
import { ViewModel } from './view-model'

type ViewModelQuery<TData> = FilteredQueryBase<any, any, TData>
export type FilteredQueryResolver<TData, TQuery extends ViewModelQuery<TData>> = TQuery | (() => TQuery)

/**
 * A base class for filter query view-model implementations, which automatically initialises and releases its `FilteredQueryBase` instance.
 */
export class FilterQueryViewModel<TData, TQuery extends FilteredQueryBase<any, any, TData>> extends ViewModel {
    private _queryInternal: TQuery

    /**
     * Creates a new `FilterQueryViewModel` instance.
     * @param queryResolver An instance of `TQuery`, or a factory function which may be invoked to generate such an instance.
     * @param suppressFocus  If `true`, suppresses focus of the filter panel UI component. The default value is `false`.
     */
    constructor(queryResolver: FilteredQueryResolver<TData, TQuery>, private suppressFocus = false) {
        super()
        if (typeof queryResolver === 'function') {
            queryResolver = queryResolver()
        }
        this._queryInternal = <any>queryResolver
    }

    protected get queryInternal(): TQuery {
        return this._queryInternal
    }

    get query(): QueryBinding<TData> {
        return this.queryInternal
    }

    viewModelInit(): void {
        this.disposables.autoDispose(this.queryInternal)
    }

    protected onLoaded(): void {
        this.queryInternal.init({ suppressFocus: this.suppressFocus })
        delete this.suppressFocus
    }
}
