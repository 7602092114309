export class TraceLogger {
    static console(headers: string | Object | (string | Object)[], message: string, ...optionalParams: any[]): void {
        if (Array.isArray(headers)) {
            let c = headers
            headers = ''
            c.forEach(h => {
                let s = formatHeader(h)
                if (s != undefined) headers += s
            })
        } else {
            headers = formatHeader(headers)
        }

        if (headers == undefined || headers === '') {
            headers = '[Trace Logger]'
        }

        console.log(headers + ' ' + message, ...optionalParams)
    }
}

function formatHeader(header: string | Object): string {
    let r: string
    if (typeof header === 'string') {
        r = `[${header}]`
    } else if (header.constructor) {
        r = `[${header.constructor.name}]`
    }

    return r
}
