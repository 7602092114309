import { Injectable } from '@angular/core'
import { TableDefinition } from '@plantandfood/kup.core'

@Injectable()
export class SamplesTableFactory {
    samples(): TableDefinition {
        return {
            sortActive: 'name',
            sortDirection: 'asc',
            tableId: 'plants',
            columns: [
                {
                    columnDef: 'name',
                    sortKey: 'name',
                    header: 'Name',
                    formatter: 'truncate',
                    show: true,
                    excluded: true,
                    tooltipKey: 'name',
                },
                {
                    columnDef: 'locationName',
                    sortKey: 'location.name',
                    header: 'Location',
                    formatter: 'truncate',
                    tooltipKey: 'locationName',
                    show: true,
                },
                {
                    columnDef: 'locationAddress',
                    sortKey: 'location.address',
                    header: 'Location Address',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'locationAddress',
                },
                {
                    columnDef: 'genotypeName',
                    sortKey: 'genotype.name',
                    header: 'Genotype',
                    formatter: 'truncate',
                    tooltipKey: 'genotypeName',
                    show: true,
                },
                {
                    columnDef: 'genotypeSex',
                    sortKey: 'genotype.sex',
                    header: 'Sex',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'genotypeSex',
                },
                {
                    columnDef: 'activeStatus',
                    sortKey: 'activeRange',
                    header: 'Status',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'activeStatus',
                    sortDisabled: true,
                },
                {
                    columnDef: 'treatment',
                    sortKey: 'treatment',
                    header: 'Treatment',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'treatment',
                },
                {
                    columnDef: 'creationMethodName',
                    sortKey: 'creationMethod.name',
                    header: 'Biomaterial Creation Method',
                    formatter: 'truncate',
                    tooltipKey: 'creationMethodName',
                    show: true,
                },
                {
                    columnDef: 'collectionName',
                    sortKey: 'collection.name',
                    header: 'Collection',
                    formatter: 'truncate',
                    tooltipKey: 'collectionName',
                    show: true,
                },
                {
                    columnDef: 'startDate',
                    sortKey: 'startDate',
                    header: 'Start Date',
                    formatter: 'date',
                    show: true,
                    tooltipKey: 'startDate',
                },
                {
                    columnDef: 'endDate',
                    sortKey: 'endDate',
                    header: 'End Date',
                    formatter: 'date',
                    show: true,
                    tooltipKey: 'endDate',
                },
                {
                    columnDef: 'latestTransferDate',
                    sortKey: 'latestTransferDate',
                    header: 'Latest Transfer Date',
                    formatter: 'date',
                    tooltipKey: 'latestTransferDate',
                    show: true,
                },
            ],
        }
    }
}
