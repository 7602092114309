// import { FeatureModuleState } from '../lib/feature-module-state';
import { TraceLogger } from '../lib/trace/trace-logger'

export enum AppTraceTarget {
    None = 0x0,
    ViewStore = 0x1,
    FeatureModuleState = 0x2,
    All = ViewStore | FeatureModuleState,
    QueryCoordinator,
}

export class AppTrace {
    private static _targets = AppTraceTarget.None

    private constructor() {}

    static get targets(): AppTraceTarget {
        return AppTrace._targets
    }

    static set targets(value: AppTraceTarget) {
        let prev = AppTrace._targets
        AppTrace._targets = value != undefined ? value : AppTraceTarget.None
        if (prev != AppTrace._targets) {
            TraceLogger.console('AppTrace', prev === AppTraceTarget.None ? 'Tracing Enabled' : 'Tracing Disabled')
        }
        applyTargets()
    }

    static isActive(value: AppTraceTarget): boolean {
        return (AppTrace._targets & value) === value
    }
}

/**
 * Congifures type-specific trace-logging where required.
 */
function applyTargets(): void {
    // FeatureModuleState.trace = AppTrace.isActive(
    //   AppTraceTarget.FeatureModuleState
    // );
}
