import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, EventEmitter } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'

import { Page, Disposables } from '@plantandfood/kup.core'
import { CustomDataService } from 'app/services/custom-data.service'

interface TableConfig {
    [key: string]: string[]
}

/** @todo */
import { FilteredQueryBase, QueryBinding } from '../../../filter-query-services'
import { AppFacade } from '../../facade'
import { TableComponentBase } from '../table/table-component-base'

@Component({
    selector: 'app-data-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent extends TableComponentBase {
    private _querySubs: Disposables
    private _query: FilteredQueryBase<any, any, any>
    private _localLoading = false
    private _awaitingData = false
    private _data: any[]

    @Input() page: Page
    @Input() get query(): QueryBinding<any> {
        return this._query
    }
    set query(value: QueryBinding<any>) {
        if (this._querySubs) {
            this._querySubs.dispose()
        } else {
            this._querySubs = new Disposables()
        }

        this._querySubs.subscribeTo(value.loading$, loading => {
            this._localLoading = loading
            this.loading = loading
            this.changeDetector.markForCheck()
        })

        this._querySubs.subscribeTo(value.data$, data => {
            this._data = data
            this._awaitingData = this._localLoading ? true : false
            this.loading = false
            this.changeDetector.markForCheck()
        })

        this._querySubs.subscribeTo(value.page$, page => {
            let totalElement: number
            page ? this.customDataService.pageValue(page) : null
            this.page = page

            if (this.page) {
                this.emitResults(this.page.totalElements)
            }

            this.changeDetector.markForCheck()
        })

        this._querySubs.subscribeTo(value.error$, () => {
            this._awaitingData = false
            this.loading = false
            this.changeDetector.markForCheck()
        })

        this._querySubs.subscribeTo(this.pageChange, (event: PageEvent) => {
            value.onPageChange(event)
        })

        this._querySubs.subscribeTo(this.sortChange, (event: Sort) => {
            value.onSortChange(event)
        })
    }

    @Input() get data(): any[] {
        return this._data
    }

    /**
     * since the table base component handles the query (it shouldn't but here we are),
     * after the table has recieved data it needs to emit it's data back to any parent components
     */
    @Output() private resultCount = new EventEmitter<number>()
    public emitResults(total: number) {
        this.resultCount.emit(total)
    }

    set data(value: any[]) {
        this.setInitialising(false)
        this._data = value
        this._awaitingData = this._localLoading ? true : false
        this.loading = false
    }

    get dataSource(): any[] {
        return this.loading ? [] : this.data
    }

    constructor(facade: AppFacade, changeDetector: ChangeDetectorRef, dailog: MatDialog, private customDataService: CustomDataService) {
        super(facade, changeDetector, dailog)
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
        if (this._querySubs) {
            this._querySubs.dispose()
        }
    }

    setLoading(value: boolean) {
        if (value == true) {
            this.setInitialising(false)
            this._awaitingData = true
        }
        super.setLoading(this._localLoading || this._awaitingData)
    }

    hasNoResults(): boolean {
        return !this._awaitingData && (!this.data || this.data.length === 0)
    }

    /**
     * The `dropRow` method is otherwise redundant but required to support the common HTML template.
     */
    dropRow(_event: any) {}

    applyFilter() {}

    protected resetPaging(): void {
        if (this.page) {
            this.page.number = 0
        }
    }
}
