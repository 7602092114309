import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { TypedRestResponse, MapiServiceBase, Plant, ApiResponse, Fruit, Scion } from '@plantandfood/kup.core'
import { managementApi } from '../../constants/path.constants'
import { UsersService } from './users.service'
import { map, shareReplay, switchMap } from 'rxjs/operators'

const canesEndpoint = 'canes?projection=caneSimpleTable'
const plantsEndpoint = 'plants?projection=plantSimpleTable'
const scionsEndpoint = 'scions?projection=scionSimpleTable'
const fruitsEndpoint = 'fruits?projection=fruitSimpleTable'

@Injectable({ providedIn: 'root' })
export class BiomaterialsService extends MapiServiceBase {
    public entityNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    constructor(private readonly _usersService: UsersService, http: HttpClient) {
        super(http, managementApi)
    }

    getPlants(params: Params): Observable<TypedRestResponse<Plant>> {
        return this._usersService.getUserData().pipe(
            shareReplay(),
            map(this._mapUserDataToSynonymCategory),
            switchMap(
                (synonymCategory): Observable<TypedRestResponse<Plant>> => {
                    return this.getAndMap(
                        plantsEndpoint,
                        result => {
                            result._embedded = result._embedded.plants
                            return result
                        },
                        {
                            ...params,
                            ...(synonymCategory && { synonymCategory }),
                        }
                    )
                }
            )
        )
    }

    getBatchPlants(params: Params, uuid: string): Observable<TypedRestResponse<Plant>> {
        return this.getAndMap(
            `${plantsEndpoint}&batch.id=${uuid}`,
            result => {
                result._embedded = result._embedded.plants
                return result
            },
            params
        )
    }

    getPlantsNotInBatch(params: Params, uuid: string): Observable<TypedRestResponse<Plant>> {
        return this.getAndMap(
            `${plantsEndpoint}&batch.id.ne=${uuid}`,
            result => {
                result._embedded = result._embedded.plants
                return result
            },
            params
        )
    }

    getCollectionPlants(params: Params, uuid: string): Observable<TypedRestResponse<Plant>> {
        return this.getAndMap(
            `${plantsEndpoint}&collection.id=${uuid}`,
            result => {
                result._embedded = result._embedded.plants
                return result
            },
            params
        )
    }

    getBlocks(siteId: string) {
        return this.getAndMap(`sites/${siteId}/blocks`, result => {
            result = result._embedded.blocks
            return result
        })
    }

    getCollections(): Observable<any> {
        return this.getAndMap('biomaterialCollections?projection=biomaterialCollectionManagementTable', result => {
            result = result._embedded.biomaterialCollections
            return result
        })
    }

    applyBiomaterials(batchId: string, biomateriaIds: string[], processByLocation: boolean, action: string): Observable<ApiResponse<void>> {
        return this.putAsApiResponse(`biomaterialBatches/${batchId}/biomaterials`, biomateriaIds, {
            action: action,
            processByLocation: processByLocation,
        })
    }

    applyAllBiomaterials(batchId: string, processByLocation: boolean, query: Params): Observable<ApiResponse<void>> {
        return this.putAsApiResponse(
            `biomaterialBatches/${batchId}/biomaterialsFromQuery`,
            {},
            {
                ...query,
                processByLocation,
            }
        )
    }

    getFruits(params: Params): Observable<TypedRestResponse<Fruit>> {
        return this._usersService.getUserData().pipe(
            shareReplay(),
            map(this._mapUserDataToSynonymCategory),
            switchMap(
                (synonymCategory): Observable<TypedRestResponse<Fruit>> => {
                    return this.getAndMap(
                        fruitsEndpoint,
                        result => {
                            result._embedded = result._embedded.fruits
                            return result
                        },
                        {
                            ...params,
                            ...(synonymCategory && { synonymCategory }),
                        }
                    )
                }
            )
        )
    }

    getBatchFruits(params: Params, uuid: string): Observable<TypedRestResponse<Fruit>> {
        return this.getAndMap(
            `${fruitsEndpoint}&batch.id=${uuid}`,
            result => {
                result._embedded = result._embedded.fruits
                return result
            },
            params
        )
    }

    getFruitsNotInBatch(params: Params, uuid: string): Observable<TypedRestResponse<Fruit>> {
        return this.getAndMap(
            `${fruitsEndpoint}&batch.id.ne=${uuid}`,
            result => {
                result._embedded = result._embedded.fruits
                return result
            },
            params
        )
    }

    getCollectionFruits(params: Params, uuid: string): Observable<TypedRestResponse<Plant>> {
        return this.getAndMap(
            `${fruitsEndpoint}&collection.id=${uuid}`,
            result => {
                result._embedded = result._embedded.fruits
                return result
            },
            params
        )
    }

    getCanes(params: Params): Observable<TypedRestResponse<Fruit>> {
        return this._usersService.getUserData().pipe(
            shareReplay(),
            map(this._mapUserDataToSynonymCategory),
            switchMap(
                (synonymCategory): Observable<TypedRestResponse<Fruit>> =>
                    this.getAndMap(
                        canesEndpoint,
                        result => {
                            result._embedded = result._embedded.canes
                            return result
                        },
                        {
                            ...params,
                            ...(synonymCategory && { synonymCategory }),
                        }
                    )
            )
        )
    }

    getBatchCanes(params: Params, uuid: string): Observable<TypedRestResponse<Fruit>> {
        return this.getAndMap(
            `${canesEndpoint}&batch.id=${uuid}`,
            result => {
                result._embedded = result._embedded.canes
                return result
            },
            params
        )
    }

    getCanesNotInBatch(params: Params, uuid: string): Observable<TypedRestResponse<Fruit>> {
        return this.getAndMap(
            `${canesEndpoint}&batch.id.ne=${uuid}`,
            result => {
                result._embedded = result._embedded.canes
                return result
            },
            params
        )
    }

    getCollectionCanes(params: Params, uuid: string): Observable<TypedRestResponse<Plant>> {
        return this.getAndMap(
            `${canesEndpoint}&collection.id=${uuid}`,
            result => {
                result._embedded = result._embedded.canes
                return result
            },
            params
        )
    }

    getScions(params: Params): Observable<TypedRestResponse<Scion>> {
        return this._usersService.getUserData().pipe(
            shareReplay(),
            map(this._mapUserDataToSynonymCategory),
            switchMap(
                (synonymCategory): Observable<TypedRestResponse<Scion>> => {
                    return this.getAndMap(
                        scionsEndpoint,
                        result => {
                            result._embedded = result._embedded.scions
                            return result
                        },
                        {
                            ...params,
                            ...(synonymCategory && { synonymCategory }),
                        }
                    )
                }
            )
        )
    }

    getBatchScions(params: Params, uuid: string): Observable<TypedRestResponse<Scion>> {
        return this.getAndMap(
            `${scionsEndpoint}&batch.id=${uuid}`,
            result => {
                result._embedded = result._embedded.scions
                return result
            },
            params
        )
    }

    getScionsNotInBatch(params: Params, uuid: string): Observable<TypedRestResponse<Scion>> {
        return this.getAndMap(
            `${scionsEndpoint}&batch.id.ne=${uuid}`,
            result => {
                result._embedded = result._embedded.scions
                return result
            },
            params
        )
    }

    getCollectionScions(params: Params, uuid: string): Observable<TypedRestResponse<Scion>> {
        return this.getAndMap(
            `${scionsEndpoint}&collection.id=${uuid}`,
            result => {
                result._embedded = result._embedded.scions
                return result
            },
            params
        )
    }

    private _mapUserDataToSynonymCategory(user: any) {
        const data = user?.result?.userData || '{}'
        const stringified = JSON.parse(data)
        const parsed = typeof stringified === 'string' ? JSON.parse(stringified) : stringified
        return parsed?.preferences?.genotypeSynonymCategory
    }
}
