import { Component, OnDestroy, OnInit } from '@angular/core'
import { AppTextService, StandardFormButton } from '@plantandfood/kup.core'

import { RequestAction, RequestStatus } from '../../../app/constants/request-type.constants'
import { BiomaterialRequest } from '../../../app/entities/modules/requests/requests'
import { RequestsFormFactory } from '../../factories/requests-form-factory'
import { RequestItemViewModel } from '../../viewmodels/request-item-view-model'

@Component({
    selector: 'pfr-request-edit-item',
    templateUrl: './request-item.component.html',
    styleUrls: ['./request-item.component.scss'],
    providers: [RequestItemViewModel, RequestsFormFactory],
})
export class RequestItemComponent implements OnInit, OnDestroy {
    readonly topButtons: StandardFormButton<BiomaterialRequest>[]

    constructor(readonly viewModel: RequestItemViewModel, readonly formFactory: RequestsFormFactory, readonly textService: AppTextService) {
        this.topButtons = [
            {
                tooltip: 'Save Request',
                svgIcon: 'save_white',
                click: () => {
                    this.viewModel.save()
                },
                disabled: () => {
                    switch (true) {
                        case this.formFactory.form.pristine:
                        case !this.formFactory.form.touched:
                        case this.formFactory.form.invalid:
                        case !this.viewModel.canEdit:
                        case this.formFactory.isSourceBatchError:
                            return true

                        default:
                            return false
                    }
                },
            },
        ]
        if (!this.viewModel.isCreateMode) {
            this.topButtons.push(
                {
                    tooltip: 'View Source Batch',
                    icon: 'search',
                    click: () => {
                        this.viewModel.toSourceBatch()
                    },
                },
                {
                    tooltip: 'Send for review',
                    icon: 'visibility',
                    click: () => {
                        this.viewModel.onChangeStatus(RequestAction.SEND_FOR_REVIEW)
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (value === RequestStatus.OPEN) {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    tooltip: 'Send feedback',
                    icon: 'send',
                    click: () => {
                        this.viewModel.onChangeStatus(RequestAction.SEND_FOR_REVIEW)
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (value === RequestStatus.AWAITING_FEEDBACK) {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    tooltip: 'Request feedback',
                    icon: 'feedback',
                    click: () => {
                        this.viewModel.onChangeStatus(RequestAction.REQUEST_FEEDBACK)
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (value === RequestStatus.UNDER_REVIEW) {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    tooltip: 'Export Generated Biomaterials',
                    svgIcon: 'file_export',
                    click: () => {
                        this.viewModel.onExportGeneratedBiomaterials()
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (viewModel.creationMethod && !(value === RequestStatus.OPEN || value === RequestStatus.UNDER_REVIEW || value === RequestStatus.AWAITING_FEEDBACK)) {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    tooltip: 'Print Labels',
                    icon: 'print',
                    click: () => {
                        this.viewModel.onPrintLabels()
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (!(value === RequestStatus.OPEN || value === RequestStatus.UNDER_REVIEW || value === RequestStatus.AWAITING_FEEDBACK)) {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    tooltip: 'Complete Request',
                    icon: 'assignment_turned_in',
                    click: () => {
                        this.viewModel.onChangeStatus(RequestAction.COMPLETE)
                    },
                    disabled: () => {
                        const value = this.viewModel.request ? this.viewModel.request.status : RequestStatus.OPEN
                        if (value === RequestStatus.IN_PROGRESS) {
                            return false
                        } else {
                            return true
                        }
                    },
                }
            )
        }
    }

    get status() {
        return this.viewModel.request.status
    }

    ngOnInit() {
        this.viewModel.init()
    }

    ngOnDestroy() {
        this.viewModel.closeModal()
        this.viewModel.OnDestroy()
    }
}
