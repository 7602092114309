<app-standard-view [viewTitle]="'Requests'" [canCreate]="viewModel.canCreate" (createClick)="viewModel.create()" [busyState]="viewModel.loadingState">
    <app-data-table
        id="app_requests_table"
        [tableDefinition]="tableFactory.requests()"
        [query]="viewModel.query"
        [configColms]="true"
        [selectable]="true"
        (selectRow)="viewModel.edit($event)"
    ></app-data-table>
</app-standard-view>
