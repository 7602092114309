import { CommonModule, DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule, Optional, SkipSelf } from '@angular/core'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgxPermissionsModule } from 'ngx-permissions'
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader'
import { AppHttpInterceptorProvider } from './http/http-interceptor-provider'
import { throwIfAlreadyLoaded } from './module-import-guard'
import { FileHelperService } from './services/file-helper.service'
import { Logger } from './services/logger.service'
@NgModule({
    imports: [CommonModule, HttpClientModule, NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxPermissionsModule.forRoot(), FontAwesomeModule],
    exports: [CommonModule, HttpClientModule, NgxUiLoaderModule, NgxUiLoaderHttpModule, FontAwesomeModule],
    declarations: [],
    providers: [AppHttpInterceptorProvider, DatePipe, Logger, FileHelperService],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule')
    }
}
