import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { TypedRestResponse } from '@plantandfood/kup.core'
import { Observable } from 'rxjs'

import { MapiServiceBase } from '../mapi-service-base'
import { managementApi } from '../../constants/path.constants'
import { BiomaterialCreationMethod } from '../../../biomaterial-creation-methods/model/biomaterial-creation-method.model'

@Injectable({
    providedIn: 'root',
})
export class BiomaterialCreationMethodsService extends MapiServiceBase {
    api = managementApi
    constructor(http: HttpClient) {
        super(http)
    }

    getBiomaterialCreationMethods(params?: Params): Observable<TypedRestResponse<BiomaterialCreationMethod>> {
        return this.getAndMap(
            'biomaterialCreationMethods?projection=biomaterialCreationMethodTable',
            result => {
                result._embedded = result._embedded.biomaterialCreationMethods
                return result
            },
            params
        )
    }

    getBiomaterialCreationMethod(creationMethodId: String): Observable<BiomaterialCreationMethod> {
        return this.getAndMap(`biomaterialCreationMethods/${creationMethodId}?projection=biomaterialCreationMethodTable`, result => {
            return result
        })
    }
}
