import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import {
    ApiActionType,
    ApiService,
    AsyncApiOutcomeDescriptorFactory,
    AsyncApiResponse,
    EntityTypeKey,
    SwalDialog,
    TypeHelper,
    UserNotifications,
    KupSnackBarService,
} from '@plantandfood/kup.core'

import { ViewModel } from '../../filter-query-services'

import { forkJoin, Observable, of } from 'rxjs'
import { BatchesFormFactory } from '../factories/batches-form-factory'
import { serviceList } from '../services/service-list'
import { Batch, BatchRequest, BiomaterialCombResponse } from './../models/batch.model'
import { BiomaterialBatchesService } from '../../app/api/services/biomaterial-batches.service'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { BiomaterialsService } from 'app/api/services/biomaterials.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable()
export class BatchItemViewModel extends ViewModel {
    private _batch: Batch
    private _batchId: string
    private _biomaterialTabs: string[] = []
    private _navPath: string
    private _combBiomaterial: BiomaterialCombResponse[] = []
    private _batchRequest: BatchRequest[] = []
    private _requestId: string | null

    constructor(
        public dialog: MatDialog,
        public alartDialog: SwalDialog,
        private route: ActivatedRoute,
        private router: Router,
        private formFactory: BatchesFormFactory,
        private service: ApiService,
        private userNotifications: UserNotifications,
        private biomaterialBatchesService: BiomaterialBatchesService,
        private kupSnackBarService: KupSnackBarService,
        private _biomaterialService: BiomaterialsService,
        private snackBar: MatSnackBar
    ) {
        super()
        this._batchId = this.route.snapshot.params.uuid
        this._requestId = this.route.snapshot.queryParamMap.get('requestId')
    }

    get batch(): Batch {
        return this._batch
    }

    get navPath(): string {
        return this._navPath
    }

    get biomaterialTabs(): string[] {
        return this._biomaterialTabs
    }

    get isCreateMode(): boolean {
        return this._batchId == undefined
    }

    get combBiomaterial(): BiomaterialCombResponse[] {
        return this._combBiomaterial
    }

    get batchRequest(): BatchRequest[] {
        return this._batchRequest
    }

    get requestId(): string | null {
        return this._requestId
    }

    viewModelInit(): void {
        if (!this.isCreateMode) {
            this.registerLoadingSequence(() => this.loadBatch())
        }
    }

    onLoaded() {
        this.formFactory.batchForm(!this.isCreateMode)
    }

    toListView() {
        if (!!this.requestId) return this.router.navigate([`requests/edit/${this.requestId}`])
        this.router.navigate(['biomaterial-batches'])
    }

    toItemView(batchName, id) {
        this._biomaterialService.entityNameSubject.next(batchName)
        this.router.navigate([`biomaterial-batches/edit/Batch/${id}`])
    }

    save(): AsyncApiResponse<Batch> {
        const batch = {
            ...this.batch,
        }
        if (this.isCreateMode) {
            const obs = this.service.apiService(serviceList.CREATE_BATCH, null, TypeHelper.clone(batch))
            this.userNotifications.handleAsyncApiResponse(AsyncApiOutcomeDescriptorFactory.createEntityNamed(obs, EntityTypeKey.Batch, ApiActionType.Create, this.batch.name))
            obs.subscribe(res => {
                if (res.isOk) {
                    this.toListView()
                }
            })
            return obs
        } else {
            const obs = this.service.apiService(serviceList.UPDATE_BATCH, TypeHelper.clone(batch))
            this.userNotifications.handleAsyncApiResponse(AsyncApiOutcomeDescriptorFactory.createEntityNamed(obs, EntityTypeKey.Batch, ApiActionType.Update, this.batch.name))
            obs.subscribe(res => {
                if (res.isOk) {
                    this.toListView()
                }
            })
            return obs
        }
    }

    private loadBatch(): Observable<any> {
        const resBatch = this.service.apiService(serviceList.GET_BATCH, null, null, `${this._batchId}?projection=biomaterialBatchManagementTable`)
        const resRequest = this.service.apiService(serviceList.GET_REQUESTS, null, null, `&sourceBatch.id=${this._batchId}`)

        const obs = forkJoin([resBatch, resRequest])

        obs.pipe(
            tap(([resBatch, resRequest]) => {
                if (resBatch.isOk) {
                    this._batch = resBatch.result
                }
                if (resRequest.isOk) {
                    this._batchRequest = resRequest.result._embedded.biomaterialRequests
                }
            }),
            mergeMap(([resBatch]) => {
                if (resBatch.result.type === 'COMBINATION') {
                    return this.service.apiService(serviceList.GET_BATCH, null, null, `${this._batchId}/batchBiomaterialCombinations`)
                }
                return of(null)
            })
        ).subscribe(combBiomaterial => {
            if (combBiomaterial && combBiomaterial.isOk) {
                this._combBiomaterial = combBiomaterial.result._embedded.batchBiomaterialCombinations
            }
        })

        return obs
    }

    createBatchItem(validate: boolean) {
        const batchModel = TypeHelper.clone({
            ...this.formFactory.form.value,
            ...this.formFactory.fileForm.value,
        })
        if (validate) {
            const res = this.biomaterialBatchesService.verfiyImport(batchModel, (this.formFactory.form.value as any).Radio).pipe(
                map((response: any) => response),
                catchError((error: any) => {
                    if (error.status === 400) {
                        if (batchModel.file == null) {
                            this.kupSnackBarService.open('Please upload a file', '')
                        } else {
                            this.snackBar.open('Failed to verify the CSV file: ' + error.error.message, 'Done', { duration: 10000 })
                        }
                    }
                    if (error.status === 409) {
                        this.kupSnackBarService.open('Failed to validate the Batch: The batch name must be unique', '')
                    }
                    return of(error)
                })
            )
            return res
        } else {
            const res = this.biomaterialBatchesService.createImport(batchModel, (this.formFactory.form.value as any).Radio).pipe(
                map((response: any) => response),
                catchError((error: any) => {
                    if (error.status === 400) {
                        this.kupSnackBarService.open('Failed to create Biomaterial Batch', '')
                    }
                    return error
                })
            )
            return res
        }
    }

    createEmptyBatchItem() {
        const batchModel = TypeHelper.clone({
            ...this.formFactory.form.value,
            type: 'SINGLE',
        })
        const res = this.biomaterialBatchesService.createEmpty(batchModel as any).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                if (error.status === 400) {
                    this.kupSnackBarService.open('Failed to create Biomaterial Batch', '')
                }
                if (error.status === 409) {
                    this.kupSnackBarService.open('Failed to validate the Batch: The batch name must be unique', '')
                }
                return error
            })
        )
        return res
    }
}
