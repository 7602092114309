export enum FilterFieldName {
    BiomaterialBatchName = 'biomaterialBatch.name',
    BatchBiomaterialName = 'batchBiomaterial.name',
    BatchContact = 'biomaterialBatch.contact',
    BiomaterialLocation = 'biomaterialLocation',
    GenotypeName = 'genotype.name',
    CollectionName = 'collection.name',
    SampleLocation = 'location.name',
    CreatedBy = 'collection.createdBy',
    RequestName = 'biomaterialRequests.name',
    Requester = 'requester',
    RequestType = 'type.id',
    DateRequested = 'requestDate.equals',
    Recipient = 'recipient',
    Site = 'location.siteName',
    SiteCode = 'location.siteCode',
    Block = 'location.block',
    Row = 'location.row',
    CreationMethod = 'creationMethod.name',
    Collection = 'collection.name',
    CaneWoodType = 'cane.woodType',
    CaneActiveStatus = 'cane.activeStatus',
    CaneName = 'cane.name',
    CaneSex = 'scion.genotype.sex',
    CaneGenotype = 'scion.genotype.id',
    CaneGenotypeLabel = 'scion.genotype.label',
    ScionSex = 'scion.genotype.sex',
    ScionGenotype = 'scion.genotype.id',
    ScionGenotypeName = 'scion.genotype.name',
    ScionGenotypeLabel = 'scion.genotype.label',
    ScionActiveStatus = 'scion.activeStatus',
    ScionName = 'scion.name',
    ScionGraftYear = 'scion.startYear',
    PlantSex = 'plant.genotype.sex',
    PlantGenotype = 'plant.genotype.id',
    PlantGenotypeName = 'plant.genotype.name',
    PlantGenotypeLabel = 'plant.genotype.label',
    PlantActiveStatus = 'plant.activeStatus',
    PlantName = 'plant.name',
    PlantActiveScionCount = 'plant.activeScionCount',
    TrialName = 'trial.name',
    TrialSite = 'trial.siteId',
    TrialContact = 'trial.contact',
    TrialActiveStatus = 'trial.activeStatus',
    GenotypeSex = 'genotype.sex',
    GenotypeLabel = 'genotype.label',
    GenotypePloidy = 'genotype.ploidy',
    TaxonName = 'taxon.name',
    GenotypeCreationMethod = 'creationMethod.name',
    BiomaterialName = 'biomaterialName',
    Trial = 'trialName',
    Trait = 'traitName',
    Method = 'methodName',
    Scale = 'scaleName',
    Process = 'processName',
    Season = 'seasonName',
    Hidden = 'showRecords',
    NeedsChecking = 'needsChecking',
    User = 'username',
    BiomaterialCollectionName = 'biomaterialCollection.name',
    BiomaterialCollectionActiveStatus = 'biomaterialCollection.activeStatus',
    BiomaterialCollectionContact = 'biomaterialCollection.contact',
    BiomaerialCollectionPermission = 'permissionType',
    FruitName = 'fruit.name',
    FruitGenotypeLabel = 'fruit.genotype.label',
    FruitActiveStatus = 'fruit.activeStatus',
    FruitSex = 'fruit.genotype.sex',
    FruitGenotype = 'fruit.genotype.id',
    FruitGenotypeName = 'fruit.genotype.name',
    ObservationName = 'observation.name',
    ObservationCode = 'observation.code',
    Tags = 'tag.id',
    Traits = 'trait.id',
    Methods = 'method.id',
    Scales = 'scale.id',
    processes = 'process.id',
    operationPlanName = 'operationsPlan.name',
    operationPlanContact = 'operationsPlan.contact',
    operationPlanSites = 'operationsPlan.sites',
    SampleName = 'sample.name',
    PlantLocation = 'location.name',
    SiteId = 'siteId',
    SampleSex = 'sample.genotype.sex',
    SampleGenotype = 'sample.genotype.id',
    SampleGenotypeName = 'sample.genotype.name',
    SampleGenotypeLabel = 'sample.genotype.label',
    SampleActiveStatus = 'sample.activeStatus',
    Location = 'location.ontologyTermId',
    Status = 'status',
    GenotypeRegistryName = 'genotypeRegistry.name',
    LegalEntity = 'genotypeRegistry.legalEntity.id',
    GenotypeRegistryStatus = 'genotypeRegistry.activeStatus',
    BiomaterialCreationMethod = 'creationMethod.id',
}
