import { RequestsComponent } from './../requests/containers/requests/requests.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from '../error-pages/not-found/not-found.component'
import { ForbiddenComponent } from '../error-pages/forbidden/forbidden.component'
import { AppErrorComponent } from '../error-pages/app-error/app-error.component'
import { UserRole } from './constants/users.constants'

const routes: Routes = [
    {
        path: '403',
        component: ForbiddenComponent,
        data: {
            access: 'anonymous',
        },
    },
    {
        path: '404',
        component: NotFoundComponent,
        data: {
            access: 'anonymous',
        },
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
        data: {
            permissions: { only: [UserRole.KUP_USER] },
        },
    },
    // Lazy Loaded Features
    {
        path: 'biomaterial-collections',
        loadChildren: () => import('../biomaterial-collections/biomaterial-collections.module').then(m => m.BiomaterialCollectionsModule),
    },
    {
        path: 'biomaterials',
        loadChildren: () => import('../biomaterials/biomaterials.module').then(m => m.BiomaterialsModule),
    },
    {
        path: 'biomaterial-creation-methods',
        loadChildren: () => import('../biomaterial-creation-methods/biomaterial-creation-methods.module').then(m => m.BiomaterialCreationMethodsModule),
    },
    {
        path: 'genotype-registries',
        loadChildren: () => import('../genotype-registries/genotype-registries.module').then(m => m.GenotypeRegistriesModule),
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
