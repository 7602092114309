import { Component, OnInit } from '@angular/core'
import { BatchesFormFactory } from '../../factories/batches-form-factory'
import { BiomaterialBatchesQueryCoordinator } from '../../services/biomaterial-batches-query-coordinator'
import { BatchesTableFactory } from './../../factories/batches-table-factory'
import { BiomaterialBatchesViewModel } from './../../services/biomaterial-batches-view-model'

@Component({
    selector: 'pfr-biomaterial-batches',
    templateUrl: './biomaterial-batches.component.html',
    styleUrls: ['./biomaterial-batches.component.scss'],
    providers: [BatchesTableFactory, BatchesFormFactory, BiomaterialBatchesViewModel, BiomaterialBatchesQueryCoordinator],
})
export class BiomaterialBatchesComponent implements OnInit {
    constructor(readonly viewModel: BiomaterialBatchesViewModel, readonly tableFactory: BatchesTableFactory) {}

    ngOnInit() {
        this.viewModel.init()
    }
}
