<mat-horizontal-stepper class="status-container" [selectedIndex]="stepperIndex" labelPosition="bottom">
    <mat-step label="Created" editable="false" [state]="open"></mat-step>
    <mat-step *ngIf="underReview" label="Under Review" editable="false" [state]="underReview"></mat-step>
    <mat-step *ngIf="awaitingFeedback" label="Awaiting Feedback" editable="false" [state]="awaitingFeedback"></mat-step>
    <mat-step *ngIf="creatingBiomaterials" label="Creating Biomaterials" editable="false" [state]="creatingBiomaterials"></mat-step>
    <mat-step *ngIf="inProgress" label="In Progress" editable="false" [state]="inProgress"></mat-step>
    <mat-step label="Completed" editable="false" [state]="completed"></mat-step>
    <ng-template matStepperIcon="done">
        <mat-icon class="material-icons">done</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="pending_actions">
        <mat-icon class="material-icons icon-color">pending_actions</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="help_outline">
        <mat-icon class="material-icons icon-color">help_outline</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="more_horiz">
        <i class="material-icons md-48">more_horiz</i>
    </ng-template>
    <ng-template matStepperIcon="horizontal_rule">
        <mat-icon class="material-icons md-36">horizontal_rule</mat-icon>
    </ng-template>
</mat-horizontal-stepper>
