import { Component, Injectable, Injector, OnDestroy } from '@angular/core'
import { Sort } from '@angular/material/sort'
import { Params } from '@angular/router'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { first } from 'rxjs/operators'

import { FilteredQueryCoordinator, FilteredQueryInitOptions, FilteredQueryResult, FilterField, FilterPanelService, QueryParamManager } from 'kup.common.ui'

import { ApiResponse, Page, TypeHelper, Disposables } from '@plantandfood/kup.core'

import { CustomFilterEventType } from './custom-filter-event-type'
import { FilterModelType } from './filter-model-type'
import { QueryBinding } from './query-binding'
import { SpringFilterPageAdapter } from './spring-filter-page-adapter'
import { QueryRouteHelper } from './query-route-helper'
import { QueryErrorPageHelper } from './query-error-helper'
import { QueryActionHelper } from './query-action-helper'

/**
 * The internal QueryParamManager for MUI FilteredQueryBase implementations.
 * See the `ActiveRouteNavFacade` inline documentation for details.
 */
class ParamManager implements QueryParamManager {
    constructor(private queryRouteHelper: QueryRouteHelper) {}

    add(params: Params): Promise<boolean> {
        return this.queryRouteHelper.addQuery(params)
    }

    remove(keys: string[]): Promise<boolean> {
        return this.queryRouteHelper.removeQuery(keys)
    }

    getParams(): Promise<Params> {
        return this.queryRouteHelper.getQueryParams()
    }
}

/**
 * Defines configuration options for MUI FilteredQueryBase implementations.
 */
export interface FilteredQueryOptions<TQueryModel> {
    /**
     * If true, unfocuses any pre-existing active FilterContext but does not give
     * focus to the context associated with this FilteredQueryCoordinator.
     */
    suppressFocus?: boolean
    /**
     * If assigned, `initialFilter` is applied as the initial filter model.
     */
    initialFilter?: TQueryModel
}

/** The base class for all filtered query coordinators in the MUI application. */
@Injectable()
export abstract class FilteredQueryBase<TFilterModel, TQueryModel, TData>
    extends FilteredQueryCoordinator<TFilterModel, TQueryModel, TData>
    implements QueryBinding<TData>, OnDestroy
{
    private _page = new BehaviorSubject<Page>(null)
    private _activeSort: Sort
    private _constants: string[] = []
    private _isSelectorMode = false
    private _isReentrant = false
    /**
     * A representation of page state that conforms to the kup.management.ui Page model.
     */
    readonly page$: Observable<Page>
    /**
     * When invoked from a dervied constructor, creates a new `FilteredQueryBase` instance.
     * @param modelType The `FilterModelType` describing this filter (used as a key in the saved filter collection).
     * @param filterPanelService The `FilterPanelService` instance.
     * @param filterFields The `FilterField` collection which describes the filter model.
     * @param queryActionHelper A helper that imports the global `AppFacade` instance and provides only the required functions.
     */
    constructor(
        readonly modelType: FilterModelType,
        filterPanelService: FilterPanelService,
        filterFields: FilterField[],
        protected readonly queryRouteHelper: QueryRouteHelper,
        protected readonly queryErrorPageHelper: QueryErrorPageHelper,
        protected readonly queryActionHelper: QueryActionHelper,
        config: any
    ) {
        super(filterPanelService, SpringFilterPageAdapter.singleton, filterFields, new ParamManager(queryRouteHelper))

        queryRouteHelper.init(config.store)
        queryActionHelper.init(config.facade)

        filterFields.forEach(f => {
            if (TypeHelper.isStringNullOrEmpty(f.selectorGroup)) {
                this._constants.push(f.key)
            } else {
                this._isSelectorMode = true
            }
        })

        this.page$ = this._page.asObservable()
        this.disposables.subscribeTo(this.pageChanged$, state => {
            if (!state) return
            this._page.next({
                size: state.pageSize,
                totalElements: state.resultCount,
                totalPages: state.pageCount,
                number: state.pageIndex,
            })
        })

        this.disposables.subscribeTo(this.sortChanged$, sort => {
            this._activeSort = sort
        })

        this.error$.subscribe(error => {
            if (this.isTraceActive) {
                console.warn(this, 'Error event: ', error)
            }

            error = ApiResponse.error(error)
            this.queryErrorPageHelper.gotoErrorPageAuto(error)
        })

        if (this.isTraceActive) {
            this.disposables.subscribeTo(this.queryModelChanged$, model => {
                console.warn(this, 'Query model changed event: ', model)
            })

            this.disposables.subscribeTo(this.loading$, state => {
                console.warn(this, `Loading = "${state}"`)
            })

            this.disposables.subscribeTo(<any>this.page$, page => {
                if (!page) return
                console.warn(this, 'Page changed event: ', page)
            })

            this.disposables.subscribeTo(this.sortChanged$, sort => {
                if (!sort) return
                console.warn(this, 'Sort changed event: ', sort)
            })

            this.disposables.subscribeTo(this.data$, data => {
                console.warn(this, 'Data changed event: ', data)
            })
        }
    }

    /**
     * Returns `true` if tracing is active for the `AppTraceTarget.QueryCoordinator` trace target, otherwise returns `false`.
     * Can be used by derived classes to write trace output.
     */
    protected get isTraceActive(): boolean {
        return false // AppTrace.isActive(AppTraceTarget.QueryCoordinator);
    }

    /**
     * Returns the active `Sort` state.
     */
    protected get activeSort(): Sort {
        return this._activeSort
    }

    /**
     * Returns true if the current instance had its filter state restored from the URL, otherwise returns false.
     */
    get isReentrant(): boolean {
        return this._isReentrant
    }

    /**
     * Returns `true` if the filter is able to be saved on this instance, otherwise returns `false`.
     * When overriden by a derived class, may be used to customise filter saving behaviour. The default value is `true`.
     */
    get canSaveFilter(): boolean {
        return true
    }

    ngOnDestroy(): void {
        this.unfocusFilter()
        super.ngOnDestroy()
        if (this.isTraceActive) {
            console.warn(this, 'Destroyed.')
        }
    }

    /**
     * Returns a set of `Observable` instances which should complete before the host component is rendered.
     */
    getLoadingWaitHandles(): Observable<any>[] {
        return this.ensureWillComplete(this.generateLoadingWaitHandles())
    }

    /**
     * A convenience method that invokes the `unfocus` method on the `FilterContext` associated with this instance.
     */
    unfocusFilter() {
        if (this.filterContext != undefined && this.filterContext.hasFocus) {
            this.filterContext.unfocus()
            if (this.isTraceActive) {
                console.warn([this], 'Unfocused filter.')
            }
        }
    }

    /**
     * Returns the query model params with sort parameters included but paging parameters excluded.
     */
    getExportParams(): Params {
        const params = this.getQueryModelParams()
        if (this.activeSort != undefined) {
            params.sort = `${TypeHelper.sanitizeString(this.activeSort.active)},${TypeHelper.sanitizeString(this.activeSort.direction)}`
        }
        return params
    }

    /**
     * When overriden in a derived class, returns an array of `Observable<any>` each of which must complete
     * as part of this instance's initialisation process.
     */
    protected generateLoadingWaitHandles(): Observable<any>[] {
        return []
    }

    protected onBeforeInit(): Promise<void> {
        if (this.isTraceActive) {
            console.warn(this, 'OnBeforeInit event.')
        }
        return this.paramManager
            .getParams()
            .then(params => {
                this._isReentrant = params['filter_mode'] === 'no_default'
            })
            .then(() => (this.canSaveFilter ? this.restoreSavedFilter() : Promise.resolve()))
            .then(() => this.awaitAll(this.generateLoadingWaitHandles()))
    }

    async init(options?: FilteredQueryOptions<TQueryModel>) {
        // In the local QueryFilter extension, fields are only included in the selector if they are part of a group
        // (although they may be that group's only member). All other fields are implicitly "constants" (always visible in the panel).
        if (this._constants.length > 0) {
            options = options == undefined ? {} : TypeHelper.clone(options)
            ;(<FilteredQueryInitOptions<TQueryModel>>options).constants = this._constants
        }

        if (this.isTraceActive) {
            console.warn(this, 'Initialising query coordinator with the following options:', options)
        }
        await super.init(options)
    }

    protected onAfterInit(): Promise<void> {
        if (this.isTraceActive) {
            console.warn(this, 'OnAfterInit event.')
            console.warn(this, `Save filter functionality is ${this.canSaveFilter ? 'enabled' : 'disabled'}.`)
        }
        this.filterContext.selectorMode = this._isSelectorMode

        if (this.isTraceActive) {
            console.warn(this, `Selector mode is ${this._isSelectorMode ? 'enabled' : 'disabled'}.`)
            if (this._isSelectorMode && this._constants.length > 0) {
                console.warn(this, 'Constant filter fields (not available as selectors) are: ', this._constants)
            }
        }

        this.configureSelectors()
        this.filterContext.state = { saveDisabled: !this.canSaveFilter }
        this.filterContext.contextEvent$.subscribe(args => {
            switch (args.eventName) {
                case CustomFilterEventType.Save:
                    this.conditionallySaveFilter()
                    break

                case CustomFilterEventType.ClearModel:
                    this.clearModel()
                    break
            }
        })

        // Apply the filter_mode=no_default param to prevent any saved or default filter state from being applied when
        // navigating from a bookmark or other context where it is expected that previous filter state will be restored.
        return this.queryRouteHelper.addQuery({ filter_mode: 'no_default' }).then(() => {
            if (this.isTraceActive) {
                console.warn(this, `Applied the "no_default" param to the active route.`)
            }
            return Promise.resolve()
        })
    }

    protected executeQuery(params?: Params): Promise<FilteredQueryResult<TData>> {
        if (this.isTraceActive) {
            console.warn(this, 'Executing query with params: ', params)
        }
        return this.execute(params)
    }

    onModeChange() {
        super.onModeChange()
    }

    /**
     * When implemented in a derived class executes a query to return filtered data.
     * @param params The filter parameters.
     */
    protected abstract execute(params?: Params): Promise<FilteredQueryResult<TData>>

    /**
     * A convenience method that constructs a `FilteredQueryResult` instance from a query dataset and `Page` instance.
     */
    protected toFilteredQueryResult(data: TData[], page: Page): FilteredQueryResult<TData> {
        return {
            data: data,
            page: {
                pageSize: page.size,
                pageIndex: page.number,
                pageCount: page.totalPages,
                resultCount: page.totalElements,
            },
        }
    }

    private clearModel(): void {
        if (this.filterContext != undefined) {
            this.filterContext.resetFilters()
        }
    }

    private conditionallySaveFilter(): void {
        if (this.canSaveFilter) {
            const obs = this.queryActionHelper.saveFilter(this.modelType, this.getQueryModelParams(), this.filterContext.getActiveSelectors())

            if (this.isTraceActive) {
                Disposables.global.subscribeReplayOnce(obs, () => {
                    console.warn(this, `Saved the active filter state as "${this.modelType}".`)
                })
            }
        }
    }

    private restoreSavedFilter(): Promise<void> {
        return (this._isReentrant ? Promise.resolve(true) : this.routeHasModelParam()).then(abort => {
            let result: Promise<void>
            if (!abort) {
                const saved = this.queryActionHelper.getSavedFilter(this.modelType)
                if (saved) {
                    result = this.assignModelToRoute(<any>saved.filterModel)
                    if (this.isTraceActive) {
                        console.warn(this, `Restored the saved filter "${this.modelType}":`, saved.filterModel)
                    }
                }
            } else if (this.isTraceActive) {
                console.warn(
                    this,
                    // tslint:disable-next-line
                    `Did not apply saved filter or filter defaults because the "no_default" param is present on the active route. The filter state will be restored from the route.`
                )
            }

            return result ? result : Promise.resolve()
        })
    }

    private configureSelectors(): void {
        let applied = false
        if (this._isSelectorMode && this.canSaveFilter) {
            const saved = this.queryActionHelper.getSavedFilter(this.modelType)
            if (saved && saved.selectors != undefined) {
                this.filterContext.setActiveSelectors(saved.selectors)
                applied = true
                if (this.isTraceActive) {
                    console.warn(this, `Restored the saved selectors "${this.modelType}".`, saved.selectors)
                }
            }
        }

        if (!applied) {
            const selectors = this.getSelectors()
            this.filterContext.setActiveSelectors(selectors)
            if (this.isTraceActive) {
                console.warn(this, `Applied the default selectors "${this.modelType}".`, selectors)
            }
        }
    }

    private ensureWillComplete(obs: Observable<any>[]): Observable<any>[] {
        if (obs == undefined || obs.length === 0) return []
        const array = []
        for (let i = 0; i < obs.length; i++) {
            const o = obs[i]
            if (o) {
                array.push(o.pipe(first()))
            }
        }
        return array
    }

    private awaitAll(obs: Observable<any>[]): Promise<void> {
        return forkJoin(this.ensureWillComplete(obs))
            .toPromise()
            .then(() => {})
    }
    /**
     * When called, API params will tack on `.contains` to any keys to process search/filter request.
     */
    protected updateParams(keys: string[], params?: Params): Params {
      for (const key of keys) {
        if (params[key]) {
          params[`${key}.contains`] = params[key]
          delete params[key]
        }
      }
      return params
    }
}
