import { FilterField, FilterFieldFlags } from 'kup.common.ui'
import { FilterFieldName } from '@plantandfood/kup.core'

export class BiomaterialBatchFilterDefinition {
    static readonly Name = FilterField.createWithOptions(FilterFieldName.BiomaterialBatchName)
    static readonly Contact = FilterField.createWithOptions(FilterFieldName.BatchContact, {
        flags: FilterFieldFlags.MultiValue,
    })

    static getFields(): FilterField[] {
        return [BiomaterialBatchFilterDefinition.Name, BiomaterialBatchFilterDefinition.Contact]
    }
}
