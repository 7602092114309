import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { RequestsComponent } from './containers/requests/requests.component'
import { RequestItemComponent } from './containers/request-item/request-item.component'
import { AuthGuard } from '../app/guards/auth.guard'
import { UserRole } from '@plantandfood/kup.core'
import { PageTitles } from 'app/constants/page-titles.constants'

const routes: Routes = [
    {
        path: 'requests',
        component: RequestsComponent,
        canActivate: [AuthGuard],
        data: {
            title: `${PageTitles.REQUESTS}`,
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
        },
    },
    {
        path: 'requests/new',
        component: RequestItemComponent,
        canActivate: [AuthGuard],
        data: {
            title: `${PageTitles.CREATE_REQUEST}`,
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
        },
    },
    {
        path: 'requests/edit/:uuid',
        component: RequestItemComponent,
        canActivate: [AuthGuard],
        data: {
            title: `${PageTitles.EDIT_REQUESTS}`,
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
        },
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RequestsRoutingModule {}
