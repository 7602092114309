/**
 * The set of API response codes.
 */
export enum ApiResponseCode {
    Ok = 'ok',
    ApplicationError = 'application_error',
    Custom = 'custom',
    NotReady = 'not_ready',
    NotFound = 'not_found',
    BadRequest = 'bad_request',
    AccessDenied = 'access_denied',
}
