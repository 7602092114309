import { RequestStatus } from './../../../app/constants/request-type.constants'
import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges } from '@angular/core'

@Component({
    selector: 'pfr-request-status',
    templateUrl: './request-status.component.html',
    styleUrls: ['./request-status.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RequestStatusComponent implements OnChanges {
    @Input() status: any
    open: string
    underReview: string | boolean
    awaitingFeedback: string | boolean
    creatingBiomaterials: string | boolean
    inProgress: string | boolean
    completed: string
    stepperIndex: number

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['status']) {
            this.loadStatus()
        }
    }

    loadStatus() {
        if (this.status === RequestStatus.OPEN) {
            this.open = 'done'
            this.underReview = 'horizontal_rule'
            this.awaitingFeedback = false
            this.creatingBiomaterials = false
            this.inProgress = 'horizontal_rule'
            this.completed = 'horizontal_rule'
            this.stepperIndex = 0
        } else if (this.status === RequestStatus.UNDER_REVIEW) {
            this.open = 'done'
            this.underReview = 'pending_actions'
            this.awaitingFeedback = false
            this.creatingBiomaterials = false
            this.inProgress = 'horizontal_rule'
            this.completed = 'horizontal_rule'
            this.stepperIndex = 1
        } else if (this.status === RequestStatus.AWAITING_FEEDBACK) {
            this.open = 'done'
            this.underReview = false
            this.awaitingFeedback = 'help_outline'
            this.creatingBiomaterials = false
            this.inProgress = 'horizontal_rule'
            this.completed = 'horizontal_rule'
            this.stepperIndex = 1
        } else if (this.status === RequestStatus.CREATING_BIOMATERIALS) {
            this.open = 'done'
            this.underReview = 'done'
            this.awaitingFeedback = false
            this.creatingBiomaterials = 'more_horiz'
            this.inProgress = false
            this.completed = 'horizontal_rule'
            this.stepperIndex = 2
        } else if (this.status === RequestStatus.IN_PROGRESS) {
            this.open = 'done'
            this.underReview = 'done'
            this.awaitingFeedback = false
            this.creatingBiomaterials = false
            this.inProgress = 'pending_actions'
            this.completed = 'horizontal_rule'
            this.stepperIndex = 2
        } else if (this.status === RequestStatus.COMPLETED) {
            this.open = 'done'
            this.underReview = 'done'
            this.awaitingFeedback = false
            this.creatingBiomaterials = false
            this.inProgress = 'done'
            this.completed = 'done'
            this.stepperIndex = 3
        }
    }
}
