import { Injectable } from '@angular/core'
import { TableDefinition } from '@plantandfood/kup.core'

@Injectable()
export class ScionsTableFactory {
    scions(): TableDefinition {
        return {
            sortActive: 'name',
            sortDirection: 'asc',
            tableId: 'fruit',
            columns: [
                {
                    columnDef: 'name',
                    sortKey: 'name',
                    header: 'Name',
                    formatter: 'truncate',
                    show: true,
                    excluded: true,
                    tooltipKey: 'name',
                },
                {
                    columnDef: 'genotypeName',
                    sortKey: 'genotype.name',
                    header: 'Scion Genotype',
                    formatter: 'truncate',
                    tooltipKey: 'genotypeName',
                    show: true,
                },
                {
                    columnDef: 'plantGenotypeName',
                    sortKey: 'plant.genotype.name',
                    header: 'Plant Genotype',
                    formatter: 'truncate',
                    tooltipKey: 'plantGenotypeName',
                    show: true,
                },
                {
                    columnDef: 'genotypeSex',
                    sortKey: 'genotype.sex',
                    header: 'Sex',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'genotypeSex',
                },
                {
                    columnDef: 'collectionName',
                    sortKey: 'collection.name',
                    header: 'Collection',
                    formatter: 'truncate',
                    tooltipKey: 'collectionName',
                    show: true,
                },
                {
                    columnDef: 'locationName',
                    sortKey: 'plant.location.name',
                    header: 'Location',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'locationName',
                },
                {
                    columnDef: 'locationAddress',
                    sortKey: 'plant.location.address',
                    header: 'Location Address',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'locationAddress',
                },
                {
                    columnDef: 'graftYear',
                    sortKey: 'graftYear',
                    header: 'Graft Year',
                    formatter: 'text',
                    show: true,
                    tooltipKey: 'graftYear',
                },
                {
                    columnDef: 'activeStatus',
                    sortKey: 'activeRange',
                    header: 'Status',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'activeStatus',
                    sortDisabled: true,
                },
            ],
        }
    }
}
