import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import {
    ApiActionType,
    ApiService,
    AsyncApiOutcomeDescriptorFactory,
    AsyncApiResponse,
    Disposables,
    DropdownDatasource,
    DropdownItem,
    EntityTypeKey,
    KupSnackBarService,
    PrinterModalData,
    PrintLabelsModalComponent,
    SwalDialog,
    TrialOperationsGroup,
    TypeHelper,
    UserNotifications,
    UserRole,
    RightsSummary,
} from '@plantandfood/kup.core'
import { Observable, Subscription } from 'rxjs'

import { BiomaterialRequestsService } from '../../app/api/services/biomaterial-requests.service'
import { BiomaterialBatchesService } from '../../app/api/services/biomaterial-batches.service'
import { BiomaterialCreationMethodsService } from '../../app/api/services/biomaterial-creation-methods.service'
import { biomaterialTypes, RequestStatus } from '../../app/constants/request-type.constants'
import { BiomaterialRequest } from '../../app/entities/modules/requests/requests'
import { BiomaterialCreationMethod } from '../../biomaterial-creation-methods/model/biomaterial-creation-method.model'
import { ErrorPageNavFacade } from '../../app/facade/nav-facade/error-page-nav-facade'
import { ViewModel } from '../../filter-query-services/view-model/view-model'
import { ChangeStatusModalComponent } from '../components/change-status/change-status-modal.component'
import { RequestsFormFactory } from '../factories/requests-form-factory'
import { serviceList } from '../services/service-list'
import { PrintService } from './../../app/api/services/print.service'
import { CustomDataService } from './../../app/services/custom-data.service'
import { BiomaterialsService } from 'app/api/services/biomaterials.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { RolesService } from 'filter-query-services'
import { FileHelperService } from 'core/services/file-helper.service'

@Injectable()
export class RequestItemViewModel extends ViewModel {
    private _request: BiomaterialRequest
    private _requestId: string
    private _externalReferences: DropdownItem[]
    private _trialOperationsGroups: TrialOperationsGroup[] = []
    private _entity = 'Batch'
    private _isRecipient = true
    private _sourceBatchName: string
    private _creationMethod: BiomaterialCreationMethod
    private _navPath: string
    private _canEdit = true

    subscription = new Subscription()

    constructor(
        public dialog: MatDialog,
        public alertDialog: SwalDialog,
        private route: ActivatedRoute,
        private router: Router,
        private formFactory: RequestsFormFactory,
        private service: ApiService,
        private customService: BiomaterialRequestsService,
        private userNotifications: UserNotifications,
        private printService: PrintService,
        private kupSnackBarService: KupSnackBarService,
        private customDataService: CustomDataService,
        private errorPage: ErrorPageNavFacade,
        private batchesService: BiomaterialBatchesService,
        private creationMethodService: BiomaterialCreationMethodsService,
        private fileHelperService: FileHelperService,
        private _biomaterialService: BiomaterialsService,
        private snackBar: MatSnackBar,
        private rolesService: RolesService
    ) {
        super()
        this._requestId = this.route.snapshot.params.uuid
    }

    get request(): BiomaterialRequest {
        return this._request
    }

    get creationMethod(): BiomaterialCreationMethod {
        return this._creationMethod
    }

    get sourceBatchName(): string {
        return this._sourceBatchName
    }

    get canEdit(): boolean {
        if (this.isCreateMode) {
            return true
        }
        if (this._request) {
            const hasUserEditPermissions = this.checkRightsSummary(this._request.biomaterialRequestRightsSummary)
            if (hasUserEditPermissions) {
                let inProgress = RequestStatus.IN_PROGRESS.toString()
                let isComplete = RequestStatus.COMPLETED.toString()
                return ![inProgress, isComplete].includes(this._request.status)
            }
            return false
        }
        return false
    }

    get isCreateMode(): boolean {
        return this._requestId == undefined
    }

    get isRecipient(): boolean {
        return this._isRecipient
    }

    get externalReferences(): DropdownItem[] {
        return DropdownDatasource.automap(this._externalReferences, 'name')
    }

    get trialOperationsGroups(): DropdownItem[] {
        return DropdownDatasource.fieldMap(this._trialOperationsGroups, 'operationsGroupName')
    }

    get navPath(): string {
        return this._navPath
    }

    viewModelInit(): void {
        if (this.isCreateMode) {
            let currentUserName: string
            this.subscription.add(this.customDataService.currentUserName$.subscribe(name => (currentUserName = name)))
            this._request = { ...this._request, requester: currentUserName, requestDate: new Date() }
            this.registerLoading(this.loadExternalReferences())
        } else {
            this.registerLoadingSequence(
                () => this.loadRequest(),
                () => this.loadBatch(),
                () => this.loadExternalReferences(),
                () => this.loadTrailOperationsGroups(),
                () => this.loadCreationMethod()
            )
        }
    }

    onLoaded() {
        this.formFactory.requestEditForm(this.externalReferences, !this.isCreateMode, this.canEdit, this.trialOperationsGroups, this.isRecipient)
    }

    toListView() {
        this.router.navigate(['requests'])
    }

    toItemView(requestId: string) {
        this.router.navigate([`requests/edit/${requestId}`])
    }

    toSourceBatch() {
        this.router.navigate([`/biomaterial-batches/edit/${this._entity}/${this.request.sourceBatchId}`], {
            queryParams: { requestId: this.request.uuid },
        })
    }

    save(): AsyncApiResponse<BiomaterialRequest> {
        const request = {
            ...this.request,
            externalReferences: this.request.externalReferences.filter(value => Object.keys(value).length !== 0),
        }
        if (this.isCreateMode) {
            const obs = this.service.apiService(serviceList.CREATE_REQUEST, null, TypeHelper.clone(request))

            obs.subscribe(res => {
                if (res.isOk) {
                    this.toItemView(res.result.uuid)
                } else {
                    this.snackBar.open('Failed to create Request: ' + res.message, 'Try Again', { duration: 10000 })
                    return res.error
                }
            })
            return obs
        } else {
            const obs = this.service.apiService(serviceList.UPDATE_REQUEST, TypeHelper.clone(request))
            obs.subscribe(res => {
                if (res.isOk) {
                    this.toListView()
                } else {
                    this.snackBar.open('Failed to update Request: ' + res.message, 'Try Again', { duration: 10000 })
                    return res.error
                }
            })
            return obs
        }
    }

    onChangeStatus(action: string) {
        const dialogRef = this.dialog.open(ChangeStatusModalComponent, {
            id: 'ChangeStatusDialog',
            width: '40%',
            data: {
                action: action,
                requester: this.request.requester,
                recipient: this.request.recipient,
            },
        })
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.updateRequest(action, res.notes)
            }
        })
    }

    updateRequest(action: string, notes?) {
        const updateRequests = {
            action: action,
            generatedBatchId: this.request.generatedBatchId,
            notes: notes ? notes : null,
        }
        const obs = this.customService.updateRequest(this.request.uuid, TypeHelper.clone(updateRequests))
        obs.subscribe(res => {
            if (res.isOk) {
                this.loadRequest()
            }
        })
    }

    private loadBatch(): Observable<any> {
        const obs = this.service.apiService(serviceList.GET_BATCH, null, null, `${this.request.sourceBatchId}?projection=biomaterialBatchManagementTable`)
        Disposables.global.subscribeSubjectOnce(obs, res => {
            if (res.isOk) {
                this._sourceBatchName = res.result.name
            }
        })
        return obs
    }

    private loadRequest(): Observable<any> {
        const obs = this.service.apiService(serviceList.GET_REQUEST, null, null, `${this._requestId}?projection=biomaterialRequestFormat`)
        obs.subscribe(res => {
            if (res.isOk) {
                this._request = res.result
            } else {
                this.errorPage.gotoErrorPageAuto(res)
            }
        })
        return obs
    }

    private loadExternalReferences(): Observable<any> {
        const obs = this.service.apiService(serviceList.GET_EXTERNAL_REFERENCES, null, null)
        obs.subscribe(res => {
            if (res.isOk) {
                this._externalReferences = res.result._embedded.externalReferenceNamespaces
            }
        })
        return obs
    }

    closeModal() {
        this.dialog.closeAll()
    }

    onExportGeneratedBiomaterials(): void {
        this.batchesService.getBatchExport(this.request.generatedBatchId, this._creationMethod.toType).subscribe(file => {
            if (file.size && file.size > 0) {
                this.fileHelperService.downloadCSVFromBlob('Biomaterials', file)
            } else {
                this.kupSnackBarService.open('The API has returned an empty response', 'close')
            }
        })
    }

    onPrintLabels(): void {
        const modaldata: PrinterModalData = {
            preSelectedField: 'batchId',
            id: this.request.generatedBatchId,
            fields: this.formFactory.batchPrintForm(),
        }

        const dialogRef = this.dialog.open(PrintLabelsModalComponent, {
            id: 'PrintLabelsDialog',
            width: '40%',
            data: modaldata,
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const printLabels = {
                    ...result,
                }
                const obs = this.printService.printLabels(printLabels)

                obs.subscribe(res => {
                    if (res.isOk) {
                        this.kupSnackBarService.open('Request to print the labels has been sent', '')
                    } else {
                        this.alertDialog.errorAlert(res.message)
                    }
                })
            }
        })
    }

    private loadTrailOperationsGroups(): Observable<any> {
        const obs = this.service.apiService(serviceList.GET_TRIAL_OPERATIONS, null, null, `/${this.request.trialId}/trialOperationsGroups`)
        obs.subscribe(res => {
            if (res.isOk) {
                this._trialOperationsGroups = res.result._embedded.trialOperationsGroups
                if (this._trialOperationsGroups.length == 0) {
                    this._isRecipient = false
                } else {
                    const recipient = this._trialOperationsGroups.findIndex(e => {
                        return e.operationsGroupName === this.request.recipient
                    })
                    this._isRecipient = recipient === -1 ? false : true
                }
            }
        })
        return obs
    }

    private loadCreationMethod(): Observable<any> {
        const obs = this.creationMethodService.getBiomaterialCreationMethod(this.request.creationMethodId)

        obs.subscribe(method => {
            this.formFactory.defaultValueSourceTypes(method.sourceTypes)
            this.formFactory.defaultValueToType(method.toType)
            this.formFactory.updateCreationMethodId(method.uuid)
            this._creationMethod = method
        })
        return obs
    }

    private checkRightsSummary(rightsSummary: RightsSummary) {
        return rightsSummary.canEdit ? true : false
    }

    OnDestroy() {
        this.subscription.unsubscribe()
    }
}
