import { Injectable } from '@angular/core'
import { ApiResponse, ApiResponseCode } from '../api'
import { UserRole } from '../constants/users.constants'
import { UserRightsMask } from '@plantandfood/kup.core'
import { NavFacade } from './nav-facade/nav-facade'
import { AuthService } from '@plantandfood/kup.auth'

@Injectable({ providedIn: 'root' })
export class AuthFacade {
    constructor(private authService: AuthService, private nav: NavFacade) {}

    get isAuthenticated(): boolean {
        return this.authService.isAuthenticated
    }

    /**
     * Validates that the current user has permission to access the application, and navigates to an error page if validation fails.
     */
    enforceApplicationAccess(): boolean {
        return this.enforceInRole(UserRole.BITS_USER)
    }

    /**
     * Validates that the current user has membership of the specified `UserRole` and navigates to an error page if validation fails.
     * @param role The role to validate.
     */
    enforceInRole(role: UserRole): boolean {
        let result = this.isUserInRole(role)

        if (!result) {
            this.nav.errorPage.gotoErrorPageAuto(ApiResponse.error(ApiResponseCode.AccessDenied))
        }

        return result
    }

    logout() {
        this.authService.logout()
    }

    getUserRoles(): string[] {
        return this.authService.getUserRoles()
    }

    /**
     * Returns true if the current user is in any of the specified roles, otherwise returns false.
     * @param role The role, or the array of roles, to evaluate.
     */
    isUserInRole(role: UserRole | UserRole[]): boolean {
        if (role == undefined) return false
        if (!Array.isArray(role)) return this.authService.isUserInRole(role)

        let result = false
        for (let i = 0; !result && i < role.length; i++) {
            result = this.authService.isUserInRole(role[i])
        }

        return result
    }

    isUserAuthorised(role: UserRole, rights?: UserRightsMask): boolean {
        if (!this.isUserInRole(role)) return false
        if (rights == undefined) return true
        return (rights.rights & rights.requiredRights) === rights.requiredRights
    }
}
