export const shortDateTime = 'dd/MM/yyyy HH:mm'
export const shortDate = 'dd/MM/yyyy'

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATEPICKER_DATE_FORMATS = {
    parse: {
        dateInput: 'l',
    },
    display: {
        dateInput: 'l',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'l',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}
