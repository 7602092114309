import { HttpErrorResponse } from '@angular/common/http'
import { ApiResponseCode } from './api-response-code'
import { ApiResponseData } from './api-response-data'
import { HttpErrorResolutionFunction } from './http-error-resolution-function'
import { HttpResponseData } from './http-response-data'

export function httpErrorResponseFactory<T>(response: HttpErrorResponse): ApiResponseData<T> {
    let result: HttpResponseData = {
        code: '',
        message: '',
        result: undefined,
    }

    getResolutionFunction(response)(result, response)
    if (result.code == undefined) result.code = ApiResponseCode.ApplicationError

    result.error = response
    return result
}

function getResolutionFunction(response: HttpErrorResponse): HttpErrorResolutionFunction {
    if (response.error == undefined) return resolveGenericError
    if (response.error.constraint != undefined) return resolveContraintViolation
    return resolveGenericError
}

function resolveGenericError(data: HttpResponseData, response: HttpErrorResponse) {
    switch (response.status) {
        case 404:
            data.code = ApiResponseCode.NotFound
            break

        case 403:
            data.code = ApiResponseCode.AccessDenied
            break

        default:
            data.code = ApiResponseCode.ApplicationError
            break
    }

    data.message = resolveErrorMessage(response)
}

function resolveContraintViolation(data: HttpResponseData, response: HttpErrorResponse) {
    data.code = response.error.constraint
    data.message = resolveErrorMessage(response)
}

function resolveErrorMessage(response: HttpErrorResponse): string {
    let error = response.error

    let result: string
    if (Array.isArray(error)) {
        if (error.length > 0) {
            result = error[0].message
        }
    } else if (error != undefined) {
        result = error.message
    }

    return result
}
