import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { FormGroup, UntypedFormGroup } from '@angular/forms'
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core'
import { ApiService, DropdownProvider, UsernameDropdownProviderNew, UserNotifications } from '@plantandfood/kup.core'
export const fileTypeRegex = /\.(csv)$/i

@Directive()
@Injectable()
export class BatchesFormFactory {
    form: UntypedFormGroup = new FormGroup({})
    options: FormlyFormOptions = {}
    users
    fields: FormlyFieldConfig[]
    fileForm: any = new FormGroup({})
    fileUploadField: FormlyFieldConfig[]
    @Output() changed = new EventEmitter<any>()

    constructor(private service: ApiService, public notify: UserNotifications) {
        this.users = new UsernameDropdownProviderNew(this.service)
    }

    batchFilterForm() {
        const userData = DropdownProvider.resolve(this.users, {
            suppressRefresh: false,
        })
        return [
            {
                key: 'biomaterialBatch.name',
                id: 'filter_batch_name',
                type: 'input',
                templateOptions: {
                    label: 'Filter by Name',
                },
            },
            {
                key: 'biomaterialBatch.contact',
                id: 'field_batch_contact',
                type: 'ng-select',
                templateOptions: {
                    multiple: true,
                    label: 'Filter by Contact',
                    options: userData.data$,
                    provider: userData,
                    isTypeahead: true,
                },
            },
        ]
    }

    batchForm(isCreateMode: boolean) {
        const userData = DropdownProvider.resolve(this.users, {
            suppressRefresh: false,
        })

        this.fields = [
            {
                key: 'name',
                id: 'field_batch_name',
                type: 'input',
                templateOptions: {
                    label: 'Name',
                    disabled: isCreateMode,
                    placeholder: 'Enter batch name, must be unique',
                    required: true,
                },
                validators: {
                    noOnlySpaces: {
                        expression: c => !c.value || /.*\S.*/.test(c.value),
                        message: () => 'Only spaces are not allowed',
                    },
                },
            },
            {
                key: 'description',
                id: 'field_description',
                type: 'textarea',
                templateOptions: {
                    label: 'Description',
                    rows: 3,
                    maxLength: 255,
                },
            },
            {
                key: 'contact',
                id: 'field_contact',
                type: 'ng-select',
                templateOptions: {
                    label: 'Contact',
                    required: true,
                    options: userData.data$,
                    provider: userData,
                    isTypeahead: true,
                },
            },
        ]
        if (!isCreateMode) {
            this.fields.push({
                key: 'Radio',
                type: 'radio',
                templateOptions: {
                    label: 'Select import biomaterial type',
                    placeholder: 'Placeholder',
                    required: true,
                    options: [
                        { value: 'withBiomaterial', label: 'Biomaterial Batch' },
                        {
                            value: 'combBiomaterial',
                            label: 'Combination Batch',
                        },
                    ],
                    change: (field, $event) => {
                        if ($event.value === 'withBiomaterial') {
                            this.fileUploadField = [
                                {
                                    key: 'file',
                                    id: 'field_file',
                                    type: 'file-upload',
                                    templateOptions: {
                                        required: false,
                                        label: 'Select Biomaterial file (optional)',
                                        floatLabel: 'always',
                                        appearance: 'outline',
                                        change: item => {
                                            this.checkForFileType(item, field.formControl.value)
                                        },
                                        description:
                                            'CSV file structure: expecting one or two columns, first column - Biomaterial Name (Mandatory), second column - Genotype Name (Optional)',
                                    },
                                    validation: {
                                        validators: ['file-upload'],
                                    },
                                },
                            ]
                        } else if ($event.value === 'combBiomaterial') {
                            this.fileUploadField = [
                                {
                                    key: 'file',
                                    id: 'field_file',
                                    type: 'file-upload',
                                    templateOptions: {
                                        required: true,
                                        label: 'Select Combination Biomaterials',
                                        floatLabel: 'always',
                                        appearance: 'outline',
                                        change: item => {
                                            this.checkForFileType(item, field.formControl.value)
                                        },
                                        description:
                                            'CSV file structure: expecting two columns, first column - Acceptor Biomaterial Name (Mandatory), second column - Donor Biomaterial Name (Mandatory)',
                                    },
                                    validation: {
                                        validators: ['file-upload'],
                                    },
                                },
                            ]
                        }
                    },
                },
            })
        }
    }

    checkForFileType(item, mode) {
        const el: any = document.querySelector('.file-upload-input')
        const file = item.formControl.value[0]
        if (file) {
            if (mode === 'withBiomaterial' || mode === 'combBiomaterial') {
                if (!fileTypeRegex.test(file.name)) {
                    el.value = null
                    this.notify.notifyFailure(`Invalid File - ${file.name}`)
                    this.fileForm.controls.file.setValue(null)
                }
            }
        }
    }
}
