<mat-horizontal-stepper labelPosition="bottom" #stepper linear>
    <mat-step>
        <!-- <ng-template matStepLabel>Enter Data</ng-template> -->
        <pfr-batch-item (verified)="onVerified($event)"></pfr-batch-item>
        <div class="button-style">
            <button class="cancel-button" mat-button (click)="OnTolistView()">Cancel</button>

            <ng-container [ngSwitch]="true">
                <button
                    *ngSwitchCase="
                        batchItem.formFactory.form.value.Radio === 'combBiomaterial' ||
                        (batchItem.formFactory.fileForm.value.file !== null && batchItem.formFactory.fileForm.value.file?.length !== 0)
                    "
                    class="confirm-button"
                    mat-button
                    [disabled]="
                        (processing$ | async) ||
                        batchItem.formFactory.form.invalid ||
                        batchItem.formFactory.fileForm.value.file === null ||
                        batchItem.formFactory.fileForm.value?.file?.length === 0
                    "
                    [class.spinner]="processing$ | async"
                    (click)="onCreateBatch(true, stepper)"
                >
                    Validate
                </button>
                <button
                    *ngSwitchCase="
                        batchItem.formFactory.form.value.Radio === 'withBiomaterial' &&
                        (batchItem.formFactory.fileForm.value.file === null || batchItem.formFactory.fileForm.value.file?.length === 0)
                    "
                    class="confirm-button"
                    mat-button
                    [disabled]="(processing$ | async) || batchItem.formFactory.form.invalid"
                    [class.spinner]="processing$ | async"
                    (click)="createEmptyBatch()"
                >
                    Create
                </button>
            </ng-container>
        </div>
    </mat-step>
    <mat-step>
        <!-- <ng-template matStepLabel>Confirm</ng-template> -->
        <app-simple-table
            id="app_view_verify_table"
            [data]="tableData$ | async"
            [hasSearch]="true"
            [tableDefinition]="tableDefinition"
            [hasActions]="false"
            idKey="id"
        ></app-simple-table>
        <div [hidden]="!(isSuccess$ | async)">
          <input id="file" accept="text/csv" type="file" [ngStyle]="{ display: 'none' }"
                 (click)="$event.target.value=null" (change)="revalidateFile($event.target.files)" #fileInput />

          <button mat-raised-button (click)="fileInput.click()">Revalidate file</button>
          <div class="pfr-file-list"  *ngIf="(batchItem.formFactory.fileForm.value.file?.length > 0)">
              <span class="pfr-file">
                  <mat-icon class="align-center">insert_drive_file</mat-icon>
              </span>
              <span class="pfr-file-item">{{batchItem.formFactory.fileForm.value.file[0].name}}</span>
              <mat-hint *ngIf="errorMessage" class="mat-hint">{{errorMessage}}</mat-hint>
          </div>

        </div>
        <div>
            <button [disabled]="isSuccess$ | async" mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="OnTolistView()">Cancel</button>
            <button [disabled]="isSuccess$ | async" mat-button (click)="onCreateBatch(false, stepper)">Ok</button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
