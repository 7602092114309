import { Component, ViewChild } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { MatStepper } from '@angular/material/stepper'
import { BatchItemComponent } from '../batch-item/batch-item.component'
import { BatchItemViewModel } from '../../viewModels/batch-item-view-model'
import { BatchesTableFactory } from './../../factories/batches-table-factory'
import { TableDefinition, BiomaterialImportResponse, TypeHelper } from '@plantandfood/kup.core'
import { BiomaterialCombImportResponse } from 'biomaterial-batches/models/biomaterial-comb-import-response.model'
import { BiomaterialBatchesService } from '../../../app/api/services/biomaterial-batches.service'
import { catchError, map } from 'rxjs/operators'

@Component({
    selector: 'pfr-create-batch-item',
    templateUrl: './create-batch-item.component.html',
    styleUrls: ['./create-batch-item.component.scss'],
    providers: [BatchItemViewModel, BatchesTableFactory],
})
export class CreateBatchItemComponent {
    private readonly _processing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public readonly processing$: Observable<boolean> = this._processing.asObservable()

    private readonly _isSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public readonly isSuccess$: Observable<boolean> = this._isSuccess.asObservable()

    tableDefinition: TableDefinition = BatchesTableFactory.batchBiomaterials()
    tableData$: Observable<any[]>
    errorMessage: string
    @ViewChild(BatchItemComponent, { static: true })
    batchItem: BatchItemComponent

    constructor(private biomaterialBatchesService: BiomaterialBatchesService) {}

    onVerified(response: any) {
        this._toggleProcessing(false)
        if ((this.batchItem.formFactory.form.value as any).Radio === 'withBiomaterial') {
            const tableData: BiomaterialImportResponse[] = response.results
            this.tableData$ = of(tableData)
            this.tableDefinition = BatchesTableFactory.batchBiomaterials()
        } else if ((this.batchItem.formFactory.form.value as any).Radio === 'combBiomaterial') {
            const combTableData: BiomaterialCombImportResponse[] = response.results
            this.tableData$ = of(combTableData)
            this.tableDefinition = BatchesTableFactory.batchCombBiomaterials()
        }

        if (response.status === 'SUCCESS' || response.status === 'WARNING') {
            this._isSuccess.next(false)
        } else {
            this._isSuccess.next(true)
        }
    }

    OnTolistView() {
        this.batchItem.viewModel.toListView()
    }

    revalidateFile(files) {
        const fileReader = new FileReader()
        const self = this

        this.batchItem.formFactory.fileForm.setValue({ file: files })

        fileReader.onload = () => {
            const fileValue = { file: files }

            self.batchItem.formFactory.fileForm.value = { ...fileValue }
            const batchModel = TypeHelper.clone({
                ...this.batchItem.formFactory.form.value,
                ...this.batchItem.formFactory.fileForm.value,
            })
            const verifyImportResponse = this.biomaterialBatchesService.verfiyImport(batchModel, (self.batchItem.formFactory.form.value as any).Radio).pipe(
                map((response: any) => response),
                catchError((error: any) => {
                    if (error.status === 500) {
                        if (this.batchItem.formFactory.form.value.Radio === 'combBiomaterial') {
                            self.errorMessage =
                                'CSV file structure: expecting two columns, first column - Acceptor Biomaterial Name (Mandatory), second column - Donor Biomaterial Name (Mandatory)'
                        } else if (this.batchItem.formFactory.form.value.Radio === 'withBiomaterial') {
                            self.errorMessage =
                                'CSV file structure: expecting one or two columns, first column - Biomaterial Name (Mandatory), second column - Genotype Name (Optional)'
                        }
                    } else if (error.status == 400) {
                        console.log(`%c Debug -> `, 'color: #ff0000;', error)
                        self.errorMessage = error.error.message
                    }
                    return of(error)
                })
            )

            verifyImportResponse.subscribe(r => {
                if (r.status == 'SUCCESS') {
                    const el: any = document.querySelector('.file-upload-input')
                    el.value = ''
                }
                this.onVerified(r)
            })
        }
        fileReader.onerror = e => {
            console.log(e)
        }
        fileReader.readAsText(files[0])
    }

    async onCreateBatch(validate: boolean, stepper: MatStepper) {
        this._toggleProcessing(true)
        await this.batchItem.onUploadBatchFile(validate, stepper)
    }

    async createEmptyBatch() {
        this._toggleProcessing(true)
        await this.batchItem.createBatchWithNoBiomaterial()
        this._toggleProcessing(false)
    }

    private _toggleProcessing(processing?: boolean): void {
        if (processing !== null || processing !== undefined) {
            this._processing.next(processing)
            this._isSuccess.next(processing)
            return
        }

        const current = this._processing.getValue()
        this._processing.next(!current)
    }
}
