import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { UserRole } from '@plantandfood/kup.core'
import { PageTitles } from '../app/constants/page-titles.constants'
import { AuthGuard } from '../app/guards/auth.guard'
import { BatchItemComponent } from './containers/batch-item/batch-item.component'
import { BiomaterialBatchesComponent } from './containers/biomaterial-batches/biomaterial-batches.component'
import { CreateBatchItemComponent } from './containers/create-batch-item/create-batch-item.component'
import { BiomaterialBatchesResolver } from './guards/biomaterial-batches.resolver'

const routes: Routes = [
    {
        path: 'biomaterial-batches',
        component: BiomaterialBatchesComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
            title: `${PageTitles.VIEW_BATCHES}`,
        },
        resolve: {
            batches: BiomaterialBatchesResolver,
        },
    },
    {
        path: 'biomaterial-batches/create',
        component: CreateBatchItemComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
            title: `${PageTitles.CREATE_BATCH}`,
        },
    },
    {
        path: 'biomaterial-batches/edit/:entity/:uuid',
        component: BatchItemComponent,
        canActivate: [AuthGuard],
        data: {
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
            title: `${PageTitles.EDIT_BIOMATERIAL_BATCH}`,
        },
    },
    {
        path: 'biomaterial-batches/edit/:entity/:uuid/biomaterials',
        loadChildren: () => import('../biomaterials/biomaterials.module').then(m => m.BiomaterialsModule),
        data: {
            permissions: {
                only: [UserRole.KUP_USER],
                redirectTo: '403',
            },
            title: `${PageTitles.BIOMATERIALS}`,
        },
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, BiomaterialBatchesResolver],
})
export class BiomaterialBatchesRoutingModule {}
