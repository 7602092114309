import { Component } from '@angular/core'
import { FieldType } from '@ngx-formly/core'

@Component({
    selector: 'pfr-formly-field-file',
    template: `
        <div>
            <input [id]="id" type="file" [ngStyle]="{ display: 'none' }" [formControl]="formControl" [formlyAttributes]="field" (change)="onChange()" #fileInput />
            <button mat-raised-button (click)="fileInput.click()">Choose a File</button>
            <br />
            <br />
            <ul *ngIf="fileList.length > 0" class="file-list">
                <li *ngFor="let file of fileList"><mat-icon>insert_drive_file</mat-icon> {{ file.name }}</li>
            </ul>
        </div>
    `,
})
export class FormlyFieldFileComponent extends FieldType {
    get fileList(): File[] {
        const files: File[] = this.formControl.value
        return files && files.length > 0 ? files : []
    }

    onChange() {
        const fileList: FileList = this.formControl.value
        const fileListArray: File[] = Array.from<File>(fileList)
        this.formControl.setValue(fileListArray)
    }
}
