<app-standard-view [canCreate]="viewModel.canCreate" [busyState]="viewModel.loadingState" (createClick)="viewModel.create()">
    <app-data-table
        id="app_view_biomaterail_batches_table"
        [tableDefinition]="tableFactory.biomaterialBatches()"
        [query]="viewModel.query"
        [configColms]="true"
        [selectable]="true"
        (selectRow)="viewModel.edit($event)"
    >
        ></app-data-table
    >
</app-standard-view>
