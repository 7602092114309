import { RouterEvent, NavigationEnd, Router, ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map, mergeMap } from 'rxjs/operators'

import { PageTitles } from '../constants/page-titles.constants'

class PageHelper {
    public static setPageTitle(titleService: Title, router: Router, activatedRoute: ActivatedRoute) {
        router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                map(() => activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                titleService.setTitle(event.title ? `${PageTitles.APP_TITLE} - ${event.title} ` : `${PageTitles.APP_TITLE}`)
            })
    }
}

export default PageHelper
