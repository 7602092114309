import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AppFacade } from '../../app/facade'
import { ErrorPage } from '../error-page/error-page.component'

@Component({
    selector: 'pfr-forbidden',
    templateUrl: '../error-page/error-page.component.html',
    styleUrls: ['../error-page/error-page.component.scss'],
})
export class ForbiddenComponent extends ErrorPage {
    constructor(route: ActivatedRoute, private facade: AppFacade) {
        super(route, 'FORBIDDEN 403', 'You do not have access to view this page')
    }
}
