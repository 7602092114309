import { printingApi } from './../../constants/path.constants'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { AsyncApiResponse, ServiceBase } from '@plantandfood/kup.core'
import { PrintResponse } from '../../models/print-response.model'

@Injectable({ providedIn: 'root' })
export class PrintService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http, printingApi)
    }

    printLabels(params: Params): AsyncApiResponse<PrintResponse> {
        return this.postAsApiResponse(`printRequests/byBatch`, null, params)
    }
}
