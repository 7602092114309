import { Directive, HostListener } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

@Directive({
    // tslint:disable-next-line
    selector: 'input[type=file]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileValueAccessorDirective,
            multi: true,
        },
    ],
})
// https://github.com/angular/angular/issues/7341
export class FileValueAccessorDirective implements ControlValueAccessor {
    value: any

    @HostListener('change', ['$event.target.files'])
    onChange = _ => {}

    @HostListener('blur') onTouched = () => {}

    writeValue() {}

    registerOnChange(fn: any) {
        this.onChange = fn
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn
    }
}
