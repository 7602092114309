/**
 * A text-resource dictionary.
 * All application text resources (labels, field-names, messages, form-titles, etc) should be defined here,
 * enabling changes made in one place to be reflected
 * across the application. This will both save time and ensure consistency.
 *
 * It will be possible to port this instance to a JSON file should that be desirable
 * (for example to integrate a package such as `ngx-translate`).
 */
export const TextResourceSet = {
    'entity-type': {
        batch: 'Batch',
    },
    tooltip: {
        'biomateFrials-used-in-batch': 'Biomaterials used in Batches',
        'biomaterials-in-batch': 'Select to remove Biomaterials from this Batch',
        'biomaterials-ex-batch': 'Select to add Biomaterials to this Batch',
        'add-selected-biomaterials': 'Add selected',
        'remove-selected-biomaterials': 'Remove selected',
    },
    button: {
        'biomaterials-in-batch': 'Biomaterials in Batch',
        'biomaterials-ex-batch': 'Biomaterials NOT in Batch',
    },
    label: {
        'add-to-batch': 'Add',
        'remove-from-batch': 'Remove',
    },
    message: {
        'no-biomaterial-types': 'No biomaterials available.',
    },
}
