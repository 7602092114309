import { ApiService, DropdownDatasource, DropdownProvider } from '@plantandfood/kup.core'
import { map } from 'rxjs'

export class BatchIdFilteredDropdownProvider extends DropdownProvider {
    constructor(service: ApiService, batchType: string) {
        super(
            DropdownDatasource.createMapDynamic((filter: string) => {
                const maxLength = 20
                filter = filter != undefined ? filter.toLowerCase() : ''
                const params = `biomaterialBatch.name.contains=${filter}&page=0&size=${maxLength}&biomaterialBatch.type=${batchType}`
                const obs = service
                    .apiService(
                        {
                            action: 'GET',
                            endpoint: 'biomaterialBatches',
                        },
                        null,
                        null,
                        `?projection=biomaterialBatchManagementTable&${params}`
                    )
                    .pipe(map(res => res.result._embedded.biomaterialBatches))
                return obs
            }, 'uuid')
        )
    }
}
