import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { FilteredQueryResult, FilterPanelService } from 'kup.common.ui'
import { FilteredQueryBase, FilterModelType, QueryActionHelper, QueryErrorPageHelper, QueryRouteHelper } from '../../filter-query-services'
import { map } from 'rxjs/operators'
import { BatchesFormFactory } from '../factories/batches-form-factory'
import { BiomaterialBatchFilterDefinition } from '../models/biomaterial-batch-filter-definition'
import { Batch } from './../models/batch.model'
import { BatchesConfigService } from './batches-config.service'
import { BiomaterialBatchesService } from '../../app/api/services/biomaterial-batches.service'

@Injectable()
export class BiomaterialBatchesQueryCoordinator extends FilteredQueryBase<{}, {}, Batch> {
    constructor(
        private service: BiomaterialBatchesService,
        private formFactory: BatchesFormFactory,
        filterPanelService: FilterPanelService,
        queryRouteHelper: QueryRouteHelper,
        queryErrorPageHelper: QueryErrorPageHelper,
        queryActionHelper: QueryActionHelper,
        configService: BatchesConfigService
    ) {
        super(FilterModelType.Batches, filterPanelService, BiomaterialBatchFilterDefinition.getFields(), queryRouteHelper, queryErrorPageHelper, queryActionHelper, configService)
    }

    protected execute(params?: Params): Promise<FilteredQueryResult<Batch>> {
        params = this.updateParams(['biomaterialBatch.name'], params)
        return this.service
            .getBiomaterialBatches(params)
            .pipe(
                map(result => {
                    return this.toFilteredQueryResult(result._embedded, result.page)
                })
            )
            .toPromise()
    }

    protected createFormDefinition(): FormlyFieldConfig[] {
        return this.formFactory.batchFilterForm()
    }
}
