export enum PageTitles {
    APP_TITLE = 'Biomaterial Inventory and Tracking',
    DASHBOARD = 'Dashboard',
    VIEW_SAMPLES = 'Samples',
    CREATE_SAMPLES = 'Create Samples',
    VIEW_BATCHES = 'Batches',
    VIEW_BIOMATERIAL_COLLECTIONS = 'Biomaterial Collections',
    BATCH_BIOMATERIALS = 'Biomaterials',
    EDIT_BIOMATERIAL_BATCH = 'Edit Batch',
    CREATE_BATCH = 'Create Batch',
    EDIT_BIOMATERIAL_COLLECTION = 'Edit Biomaterial Collection',
    BIOMATERIAL_COLLECTION_ITEM = 'Biomaterial Collection',
    NOTFOUND = '404 - Page',
    BIOMATERIALS = 'Biomaterials',
    PLANTS = 'Plants',
    SAMPLES = 'Samples',
    FRUITS = 'Fruit',
    CANES = 'Canes',
    SCIONS = 'Scions',
    BIOMATERIAL_CREATION_METHODS = 'Biomaterial Creation Methods',
    BIOMATERIAL_CREATION_METHOD = 'Biomaterial Creation Method',
    ADD_NEW = 'Add New',
    GENOTYPE_REGISTRIES = 'Genotype Registries',
    GENOTYPE_REGISTRY = 'Genotype Registry',
    REQUESTS = 'Requests',
    CREATE_REQUEST = 'Create Request',
    EDIT_REQUESTS = 'Edit Request',
}
