import { HttpClient } from '@angular/common/http'
import { never, Observable, Subject } from 'rxjs'
import { catchError } from 'rxjs/operators'

/**
 * HttpClient actions are not initiated unless one or more observers are subscribed to their asscociated Observables.
 * Furthermore, HttpClient actions are initiated once for each subscribed observer.
 * HttpClientProxy acts as a proxy for an HttpClient instance, thereby ensuring that:
 * (a) The expected Http action is initiated irrespective of whether or not client code is observing it; and
 * (b) The action method returns an Observable which can be subscribed to any number of times without initiating further Http actions
 */
export class HttpClientProxy {
    private constructor(private http: HttpClient) {}

    static create(http: HttpClient): HttpClient {
        return <any>new HttpClientProxy(http)
    }

    request(method: any, url?: any, options?: any): Observable<any> {
        return proxy(this.http.request(method, url, options))
    }

    delete(url: any, options?: any): Observable<any> {
        return proxy(this.http.delete(url, options))
    }

    get(url: any, options?: any): Observable<any> {
        return proxy(this.http.get(url, options))
    }

    head(url: any, options?: any): Observable<any> {
        return proxy(this.http.head(url, options))
    }

    jsonp(url: any, callbackParam: any): Observable<any> {
        return proxy(this.http.jsonp(url, callbackParam))
    }

    options(url: any, options?: any): Observable<any> {
        return proxy(this.http.options(url, options))
    }

    patch(url: any, body: any, options?: any): Observable<any> {
        return proxy(this.http.patch(url, body, options))
    }

    post(url: any, body: any, options?: any): Observable<any> {
        return proxy(this.http.post(url, body, options))
    }

    put(url: any, body: any, options?: any): Observable<any> {
        return proxy(this.http.put(url, body, options))
    }
}

function proxy<T>(initiator: Observable<T>): Observable<T> {
    let proxy = new Subject<T>()
    let subscription = initiator
        .pipe(
            catchError(e => {
                subscription.unsubscribe()
                subscription = undefined
                proxy.error(e)
                return never()
            })
        )
        .subscribe(value => {
            subscription.unsubscribe()
            subscription = undefined
            proxy.next(value)
            // Complete must be called to allow any downstream toPromise() translations to work.
            proxy.complete()
        })

    return proxy.asObservable()
}
