import { TextResourceSet } from './constants/text-resource-set'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { BiomaterialBatchesModule } from '../biomaterial-batches/biomaterial-batches.module'
import { CoreModule } from '../core/core.module'
import { ErrorPagesModule } from '../error-pages/error-pages.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './containers/app/app.component'
import { SharedModule } from './shared/shared.module'
import { RequestsModule } from '../requests/requests.module'
import { AppLocaleProvider } from '../core/i18n/locale.provider'

import { KupEnvModule, KupEnvService } from '@plantandfood/kup.env'
import { KupAuthModule } from '@plantandfood/kup.auth'
import { KupCoreModule } from '@plantandfood/kup.core'
import { DashboardModule } from '../dashboard/dashboard.module'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ScionsTableFactory } from 'scions/factories/scions-table-factory'
import { CanesTableFactory } from 'canes/factories/canes-table-factory'
import { PlantsTableFactory } from 'plants/factories/plants-table-factory'
import { SamplesTableFactory } from 'biomaterial-samples/factories/samples-table-factory'
import { FruitsTableFactory } from 'fruits/factories/fruits-table-factory'
import { navKupIcons } from 'app/constants/icons.constants'

declare const window: any

export function envFactory() {
    return window.env
}

export function authConfigFactory(envService: KupEnvService) {
    return {
        keycloakConfig: envService.get<any>('keycloakConfigOptions'),
        keycloakInitOptions: envService.get<any>('keycloakInitOptions'),
    }
}

export function coreConfigFactory(envService: KupEnvService) {
    return {
        managementApi: envService.get<string>('managementApiHost') + envService.get<string>('managementApiPath'),
        printingApi: envService.get<string>('managementApiHost') + envService.get<string>('printingApiPath'),
        textResourceSet: TextResourceSet,
        icons: navKupIcons,
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule,
        AppRoutingModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        KupEnvModule.forRoot({
            useFactory: envFactory,
        }),
        KupCoreModule.forRoot({
            useFactory: coreConfigFactory,
            deps: [KupEnvService],
        }),
        KupAuthModule.forRoot({
            useFactory: authConfigFactory,
            deps: [KupEnvService],
        }),

        // Eager Loaded Features
        DashboardModule,
        RequestsModule,
        BiomaterialBatchesModule,

        // NOTE: ErrorPagesModule must be last
        ErrorPagesModule,

        FontAwesomeModule,
    ],
    providers: [AppLocaleProvider, ScionsTableFactory, CanesTableFactory, PlantsTableFactory, SamplesTableFactory, FruitsTableFactory],
    bootstrap: [AppComponent],
})
export class AppModule {}
