<app-standard-view [busyState]="viewModel.loadingState" [canCreate]="false" [canCancel]="true" (cancelClick)="viewModel.toListView()" [buttons]="topButtons">
    <pfr-request-status *ngIf="!viewModel.isCreateMode && viewModel.request" [status]="viewModel.request.status"></pfr-request-status>
    <span *ngIf="!viewModel.isRecipient && !viewModel.isCreateMode" class="warning">
        <mat-icon class="material-icons orange600 md-48">warning</mat-icon>
        <span> Operations Group has been removed from the associated Trial. </span>
        <span *ngIf="viewModel.request.status != 'IN_PROGRESS' && viewModel.request.status != 'COMPLETED'"> Please select a new Recipient! </span>
    </span>
    <div class="source-batch-error-message">
        <mat-icon class="material-icons orange600 md-48">warning</mat-icon>
        <span>No biomaterials of the correct type found in this batch. Did you choose the right creation method?</span>
    </div>
    <app-simple-standard-form
        [busyState]="viewModel.loadingState"
        [fields]="formFactory.fields"
        [readOnly]="!viewModel.canEdit"
        [model]="viewModel.request"
        [form]="formFactory.form"
    ></app-simple-standard-form>
</app-standard-view>
