<form (ngSubmit)="executeQuery()">
    <h1 mat-dialog-title>Configure Columns</h1>

    <div mat-dialog-content>
        <div class="sortable-checklist">
            <mat-list class="column-config-header">
                <mat-list-item>
                    <span class="name-field"><strong>Column</strong></span>
                    <span class="field"><strong>Show</strong></span>
                </mat-list-item>
            </mat-list>

            <mat-list class="checklist-items" *ngFor="let column of availableColumns; let i = index">
                <mat-list-item *ngIf="!column.excluded">
                    <span class="name-field">
                        {{ column.header }}
                    </span>
                    <span class="field">
                        <mat-checkbox [checked]="column.show" [name]="column.columnDef" (change)="onToggleShow(column)"> </mat-checkbox>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

    <mat-dialog-actions>
        <button mat-stroked-button type="button" (click)="onClose()" tabindex="-1">Cancel</button>
        <button mat-flat-button type="submit" tabindex="1" color="primary">Save</button>
    </mat-dialog-actions>
</form>
