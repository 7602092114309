import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ErrorPage } from './../error-page/error-page.component'

@Component({
    selector: 'pfr-not-found',
    templateUrl: '../error-page/error-page.component.html',
    styleUrls: ['../error-page/error-page.component.scss'],
})
export class NotFoundComponent extends ErrorPage {
    constructor(route: ActivatedRoute) {
        super(route, 'ERROR 404', 'Page not found')
    }
}
