/**
 * Defines constants used to describe filter fields and filter selector groups throughout the application.
 */
export enum FilterFieldLabel {
    Name = 'Name',
    Requester = 'Requester',
    RequestType = 'Request Type',
    DateRequested = 'Date Requested',
    Recipient = 'Recipient',
    Location = 'Location',
    WoodType = 'Wood Type',
    Sex = 'Sex',
    Genotype = 'Genotype',
    GenotypeLabel = 'Genotype Label',
    GenotypeCreationMethod = 'Genotype Creation Method',
    Taxon = 'Taxon',
    Ploidy = 'Ploidy',
    ActiveStatus = 'Active Status',
    BiomaterialCollection = 'Biomaterial Collection',
    CreationMethod = 'Creation Method',
    Site = 'Site',
    Block = 'Block',
    Row = 'Row',
    ActiveScionCount = 'Active Scion Count',
    StartYear = 'Start Year',
    GraftYear = 'Graft Year',
    BiomaterialName = 'Biomaterial Name',
    Trial = 'Trial',
    Observation = 'Observation',
    Trait = 'Trait',
    Method = 'Method',
    Scale = 'Scale',
    Process = 'Process',
    Season = 'Season',
    User = 'User',
    NeedsChecking = 'Needs Checking',
    Hidden = 'Hidden',
    Contact = 'Contact',
    Permissions = 'My Permissions',
    ShortCode = 'Short Code',
    Tags = 'Tags',
    Status = 'Status',
    GenotypeRegistry = 'Genotype Registry',
    LegalEntity = 'Legal Entity',
    BiomaterialCreationMethod = 'Biomaterial Creation Method'
}
