import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '../app/shared/shared.module'
import { FormlyModule } from '@ngx-formly/core'
import { BiomaterialBatchesRoutingModule } from './biomaterial-batches-routing.module'
import { BiomaterialBatchesComponent } from './containers/biomaterial-batches/biomaterial-batches.component'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { BatchItemComponent } from './containers/batch-item/batch-item.component'
import { CreateBatchItemComponent } from './containers/create-batch-item/create-batch-item.component'
import { BatchesConfigService } from './services/batches-config.service'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        BiomaterialBatchesRoutingModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({
            validationMessages: [{ name: 'required', message: 'This field is required' }],
        }),
    ],
    declarations: [BiomaterialBatchesComponent, CreateBatchItemComponent, BatchItemComponent],
    providers: [
        DatePipe,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        BatchesConfigService,
    ],
})
export class BiomaterialBatchesModule {}
