import { AuthService } from '@plantandfood/kup.auth'
import { Component } from '@angular/core'
import { UserRole } from '@plantandfood/kup.core'
import { requestAccessJiraUrl } from '../../../app/constants/path.constants'
import * as jQuery from 'jquery'

@Component({
    selector: 'app-dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent {
    readonly hasAccess: boolean

    constructor(private authService: AuthService) {
        this.hasAccess = this.authService.isUserInRole(UserRole.KUP_USER)
    }

    onRequestAccess() {
        jQuery.ajax({
            url: requestAccessJiraUrl,
            type: 'get',
            cache: true,
            dataType: 'script',
        })
        window['ATL_JQ_PAGE_PROPS'] = {
            triggerFunction: function (showCollectorDialog: () => void) {
                showCollectorDialog()
            },
        }
    }
}
