import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { RouterModule } from '@angular/router'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FilterPanelModule, NavbarModule, SidebarModule } from 'kup.common.ui'
import { KupCoreModule } from '@plantandfood/kup.core'
import { ColumnConfigModalComponent } from './column-config-modal/column-config-modal.component'
import { AppFormlyModule } from './formly.module'
import { BoldSpanPipe } from './pipes/bold-span.pipe'
import { DataTableComponent } from './table/data-table.component'
import { AutocompleteTypeComponent } from './types/autocomplete.component'
import { FormlyFieldFileComponent } from './types/file-type.component'
import { FileValueAccessorDirective } from './types/file-value-accessor'
import { SimpleTableComponent } from './table/simple-table.component'

@NgModule({
    imports: [
        SweetAlert2Module.forRoot(),
        KupCoreModule,
        RouterModule,
        AppFormlyModule,
        FlexLayoutModule,
        FontAwesomeModule,
        FilterPanelModule,
        SidebarModule,
        NavbarModule,
        //NgxLibMaterialModule,
        // Common libraries
    ],
    exports: [
        SimpleTableComponent,
        DataTableComponent,
        AutocompleteTypeComponent,
        FormlyFieldFileComponent,
        SweetAlert2Module,
        KupCoreModule,
        RouterModule,
        FlexLayoutModule,
        AppFormlyModule,
        FontAwesomeModule,
        FileValueAccessorDirective,
        FilterPanelModule,
        SidebarModule,
        NavbarModule,
        //NgxLibMaterialModule,
        BoldSpanPipe,
    ],
    declarations: [
        SimpleTableComponent,
        DataTableComponent,
        ColumnConfigModalComponent,
        AutocompleteTypeComponent,
        FileValueAccessorDirective,
        FormlyFieldFileComponent,
        BoldSpanPipe,
    ],
    entryComponents: [ColumnConfigModalComponent],
    providers: [],
})
export class SharedModule {}
