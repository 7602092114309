import { ForbiddenComponent } from './forbidden/forbidden.component'
import { AppErrorComponent } from './app-error/app-error.component'
import { NgModule } from '@angular/core'
import { CoreModule } from '@angular/flex-layout'
import { SharedModule } from './../app/shared/shared.module'
import { ErrorPagesRoutingModule } from './error-pages-routing.module'
import { NotFoundComponent } from './not-found/not-found.component'

@NgModule({
    declarations: [NotFoundComponent, AppErrorComponent, ForbiddenComponent],
    imports: [CoreModule, SharedModule, ErrorPagesRoutingModule],
})
export class ErrorPagesModule {}
