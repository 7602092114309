import { Router } from '@angular/router'
import { ApiResponse, ApiResponseCode } from '@plantandfood/kup.core'
import { Injectable } from '@angular/core'

export type ErrorPageType = 'AppError' | 'HttpError' | 'InternalServerError' | 'BadRequest' | 'Forbidden' | 'NotFound'
export interface ErrorPageInfo {
    code: string
    message: string
}

@Injectable()
export class QueryErrorPageHelper {
    constructor(private router: Router) {}

    gotoErrorPageAuto(apiResponse: ApiResponse<any>): Promise<boolean> {
        let target: ErrorPageType
        if (apiResponse) {
            switch (apiResponse.code) {
                case ApiResponseCode.BadRequest:
                    target = 'BadRequest'
                    break

                case ApiResponseCode.NotFound:
                    target = 'NotFound'
                    break

                case ApiResponseCode.AccessDenied:
                    target = 'Forbidden'
                    break

                case ApiResponseCode.InternalServerError:
                    target = 'InternalServerError'
                    break

                case ApiResponseCode.HttpError:
                    target = 'HttpError'
                    break
            }
        }
        return this.gotoErrorPage(target != undefined ? target : 'AppError', apiResponse)
    }

    gotoErrorPage(target: ErrorPageType, details: ApiResponse<any> | ErrorPageInfo): Promise<boolean> {
        let route: string
        switch (target) {
            case 'BadRequest':
                route = '400'
                break

            case 'Forbidden':
                route = '403'
                break

            case 'NotFound':
                route = '404'
                break

            case 'InternalServerError':
                route = '500'
                break

            case 'HttpError':
                route = 'http-error'
                break

            default:
                route = 'error'
                break
        }

        let options: any
        if (details) {
            options = {
                queryParams: {
                    code: details.code,
                    message: details.message,
                },
            }
        }

        return this.router.navigate([route], options)
    }
}
