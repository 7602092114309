import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core'
import { StandardFormButton, KupSnackBarService } from '@plantandfood/kup.core'
import { BatchItemViewModel } from '../../viewModels/batch-item-view-model'
import { BatchesFormFactory } from '../../factories/batches-form-factory'
import { Batch, BatchRequest } from '../../models/batch.model'
import { MatStepper } from '@angular/material/stepper'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { BatchesTableFactory } from 'biomaterial-batches/factories/batches-table-factory'
import { BiomaterialsService } from 'app/api/services/biomaterials.service'

@Component({
    selector: 'pfr-batch-item',
    templateUrl: './batch-item.component.html',
    styleUrls: ['./batch-item.component.scss'],
    providers: [BatchItemViewModel, BatchesFormFactory, BatchesTableFactory],
})
export class BatchItemComponent implements OnInit, OnDestroy {
    readonly topButtons: StandardFormButton<Batch>[]
    @Output() verified = new EventEmitter<any>()
    subscription = new Subscription()

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        readonly viewModel: BatchItemViewModel,
        readonly formFactory: BatchesFormFactory,
        private kupSnackBarService: KupSnackBarService,
        readonly tableFactory: BatchesTableFactory,
        private _biomaterialService: BiomaterialsService
    ) {
        if (!this.viewModel.isCreateMode) {
            this.topButtons = [
                {
                    tooltip: 'Biomaterials belonging to this Batch',
                    svgIcon: 'biomaterial_white',
                    disabled: () => (this.viewModel.batch?.type === 'COMBINATION' ? true : false),
                    click: () => {
                        if (!!this.viewModel.requestId) {
                            const sourceBatch = this.viewModel.batch.name + '(Source Batch)'
                            this._biomaterialService.entityNameSubject.next(sourceBatch)
                            return this.router.navigate([`/biomaterial-batches/edit/Batch/${this.viewModel.batch.uuid}/biomaterials`], {
                                queryParams: { requestId: this.viewModel.requestId },
                            })
                        }

                        this.router.navigate([`biomaterials/`], { relativeTo: this.route })
                    },
                },
                {
                    tooltip: 'Save Batch',
                    svgIcon: 'save_white',
                    click: () => {
                        this.viewModel.save()
                    },
                    disabled: () => {
                        return this.formFactory.form.pristine
                    },
                },
            ]
        }
    }

    ngOnInit() {
        this.viewModel.init()
    }

    onUploadBatchFile(validate: boolean, stepper?: MatStepper): Promise<void> {
        return new Promise(resolve => {
            if (validate) {
                if (!this.formFactory.form.invalid) {
                    this.subscription.add(
                        this.viewModel.createBatchItem(validate).subscribe(res => {
                            const verifiedRes = res
                            this.verified.emit(verifiedRes)
                            if (!res.error) {
                                stepper.next()
                            }
                            return resolve()
                        })
                    )
                } else {
                    this.kupSnackBarService.open('Enter required fields marked with *', '')
                    return resolve()
                }
            } else {
                this.subscription.add(
                    this.viewModel.createBatchItem(validate).subscribe(res => {
                        this.viewModel.toItemView(res.name, res.uuid)
                        return resolve()
                    })
                )
            }
        })
    }

    createBatchWithNoBiomaterial() {
        if (!this.formFactory.form.invalid) {
            this.subscription.add(
                this.viewModel.createEmptyBatchItem().subscribe(res => {
                    this.viewModel.toItemView(res.name, res.uuid)
                })
            )
        } else {
            this.kupSnackBarService.open('Enter required fields marked with *', '')
        }
    }

    toBatchRequest(request: BatchRequest) {
        this.router.navigate([`/requests/edit/${request.uuid}`])
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
}
