import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@plantandfood/kup.auth'
import { UserRightsMask, ApiResponse, ApiResponseCode, UserRole } from '@plantandfood/kup.core'

@Injectable({ providedIn: 'root' })
export class RolesService {
    constructor(private authService: AuthService, private router: Router) {}

    /**
     * Validates that the current user has permission to access the application, and navigates to an error page if validation fails.
     */
    enforceApplicationAccess(): boolean {
        return this.enforceInRole(UserRole.KUP_USER)
    }

    /**
     * Validates that the current user has membership of the specified `UserRole` and navigates to an error page if validation fails.
     * @param role The role to validate.
     */
    enforceInRole(role: UserRole): boolean {
        const details = ApiResponse.error(ApiResponseCode.AccessDenied)
        const result = this.isUserInRole(role)

        if (!result) {
            let options: any

            if (details) {
                options = {
                    queryParams: {
                        code: details.code,
                        message: details.message,
                    },
                }
            }
            this.router.navigate(['403'], options)
        }
        return result
    }

    getUserRoles(): string[] {
        return this.authService.getUserRoles()
    }

    /**
     * Returns true if the current user is in any of the specified roles, otherwise returns false.
     * @param role The role, or the array of roles, to evaluate.
     */
    isUserInRole(role: UserRole | UserRole[]): boolean {
        if (role == undefined) return false
        if (!Array.isArray(role)) return this.authService.isUserInRole(role)

        let result = false
        for (let i = 0; !result && i < role.length; i++) {
            result = this.authService.isUserInRole(role[i])
        }

        return result
    }

    isUserAuthorised(role: UserRole, rights?: UserRightsMask): boolean {
        if (!this.isUserInRole(role)) return false
        if (rights == undefined) return true
        return (rights.rights & rights.requiredRights) === rights.requiredRights
    }
}
