import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router'
import { FilterPanelService } from 'kup.common.ui'
import { TypeHelper, UserProfile } from '@plantandfood/kup.core'
import { CustomFilterEventType } from '../../app-lib/filter/custom-filter-event-type'
import { appNavLinks } from '../../app-nav-links'
import { PageTitles } from '../../constants/page-titles.constants'
import { AppFacade } from '../../facade'
import { NavLinks } from '../../models/nav-link.model'
import { CustomDataService } from '../../services/custom-data.service'
import { Disposables } from '../../util/disposables'
import TitleHelper from '../../util/title-helper'
import { HttpClient } from '@angular/common/http'
import { KupEnvService } from '@plantandfood/kup.env'

declare const window: any

@Component({
    selector: 'pfr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav
    title = PageTitles.APP_TITLE
    svgIcon = 'home'
    links: NavLinks = appNavLinks
    version: string
    accountName: string
    username: string
    readonly showTestBanner: boolean
    readonly envName: string
    allApps: any
    baseUrl: string
    urlForApps: string

    constructor(
        private appFacade: AppFacade,
        private filterService: FilterPanelService,
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private customDataService: CustomDataService,
        private http: HttpClient,
        private envService: KupEnvService
    ) {
        this.showTestBanner = TypeHelper.sanitizeString(window.env.showTestBanner).toLowerCase() === 'true'
        this.version = window.env.version
        this.envName = window.env.envName
    }

    ngOnInit() {
        this.appFacade.initApp()
        TitleHelper.setPageTitle(this.titleService, this.router, this.activatedRoute)
        this.appFacade.users.userProfile$.subscribe((profile: UserProfile) => {
            this.accountName = profile.displayName
            this.username = profile.userName
            this.customDataService.currentUser(this.username)
        })

        const disposables: Disposables = new Disposables()
        disposables.subscribeTo(this.router.events, e => {
            if (e instanceof RoutesRecognized) {
                disposables.dispose()
            }
        })
        this.baseUrl = this.envService
            .get<string>('managementApiHost')
            .toString()
            .substring(8)
        if (this.baseUrl.slice(-1) == '/') {
            this.urlForApps = 'https://' + this.baseUrl + 'aapi/applications?sort=name'
        } else {
            this.urlForApps = 'https://' + this.baseUrl + '/aapi/applications?sort=name'
        }

        this.http.get<any>(this.urlForApps).subscribe(app => {
            this.allApps = Object.keys(app).map(key => app[key])[0].applications
            this.allApps.forEach((element, index) => {
                if (element.name === this.title) {
                    this.allApps.splice(index, 1)
                }
            })
        })
    }

    get filtersDisabled(): boolean {
        return this.filterService.isDisabled
    }

    get saveFiltersDisabled(): boolean {
        if (this.filtersDisabled) return true
        let state = this.filterService.contextState
        return state && state.saveDisabled === true
    }

    onLogout() {
        this.appFacade.auth.logout()
    }

    saveFilters() {
        this.filterService.raiseContextEvent({
            eventName: CustomFilterEventType.Save,
        })
    }

    navToApp(app) {
        window.open(app.url.toString().replace(/{base-url}/gi, this.baseUrl), '_blank')
    }
}
