<div class="pfr-app-theme">
    <app-navbar
        class="mat-elevation-z6"
        [title]="title"
        [svgIcon]="svgIcon"
        [accountName]="accountName"
        [showTestBanner]="showTestBanner"
        [envName]="envName"
        (logout)="onLogout()"
    >
        <div app-switcher-items>
            <button mat-menu-item *ngFor="let app of allApps; let i = index" (click)="navToApp(app)">
                <span class="app-title">{{ app.name }}</span>
            </button>
        </div>
    </app-navbar>

    <mat-sidenav-container class="app-root" fullscreen autosize>
        <mat-sidenav #sidenav mode="side" opened="true" class="app-sidenav no-print" [disableClose]="true">
            <app-sidebar [links]="links" [version]="version"> </app-sidebar>
        </mat-sidenav>
        <mat-sidenav-content>
            <section class="app-layout" fxLayout="column" fxLayout.gt-sm="row">
                <div class="app-layout__content" id="app_content" fxFlex ngClass.gt-sm="gt-sm">
                    <!-- ROUTE CONTENT -->
                    <mat-card>
                        <mat-card-content>
                            <router-outlet></router-outlet>
                        </mat-card-content>
                    </mat-card>
                </div>
            </section>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <app-filter-panel>
        <div app-filter-buttons>
            <button mat-icon-button matTooltip="{{ 'tooltip.save-filters' | appText }}" [disabled]="saveFiltersDisabled" (click)="saveFilters()">
                <mat-icon svgIcon="save_black"></mat-icon>
            </button>
        </div>
    </app-filter-panel>
</div>
