interface ImportStatus {
    matchStatus: string
    ligature: string
}

export const IMPORT_STATUSES: ImportStatus[] = [
    {
        matchStatus: 'Complete',
        ligature: 'complete',
    },
    {
        matchStatus: 'Pending processing',
        ligature: 'processing',
    },
    {
        matchStatus: 'Complete with errors',
        ligature: 'complete_with_errors',
    },
    {
        matchStatus: 'Uploading, Please Wait...',
        ligature: 'processing',
    },
    {
        matchStatus: 'Processing',
        ligature: 'processing',
    },
]

export class ImportStatusHelper {
    static getStatusLigature(status: string): string {
        const importStatus = IMPORT_STATUSES.find(s => s.matchStatus === status)
        return importStatus ? importStatus.ligature : 'fatal_error'
    }
}
