<app-standard-view [canCreate]="false" [canCancel]="!viewModel.isCreateMode" (cancelClick)="viewModel.toListView()" [buttons]="topButtons">
    <app-simple-standard-form
        [busyState]="viewModel.loadingState"
        [fields]="formFactory.fields"
        [readOnly]="false"
        [model]="viewModel.batch"
        [form]="formFactory.form"
    ></app-simple-standard-form>
    <div *ngIf="formFactory.form.value.Radio === 'withBiomaterial'">
        A biomaterial batch holds a single list of biomaterials of a single type. It’s for simple creation of biomaterials from a single source.
    </div>
    <div *ngIf="formFactory.form.value.Radio === 'combBiomaterial'">
        A combination batch holds a set of combinations of multiple biomaterials. It’s for things like crossing and grafting, when two biomaterials combine to create new
        biomaterials.
    </div>
    <div *ngIf="!this.viewModel.isCreateMode">
        <h2 class="sub_header">Requests</h2>
        <app-simple-table
            id="app_view_batch_requests_table"
            [hasSearch]="false"
            [paged]="false"
            [tableDefinition]="tableFactory.batchRequests()"
            [data]="viewModel.batchRequest"
            [selectable]="true"
            (selectRow)="toBatchRequest($event)"
        ></app-simple-table>
    </div>
    <div *ngIf="viewModel.batch?.type === 'COMBINATION' && !this.viewModel.isCreateMode">
        <h2 id="app_registration_protocol_header" class="sub_header">Combination Biomaterials</h2>
        <app-simple-table
            id="app_view_batch_combination_biomaterial_table"
            [hasSearch]="false"
            [paged]="false"
            [tableDefinition]="tableFactory.combinationBiomaterialInBatch()"
            [data]="viewModel.combBiomaterial"
        ></app-simple-table>
    </div>
</app-standard-view>

<form [formGroup]="formFactory.fileForm">
    <formly-form [fields]="formFactory.fileUploadField" [options]="formFactory.options" [form]="formFactory.fileForm"></formly-form>
</form>
