<h1 mat-dialog-title>{{ title }}</h1>
<p class="change-status-txt" [innerHTML]="message | boldSpan: regexStr"></p>
<div mat-dialog-content>
    <formly-form [form]="form" [fields]="fields" [options]="options" [model]="model"></formly-form>
</div>

<div mat-dialog-actions>
    <button mat-button mat-raised-button color="warn" (click)="onClose(null)">Cancel</button>
    <button type="submit" class="create-biomaterial-btn" (click)="onClose(model)" mat-raised-button color="primary" [disabled]="form.invalid">Ok</button>
</div>
