import { Injectable } from '@angular/core'
import { ConfigProvider } from '@plantandfood/kup.core'
import { AppFacade } from '../../app/facade'
import { ViewStore } from '../../app/services/view-store'

// used to make @plantandfood/kup.core compatible, it  will be removed when facades are removed
@Injectable()
export class RequestsConfigService {
    constructor(configProvider: ConfigProvider, facade: AppFacade, store: ViewStore) {
        configProvider.setConfig({
            facade,
            store,
        })
        return configProvider.getConfig()
    }
}
