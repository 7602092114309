import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UsersService } from '../../app/api/services/users.service'
import { FilterQueryViewModel } from '../../filter-query-services'

import { RequestsQueryCoordinator } from '../services/requests-query-coordinator'
import { BiomaterialRequest } from './../../app/entities/modules/requests/requests'

@Injectable()
export class RequestsViewModel extends FilterQueryViewModel<BiomaterialRequest, RequestsQueryCoordinator> {
    public canCreate: boolean = false
    constructor(query: RequestsQueryCoordinator, private readonly _router: Router, private readonly _userService: UsersService) {
        super(query)
    }

    create() {
        return this._router.navigate(['requests/new'])
    }

    edit(request: BiomaterialRequest) {
        return this._router.navigate([`requests/edit/${request.uuid}`])
    }

    public checkTrialPermissions(): void {
        this._userService.getTrialPermissions().subscribe(response => {
            const trials = response?._embedded?.trials || []
            this.canCreate = trials.length !== 0
        })
    }
}
