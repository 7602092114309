import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormlyModule } from '@ngx-formly/core'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker'
import { AutocompleteTypeComponent } from './types/autocomplete.component'
import { FormlyFieldFileComponent } from './types/file-type.component'
import { NewRepeatTypeComponent, ButtonTypeComponent } from '@plantandfood/kup.core'

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        FormlyModule.forRoot({
            validationMessages: [{ name: 'required', message: 'This field is required' }],
            types: [
                {
                    name: 'autocomplete',
                    component: AutocompleteTypeComponent,
                    wrappers: ['form-field'],
                },
                { name: 'file', component: FormlyFieldFileComponent, wrappers: ['form-field'] },
                { name: 'repeat', component: NewRepeatTypeComponent },
                {
                    name: 'button',
                    component: ButtonTypeComponent,
                    defaultOptions: {
                        templateOptions: {
                            type: 'button',
                        },
                    },
                },
            ],
        }),
        FormlyMaterialModule,
        CommonModule,
        FormlyMatDatepickerModule,
        FlexLayoutModule,
    ],
    exports: [ReactiveFormsModule, FormsModule, FormlyModule, FormlyMaterialModule, CommonModule, FormlyMatDatepickerModule, FlexLayoutModule],
    declarations: [],
})
export class AppFormlyModule {}
