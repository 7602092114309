/**
 * The key used to uniquely identify individual filter models within the set of saved filter models.
 */
export enum FilterModelType {
    Undefined = '',
    ObservationValues = 'ObservationValues',
    Trials = 'Trials',
    Canes = 'Canes',
    Scions = 'Scions',
    Plants = 'Plants',
    Fruits = 'Fruits',
    Genotypes = 'Genotypes',
    Users = 'Users',
    BiomaterialCollections = 'BiomaterialCollections',
    ObservationVariables = 'ObservationVariables',
    Batches = 'Batches',
    BatchBiomaterials = 'BatchBiomaterials',
    Samples = 'Samples',
    OperationsPlans = 'OperationsPlans',
    BiomaterialRequests = 'BiomaterialRequests',
    RemainingBiomaterials = 'RemaningBiomaterials',
    SuggestedBiomaterials = 'SuggestedBiomaterials',
    Biomaterials = 'Biomaterials',
}
