import { cloneDeep } from 'lodash'
import { ExceptionMessageFormatter } from './exception-message-formatter'

export class TypeHelper {
    private constructor() {}

    static safeConvertInteger(src: any, defaultValue: number = 0): number {
        let result: number
        if (typeof src == 'string') {
            result = Number.parseInt(src, 10)
        }
        return Number.isInteger(result) ? result : defaultValue
    }

    static isStringNullOrEmpty(s: string): boolean {
        return s == undefined || s === ''
    }

    static sanitizeString(value: string, defaultValue: string = ''): string {
        if (value == undefined) return defaultValue
        value = value.trim()
        return value.length > 0 ? value : defaultValue
    }

    static clone<T>(src: T): T {
        return cloneDeep(src)
    }

    static integerSafeSortCompare(x: string, y: string): number {
        let x1 = parseInt(x)
        let y1 = parseInt(y)
        return x1 != NaN && y1 != NaN ? x1 - y1 : x < y ? -1 : 1
    }

    /**
     * Creates a new `Date` instance represnting the current date with the time component set to zero.
     */
    static today(): Date {
        return TypeHelper.clearTime(new Date())
    }

    /**
     * Creates a new `Date` instance derived from a specified date, with the time component set to zero.
     */
    static dateOf(d: Date): Date {
        return TypeHelper.clearTime(new Date(d.getTime()))
    }

    /**
     * Sets the time component of a `Date` instance to zero.
     */
    static clearTime(d: Date): Date {
        d.setMilliseconds(0)
        d.setSeconds(0)
        d.setMinutes(0)
        d.setHours(0)
        return d
    }

    static toUTC(date: Date): Date {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCMilliseconds())
    }

    static toUTCString(date: Date, isLocalTime: boolean = true): string {
        if (isLocalTime) {
            date = TypeHelper.toUTC(date)
        }

        let r = `${date.getFullYear()}-${formatTimeUnit(date.getMonth() + 1)}-${formatTimeUnit(date.getDate())}T${formatTimeUnit(date.getHours())}:${formatTimeUnit(
            date.getMinutes()
        )}:${formatTimeUnit(date.getSeconds())}Z`
        return r
    }

    static localTimeFromUTCMilliseconds(ms: number): Date {
        if (ms == undefined) throw new Error(ExceptionMessageFormatter.argumentNull('ms'))
        let d = new Date(ms) // Construct a date
        return new Date(TypeHelper.toUTCString(d, false)) // Convert to local-time from UTC
    }
}

function formatTimeUnit(n: number): string {
    return n < 10 ? '0' + n.toString() : n.toString()
}
