import { DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { Logger } from './logger.service'
import * as DateFormats from '../../app/constants/date-formats.constants'

@Injectable()
export class FileHelperService {
    constructor(private logger: Logger, private datePipe: DatePipe) {}

    downloadCSVFromBlob(filename: string, blob: Blob): void {
        const nav = window.navigator as any
        if (this.isInternetExplorer() && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, this.formatFilename(filename))
        } else {
            const b = new Blob([blob], { type: 'text/csv' })
            const url = window.URL.createObjectURL(b)
            this.activateAnchor(url, this.formatFilename(filename))
        }
    }

    downloadJSONFromBlob(filename: string, blob: Blob): void {
        const nav = window.navigator as any
        if (this.isInternetExplorer() && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, this.formatFilename(filename, 'json'))
        } else {
            const url = window.URL.createObjectURL(blob)
            this.activateAnchor(url, this.formatFilename(filename, 'json'))
        }
    }

    downloadCSVFromUrl(filename: string, url: string): void {
        this.activateAnchor(url, filename)
    }

    private activateAnchor(url: string, fileName: string): void {
        const a = document.createElement('a') as HTMLAnchorElement
        a.style.display = 'none'
        a.href = url
        a.download = fileName
        // Firefox requires the anchor element to be added to the document.
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
            // Removing the anchor element from the document too early can result in the download not being saved by Firefox.
            document.body.removeChild(a)
            this.logger.success(`'${fileName}' successfully downloaded.`)
        }, 1000)
    }

    private formatFilename(filename: string, fileType?: string) {
        const downloadDate = this.datePipe.transform(new Date(), DateFormats.shortDate)
        return fileType ? `${filename}_${downloadDate}.${fileType}` : `${filename}_${downloadDate}.csv`
    }

    private isInternetExplorer(): boolean {
        const nav = window.navigator as any
        return nav != undefined && typeof nav.msSaveOrOpenBlob === 'function'
    }
}
