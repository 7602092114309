import { IServiceList } from '@plantandfood/kup.core'

export const serviceList: IServiceList = {
    CREATE_BATCH: {
        action: 'CREATE',
        endpoint: `biomaterialBatches/create`,
    },
    GET_BATCHES: {
        action: 'GETLIST',
        endpoint: `biomaterialBatches`,
    },
    GET_BATCH: {
        action: 'GET',
        endpoint: `biomaterialBatches`,
    },
    UPDATE_BATCH: {
        action: 'UPDATE',
        endpoint: `biomaterialBatches`,
    },
    GET_BATCH_BIOMATERIAL_TYPES: {
        action: 'GET',
        endpoint: `biomaterialBatches`,
    },
    GET_REQUESTS: {
        action: 'GETLIST',
        endpoint: `biomaterialRequests?projection=biomaterialRequestTable`,
    },
}
