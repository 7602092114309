import { TableDefinition } from '@plantandfood/kup.core'
import { Injectable } from '@angular/core'

@Injectable()
export class RequestsTableFactory {
    requests(): TableDefinition {
        return {
            sortActive: 'name',
            sortDirection: 'asc',
            columns: [
                {
                    columnDef: 'name',
                    sortKey: 'name',
                    header: 'Name',
                    formatter: 'truncate',
                    tooltipKey: 'name',
                    show: true,
                },
                {
                    columnDef: 'creationMethodName',
                    sortKey: 'creationMethodName',
                    header: 'Biomaterial Creation Method',
                    formatter: 'truncate',
                    tooltipKey: 'creationMethodName',
                    show: true,
                },
                {
                    columnDef: 'requester',
                    sortKey: 'requester',
                    header: 'Requester',
                    formatter: 'truncate',
                    show: true,
                    tooltipKey: 'requester',
                },
                {
                    columnDef: 'requestDate',
                    sortKey: 'requestDate',
                    header: 'Date Requested',
                    formatter: 'date',
                    show: true,
                    tooltipKey: 'requestDate',
                },
                {
                    columnDef: 'completionDate',
                    sortKey: 'completionDate',
                    header: 'Date Completed',
                    formatter: 'date',
                    show: true,
                    tooltipKey: 'completionDate',
                },
                {
                    columnDef: 'recipient',
                    sortKey: 'recipient',
                    header: 'Recipient',
                    formatter: 'truncate',
                    tooltipKey: 'recipient',
                    show: true,
                },
                {
                    columnDef: 'status',
                    sortKey: 'status',
                    header: 'Status',
                    formatter: 'truncate',
                    tooltipKey: 'status',
                    show: true,
                },
            ],
        }
    }
}
