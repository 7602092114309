import { TableDefinition } from '@plantandfood/kup.core'
import { Injectable } from '@angular/core'

@Injectable()
export class BatchesTableFactory {
    static batchBiomaterials(): TableDefinition {
        return {
            sortActive: 'biomaterialName',
            sortDirection: 'asc',
            columns: [
                {
                    columnDef: 'status',
                    sortKey: 'status',
                    header: 'Import Status',
                    formatter: 'icon',
                    tooltipKey: 'message',
                },
                {
                    columnDef: 'biomaterialName',
                    sortKey: 'biomaterialName',
                    header: 'Biomaterial Name',
                    formatter: 'truncate',
                    tooltipKey: 'biomaterialName',
                },
                {
                    columnDef: 'actualGenotypeName',
                    sortKey: 'actualGenotypeName',
                    header: 'Genotype Name',
                    formatter: 'truncate',
                    tooltipKey: 'actualGenotypeName',
                },
                {
                    columnDef: 'biomaterialActiveStatus',
                    sortKey: 'biomaterialActiveStatus',
                    header: 'Active Status',
                    formatter: 'truncate',
                    tooltipKey: 'biomaterialActiveStatus',
                },
                {
                    columnDef: 'biomaterialLocationName',
                    sortKey: 'biomaterialLocationName',
                    header: 'Location',
                    formatter: 'truncate',
                    tooltipKey: 'biomaterialLocationName',
                },
            ],
        }
    }

    static batchCombBiomaterials(): TableDefinition {
        return {
            columns: [
                {
                    columnDef: 'status',
                    sortKey: 'status',
                    header: 'Import Status',
                    formatter: 'icon',
                    tooltipKey: 'message',
                },
                {
                    columnDef: 'acceptorBiomaterialName',
                    sortKey: 'acceptorBiomaterialName',
                    header: 'Acceptor Biomaterial Name',
                    formatter: 'truncate',
                    tooltipKey: 'acceptorBiomaterialName',
                },
                {
                    columnDef: 'donorBiomaterialName',
                    sortKey: 'donorBiomaterialName',
                    header: 'Donor Biomaterial Name',
                    formatter: 'truncate',
                    tooltipKey: 'donorBiomaterialName',
                },
            ],
        }
    }

    combinationBiomaterialInBatch(): TableDefinition {
        return {
            sortActive: 'name',
            sortDirection: 'asc',
            columns: [
                {
                    columnDef: 'acceptorName',
                    sortKey: 'acceptorName',
                    header: 'Acceptor Biomaterial Name',
                    formatter: 'truncate',
                    tooltipKey: 'acceptorName',
                },
                {
                    columnDef: 'donorName',
                    sortKey: 'donorName',
                    header: 'Donor Biomaterial Name',
                    formatter: 'truncate',
                    tooltipKey: 'donorName',
                },
            ],
        }
    }

    biomaterialBatches(): TableDefinition {
        return {
            sortActive: 'createdDate',
            sortDirection: 'desc',
            columns: [
                {
                    columnDef: 'name',
                    sortKey: 'name',
                    header: 'Batch Name',
                    formatter: 'truncate',
                    tooltipKey: 'name',
                },
                {
                    columnDef: 'contact',
                    sortKey: 'contact',
                    header: 'Contact Name',
                    formatter: 'truncate',
                    tooltipKey: 'contact',
                },
                {
                    columnDef: 'description',
                    sortKey: 'description',
                    header: 'Description',
                    formatter: 'truncate',
                    tooltipKey: 'description',
                },
                {
                    columnDef: 'createdDate',
                    sortKey: 'createdDate',
                    header: 'Created Date',
                    formatter: 'date',
                    tooltipKey: 'createdDate',
                },
            ],
        }
    }

    batchRequests(): TableDefinition {
        return {
            sortActive: 'name',
            sortDirection: 'asc',
            columns: [
                {
                    columnDef: 'name',
                    sortKey: 'name',
                    header: 'Request Name',
                    formatter: 'truncate',
                    tooltipKey: 'name',
                },
                {
                    columnDef: 'requestDate',
                    sortKey: 'requestDate',
                    header: 'Date Requested',
                    formatter: 'date',
                    tooltipKey: 'requestDate',
                },
                {
                    columnDef: 'creationMethodName',
                    sortKey: 'creationMethodName',
                    header: 'Biomaterial Creation Method',
                    formatter: 'truncate',
                    tooltipKey: 'creationMethodName',
                },
                {
                    columnDef: 'recipient',
                    sortKey: 'recipient',
                    header: 'Recipient',
                    formatter: 'truncate',
                    tooltipKey: 'recipient',
                },
                {
                    columnDef: 'status',
                    sortKey: 'status',
                    header: 'Status',
                    formatter: 'truncate',
                    tooltipKey: 'status',
                },
            ],
        }
    }
}
