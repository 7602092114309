import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { isObservable, Observable } from 'rxjs'

import {
    ApiActionType,
    ApiOutcomeDescriptor,
    AsyncApiOutcomeDescriptor,
    LoadingState,
    LoadingStateMonitor,
    ApiHelper,
    ApiResponse,
    AsyncApiResponse,
    EntityRef,
    RestUriCollection,
    Uri,
    RestEntity,
    EntityTypeKey,
    LoadingStateErrorEventArgs,
    Disposables,
    AsyncHelper,
    AwaitAction,
    WaitHandleSource,
    BusyStateProvider,
    ErrorRedirect,
    UserNotifications,
} from '@plantandfood/kup.core'

/** Exposes helper functions. */
export interface ViewModelHelper {
    _userNotifications: UserNotifications
    /**
     * Resolves a URI from an `EntityRef`.
     */
    uriFromEntityRef<T extends RestEntity<RestUriCollection>>(src: EntityRef<T>): Uri
    /**
     * Resolves the self-referencing URI of a `RestEntity`.
     */
    selfUri(entity: RestEntity<RestUriCollection>): Uri
    /**
     * Causes the UI to notify the user of the outcome of an API operation.
     * @param response An `ApiResponse<T>` or `Observable<ApiResponse<T>>` representing the outcome of the operation.
     * @param actionType The `ApiActionType` that describes the nature of the operation.
     * @param entityType The `EntityTypeKey` that describes the type of entity targeted by the operation.
     * @param entityName (optional) The name of the entity targeted by the operation.
     */
    notifyApiResponse<T>(response: ApiResponse<T> | AsyncApiResponse<T>, actionType: ApiActionType, entityType: EntityTypeKey, entityName?: string): void
}

export class ViewModelHelper {
    static readonly singleton = new ViewModelHelper()

    uriFromEntityRef<T extends RestEntity<RestUriCollection>>(src: EntityRef<T>): Uri {
        return ApiHelper.uriFromEntityRef(src)
    }

    selfUri(entity: RestEntity<RestUriCollection>): Uri {
        return ApiHelper.selfUri(entity)
    }

    notifyApiResponse<T>(response: ApiResponse<T> | AsyncApiResponse<T>, actionType: ApiActionType, entityType: EntityTypeKey, entityName?: string): void {
        const descriptor = {
            response: response,
            actionType: actionType,
            entityType: entityType,
            entityName: entityName,
        }

        if (isObservable(response)) {
            this._userNotifications.handleAsyncApiResponse(<AsyncApiOutcomeDescriptor<T>>descriptor)
        } else {
            this._userNotifications.handleApiResponse(<ApiOutcomeDescriptor<T>>descriptor)
        }
    }
}

/**
 * A base class for view-model implementations, which supports the aggregation of child `ViewModel` instances to enable functional reuse.
 */
// TODO: Add Angular decorator.
@Component({
  template: ''
})
export abstract class ViewModel implements OnDestroy {
    private _parent: ViewModel
    private _loadingState: LoadingStateMonitor
    private _children: ViewModel[]
    private _disposables: Disposables
    private _errorRedirect: ErrorRedirect

    private get didInit(): boolean {
        return this._loadingState != undefined
    }

    /**
     * Exposes helper functions useful for interacting with the application framework.
     */
    get helper(): ViewModelHelper {
        return ViewModelHelper.singleton
    }

    /**
     * @deprecated Exists for legacy support.
     */
    get loadingState(): BusyStateProvider {
        return this._loadingState
    }

    /**
     * Returns a reference to this instance's `BusyStateProvider`.
     */
    get busyState(): BusyStateProvider {
        return this._loadingState
    }

    /**
     * Returns true if this instance's `BusyStateProvider` is in the `LoadingState.Loaded` state.
     */
    get isLoaded(): boolean {
        return this.busyState.loadingState === LoadingState.Loaded
    }

    /**
     * Returns true if this instance's `BusyStateProvider` is the busy state.
     */
    get isBusy(): boolean {
        return this.busyState.isBusy
    }

    /**
     * Returns true if this instance is the root `ViewModel` in the active aggregated set, otherwise returns false.
     *
     * NOTE: This property will always return `false` until after `init` has completed.
     */
    protected get isRoot(): boolean {
        return this._parent != undefined && this.didInit
    }

    /**
     * The `Disposables` object available to this instance.
     */
    protected get disposables(): Disposables {
        if (this._disposables == undefined) this._disposables = new Disposables()
        return this._disposables
    }

    /**
     * Should be called on the root `ViewModel` from within the host component's `ngOnInit` method.
     */
    init(): void {
        if (this.didInit) {
            throw new Error('The init method has already been called.')
        }
        if (this._parent && this._parent._loadingState == undefined) {
            throw new Error('The init method must be called on the root ViewModel.')
        }
        this._loadingState = new LoadingStateMonitor()
        // Note: We are using this ugly hack here because
        // the router is actually injected using DI in the extended
        // view model.
        if (this['router'] && this['router'] instanceof Router) {
            this._errorRedirect = new ErrorRedirect(this['router'] as Router)
        }
        this.disposables.subscribeTo(this._loadingState.onError, (args: LoadingStateErrorEventArgs) => {
            if (this._loadingState.loadingState === LoadingState.Failed && this._errorRedirect) {
                this._errorRedirect.gotoErrorPageAuto(args.error)
            }
        })

        this.disposables.addSubscription(
            this._loadingState.loadingState$.subscribe(state => {
                if (state === LoadingState.Loaded) {
                    this.onLoaded()
                }
            })
        )

        this._loadingState.enterPrepare()
        try {
            this.initRecursive()
        } finally {
            this._loadingState.exitPrepare()
        }
    }

    /**
     * Invoked once when this instance's `BusyStateProvider` enters the `LoadingState.Loaded` state.
     * When overidden in a derived class, enables convenient post-load initialisation.
     */
    protected onLoaded(): void {}

    /**
     * Releases any resources associated with this instance, including subscriptions to any active actions.
     * Should be called from within the host component's `ngOnDestroy` method unless this instance has itself been
     * injected as an Angular service.
     */
    destroy(): void {
        this.destroyRecursive()
    }

    /**
     * Invokes this instance's `destroy` method. For use when the instance has been constructed by the Angular Dependency Injection framework.
     */
    ngOnDestroy(): void {
        this.destroy()
    }

    /**
     * When implemented in a derived class, initalises the `ViewModel` instance.
     */
    protected abstract viewModelInit(): void

    /**
     * Invoked to aggregate another `ViewModel` within this instance.
     * @param viewModel The instance to aggregate.
     */
    protected aggregate(viewModel: ViewModel): void {
        if (viewModel == undefined) {
            throw new Error('The argument "viewModel" cannot be null.')
        }
        if (this.didInit) {
            throw new Error('Cannot aggregate after init has been called.')
        }
        if (viewModel._parent != undefined) {
            throw new Error('The specified ViewModel has already been aggregated.')
        }
        viewModel._parent = this
        if (this._children == undefined) this._children = []
        this._children.push(viewModel)
    }

    registerStore(...waitHandle: WaitHandleSource[]): Observable<void> {
        return AsyncHelper.awaitSequence(AsyncHelper.waitHandleInitiator(...waitHandle))
    }

    /**
     * Registers a sequence of actions as loading actions with this instance's `BusyStateProvider`,
     * which during initialisation will remain in a `Loading` state until all such registered actions have completed
     * or one or more has errored.
     *
     * Each `AwaitAction` in the sequence must complete before the subsequent `AwaitAction` will initiate.
     * Be aware that an `AwaitAction` may itself represent a collection of actions, which may themselves execute concurrently
     * (i.e. not in sequence).
     *
     * This method will throw if it is invoked outside of the root `ViewModel` instance's initialisation sequence.
     *
     * Returns an `Observable` which will complete only when `actions` have completed.
     * @param actions The actions to register.
     */
    protected registerLoadingSequence(...actions: AwaitAction[]): Observable<void> {
        const obs = AsyncHelper.awaitSequence(...actions)
        this.registerLoading(obs)
        return obs
    }

    /**
     * Registers one or more `Observable` instances as loading actions with this instance's `BusyStateProvider`,
     * which during initialisation will remain in a `Loading` state until all such registered actions have completed or
     * one or more has errored.
     *
     * This method will throw if it is invoked outside of the root `ViewModel` instance's initialisation sequence.
     * @param actions The actions to register.
     */
    protected registerLoading(...actions: Observable<any>[]): void {
        this._loadingState.register(...actions)
    }

    /**
     * Registers an `Observable` instance with this instance's `BusyStateProvider`, which will report a busy state
     * until all such registered actions have completed or errored.
     *
     * Workers can be registered at any time during this instance's lifecycle.
     * @param action The action to register.
     */
    protected registerWorker(action: Observable<any>): void {
        this._loadingState.registerWorker(action)
    }

    private initRecursive(): void {
        this.viewModelInit()
        if (this._children != undefined) {
            this._children.forEach(child => {
                child._loadingState = this._loadingState
                child.initRecursive()
            })
        }
    }

    private destroyRecursive(): void {
        if (this.isRoot) {
            this._loadingState.dispose()
        }
        if (this._disposables != undefined) {
            this._disposables.dispose()
        }
        if (this._children != undefined) {
            this._children.forEach(child => {
                child.destroyRecursive()
            })
        }
    }

    private onLoadedRescursive(): void {
        this.onLoaded()
        if (this._children != undefined) {
            this._children.forEach(child => {
                child.onLoadedRescursive()
            })
        }
    }
}
