import { Router } from '@angular/router'
import { NavFacadeBase } from './nav-facade-base'

export class BiomaterialBatchesNavFacade extends NavFacadeBase {
    constructor(router: Router) {
        super(router)
    }
    editBiomaterialBatch(biomaterialBatchId: string): Promise<boolean> {
        return this.router.navigate([`/biomaterial-batches/edit/${biomaterialBatchId}`])
    }

    listBiomaterialBatches(): Promise<boolean> {
        return this.router.navigate([`/biomaterial-batches`])
    }

    listBatchBiomaterials(biomaterialBatchId: string): Promise<boolean> {
        return this.router.navigate([`/biomaterial-batches/${biomaterialBatchId}/batch-biomaterials`])
    }
}
