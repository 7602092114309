import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { MapiServiceBase, TypedRestResponse } from '@plantandfood/kup.core'
import { Observable } from 'rxjs'

import { Batch } from '../../../biomaterial-batches/models/batch.model'
import { managementApi } from '../../constants/path.constants'
import { ApiResponse } from '../api-response'

const biomaterialBatchesEndpoint = 'biomaterialBatches?projection=biomaterialBatchManagementTable'

@Injectable({
    providedIn: 'root',
})
export class BiomaterialBatchesService extends MapiServiceBase {
    constructor(http: HttpClient) {
        super(http, managementApi)
    }

    getBiomaterialBatches(params?: Params): Observable<TypedRestResponse<Batch>> {
        return this.getAndMap(
            biomaterialBatchesEndpoint,
            result => {
                result._embedded = result._embedded.biomaterialBatches
                return result
            },
            params
        )
    }

    verfiyImport(importModel, mode): Observable<any> {
        const formData: FormData = this.constructFormData(importModel, mode)
        return this.verifyImportFile(formData)
    }

    createImport(importModel, mode): Observable<any> {
        const formData: FormData = this.constructFormData(importModel, mode)
        return this.createImportFile(formData)
    }

    createEmpty(formData: FormData): any {
        return this.post<FormData>(`biomaterialBatches`, formData)
    }

    getBatchExport(batchId: string, type: String): Observable<Blob> {
        return this.getCsvResponse(`biomaterialBatches/${batchId}/biomaterials`, { type: type })
    }

    private constructFormData(importModel, mode): FormData {
        const desc = importModel.description ? importModel.description : ''
        const formData: FormData = new FormData()
        if (mode === 'withBiomaterial' || mode === 'combBiomaterial') {
            const fileList: FileList = importModel.file ? importModel.file : ''
            const file: File = fileList[0]
            formData.append('file', file)
        }
        if (mode === 'combBiomaterial') {
            formData.append('type', 'COMBINATION')
        }
        formData.append('name', importModel.name)
        formData.append('description', desc)
        formData.append('contact', importModel.contact)
        return formData
    }

    private verifyImportFile(formData: FormData): any {
        return this.post<FormData>(`biomaterialBatches/import/validate`, formData)
    }

    private createImportFile(formData: FormData): any {
        return this.post<FormData>(`biomaterialBatches/import`, formData)
    }

    getBiomaterialBatch(biomaterialBatchId: string, params?: Params): Observable<ApiResponse<Batch>> {
        return this.getAsApiResponse(`biomaterialBatches/${biomaterialBatchId}?projection=biomaterialBatchManagementTable`, params)
    }

    getCombBiomaterialBatch(biomaterialBatchId: string, params?: Params): Observable<ApiResponse<Batch>> {
        return this.getAsApiResponse(`biomaterialBatches/${biomaterialBatchId}/batchBiomaterialCombinations`, params)
    }

    updateBiomaterialBatch(biomaterialBatch: Batch): Observable<ApiResponse<Batch>> {
        return this.putAsApiResponse<Batch>(`biomaterialBatches/${biomaterialBatch.uuid}`, biomaterialBatch)
    }
}
