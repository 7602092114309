import { Injectable } from '@angular/core'

@Injectable()
export class QueryActionHelper {
    facade: any

    constructor() {}

    init(facadeFunctions: any) {
        this.facade = facadeFunctions
    }

    saveFilter(modelType, modelParams, activeSelectors) {
        return this.facade.users.saveFilter(modelType, modelParams, activeSelectors)
    }

    getSavedFilter(modelType) {
        if (!this.facade) return undefined
        return this.facade.users.getSavedFilter(modelType)
    }
}
