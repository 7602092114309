import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ConfigProvider } from '@plantandfood/kup.core'
import { QueryActionHelper, QueryErrorPageHelper, QueryRouteHelper } from '../filter-query-services/filters'
import { SharedModule } from './../app/shared/shared.module'

import { RequestsComponent } from './containers/requests/requests.component'
import { RequestsRoutingModule } from './requests-routing.module'
import { RequestsConfigService } from './services/requests-config.service'
import { RequestItemComponent } from './containers/request-item/request-item.component'
import { RequestStatusComponent } from './components/request-status/request-status.component'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ChangeStatusModalComponent } from './components/change-status/change-status-modal.component'

@NgModule({
    declarations: [RequestsComponent, RequestItemComponent, RequestStatusComponent, ChangeStatusModalComponent],
    imports: [CommonModule, RequestsRoutingModule, SharedModule],
    providers: [
        QueryRouteHelper,
        QueryErrorPageHelper,
        QueryActionHelper,
        {
            provide: ConfigProvider,
            useClass: ConfigProvider,
        },
        RequestsConfigService,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
    entryComponents: [ChangeStatusModalComponent],
})
export class RequestsModule {}
