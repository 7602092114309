import { KupIconConfig } from '@plantandfood/kup.core/lib/constants/icons.constants'

export type SvgIcon =
    | 'biomaterial'
    | 'biomaterial_white'
    | 'cancel_white'
    | 'cancel_black'
    | 'complete_with_errors'
    | 'complete'
    | 'delete'
    | 'fatal_error'
    | 'file'
    | 'file_export'
    | 'file_import'
    | 'genotypes'
    | 'home'
    | 'logout'
    | 'save_white'
    | 'save_black'
    | 'user_preferences'
export const navKupIcons: KupIconConfig[] = [
    { name: 'list', file: 'list.svg' },
    { name: 'menu', file: 'menu.svg' },
    { name: 'more_vert', file: 'more_vert.svg' },
    { name: 'receipt', file: 'receipt.svg' },
    { name: 'science', file: 'science.svg' },
    { name: 'account_circle', file: 'account_circle.svg' },
    { name: 'exit_to_app', file: 'exit_to_app.svg' },
    { name: 'keyboard_arrow_right', file: 'keyboard_arrow_right.svg' },
    { name: 'keyboard_arrow_down', file: 'keyboard_arrow_down.svg' },
    { name: 'info', file: 'info_black.svg' },
]
