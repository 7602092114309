import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { environment } from '../../environments/environment'

enum SnackbarStatusClasses {
    default = 'kup-snackbar',
    success = 'kup-snackbar-success',
    warn = 'kup-snackbar-warn',
    error = 'kup-snackbar-error',
}

const defaults: MatSnackBarConfig = {
    duration: 8000,
    panelClass: [SnackbarStatusClasses.default],
}

export interface AppLogger {
    log(value: any, ...rest: any[]): void
    success(value: any): void
    error(value: any, ...rest: any[]): void
    warn(value: any, ...rest: any[]): void
}

@Injectable()
export class Logger implements AppLogger {
    constructor(private snackBar: MatSnackBar) {}

    log(value: any, ...rest: any[]) {
        if (!environment.production) {
            console.log(value, ...rest)
        }
    }

    success(value: any) {
        const options = {
            ...defaults,
            panelClass: [SnackbarStatusClasses.success],
        }
        this.snackBar.open(value, 'Success', options)
    }

    error(value: any, ...rest: any[]) {
        console.log(value, ...rest)
        const options = { ...defaults, panelClass: [SnackbarStatusClasses.error] }
        this.snackBar.open(value || 'An error occurred', 'See the console for more details', options)
    }

    warn(value: any, ...rest: any[]) {
        console.warn(value, ...rest)
        const options = { ...defaults, panelClass: [SnackbarStatusClasses.warn] }
        this.snackBar.open(value, 'Try Again', options)
    }

    notification(value: string) {
        const options = {
            ...defaults,
            panelClass: [SnackbarStatusClasses.success],
        }
        this.snackBar.open(value, 'Close', options)
    }
}
