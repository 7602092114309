import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { ApiService, BatchIdDropdownProvider, DropdownDatasource, DropdownProvider, EntityNameIdDropdownProvider, UsernameDropdownProviderNew } from '@plantandfood/kup.core'
import { UserGroupsService } from 'app/api/services/user-groups.service'
import { BatchIdFilteredDropdownProvider } from 'app/dropdown-provider/batch-id-filtered-dropdown-provider'
import { TrialIdDropdownProviderNew } from 'app/dropdown-provider/trial-id-dropdown-provider'
import { BehaviorSubject, Subscription } from 'rxjs'
import { first, tap } from 'rxjs/operators'
import { RequestStatus } from '../../app/constants/request-type.constants'
import { serviceList } from '../services/service-list'

@Injectable()
export class RequestsFormFactory {
    fields: FormlyFieldConfig[]
    form = new FormGroup({})
    users
    private recipientSubject = new BehaviorSubject([])
    private subscription: Subscription

    private sourceTypes: string = ''
    private toType: string = ''
    private creationMethodId: string = ''
    private sourceBatchError: boolean = true
    private purposeOriginalText: string = ''

    get isSourceBatchError() {
        return this.sourceBatchError
    }

    constructor(private service: ApiService, private userGroupService: UserGroupsService) {
        this.users = new UsernameDropdownProviderNew(this.service)
        if (this.form.status === 'VALID') {
            this.sourceBatchError = false
        }
    }

    requestsFilterForm() {
        const status = [
            { value: RequestStatus.OPEN, label: 'Open' },
            { value: RequestStatus.UNDER_REVIEW, label: 'Under Review' },
            { value: RequestStatus.AWAITING_FEEDBACK, label: 'Awaiting Feedback' },
            { value: RequestStatus.CREATING_BIOMATERIALS, label: 'Creating Biomaterials' },
            { value: RequestStatus.IN_PROGRESS, label: 'In Progress' },
            { value: RequestStatus.COMPLETED, label: 'Completed' },
        ]

        const userList = DropdownProvider.resolve(this.users, {
            suppressRefresh: false,
        }).data$

        const obs = this.userGroupService.getUserGroups()
        this.subscription = obs.subscribe(res => {
            if (res.isOk) {
                const recipient = DropdownDatasource.fieldMap(res.result, 'name')
                this.recipientSubject.next(recipient)
                if (this.subscription) this.subscription.unsubscribe()
            }
        })

        const biomaterialCreationMethods = new EntityNameIdDropdownProvider(this.service, 'unique/biomaterialCreationMethods')
        const creationMethod = DropdownProvider.resolve(biomaterialCreationMethods, {
            suppressRefresh: false,
        })

        return [
            {
                key: 'biomaterialRequests.name',
                id: 'field_name',
                type: 'input',
                templateOptions: {
                    label: 'Filter by Name',
                },
            },
            {
                key: 'status',
                id: 'field_status',
                type: 'ng-select',
                templateOptions: {
                    multiple: true,
                    label: 'Filter By Status',
                    options: status,
                },
            },
            {
                key: 'requester',
                id: 'field_filter_requester',
                type: 'ng-select',
                templateOptions: {
                    multiple: true,
                    label: 'Filter By Requester',
                    options: userList,
                },
            },
            {
                key: 'recipient',
                id: 'field_filter_recipient',
                type: 'ng-select',
                templateOptions: {
                    multiple: true,
                    label: 'Filter By Recipient',
                    options: this.recipientSubject.asObservable(),
                },
            },
            {
                key: 'creationMethod.id',
                id: 'field_filter_creation_method',
                type: 'ng-select',
                templateOptions: {
                    multiple: true,
                    label: 'Filter By Biomaterial Creation Method',
                    options: creationMethod.data$,
                    provider: creationMethod,
                    isTypeahead: true,
                },
            },
        ]
    }

    requestEditForm(externalReferences, isCreateMode: boolean, canEdit: boolean, trialOperationsGroups, isRecipient: boolean) {
        const biomaterialCreationMethods = new EntityNameIdDropdownProvider(this.service, 'unique/biomaterialCreationMethods')
        const trials = new TrialIdDropdownProviderNew(this.service, 'EDIT')
        const sourceBatches = new EntityNameIdDropdownProvider(this.service, 'unique/biomaterialBatches')
        this.recipientSubject.next(trialOperationsGroups)

        const trial = DropdownProvider.resolve(trials, { suppressRefresh: false })
        const sourceBatch = DropdownProvider.resolve(sourceBatches, { suppressRefresh: false })

        const userData = DropdownProvider.resolve(this.users, {
            suppressRefresh: false,
        })
        const creationMethod = DropdownProvider.resolve(biomaterialCreationMethods, {
            suppressRefresh: false,
        })

        this.fields = [
            {
                key: 'name',
                id: 'field_request_name',
                type: 'input',
                templateOptions: {
                    label: 'Name',
                    disabled: true,
                },
            },
            {
                key: 'creationMethodId',
                id: 'field_creation_method',
                type: 'ng-select',
                templateOptions: {
                    label: 'Creation Method',
                    required: true,
                    options: creationMethod.data$,
                    provider: creationMethod,
                    isTypeahead: true,
                    onChange: item => {
                        if (item) {
                            this.form.patchValue({
                                sourceBatchId: undefined,
                            })
                            const obs = this.service
                                .apiService(serviceList.GET_CREATION_METHOD, null, null, `?projection=biomaterialCreationMethodTable&name=${item.label}`)
                                .pipe(
                                    first(),
                                    tap(res => {
                                        if (res.isOk) {
                                            const sourceType = res.result._embedded.biomaterialCreationMethods[0].relationshipTypes.length === 1 ? 'SINGLE' : 'COMBINATION'
                                            const sourceBatches = new BatchIdFilteredDropdownProvider(this.service, sourceType)
                                            const sourceBatch = DropdownProvider.resolve(sourceBatches, { suppressRefresh: false })

                                            const obj: FormlyFieldConfig = this.fields.find(o => o.key === 'sourceBatchId')
                                            obj.templateOptions.provider = sourceBatch
                                            obj.templateOptions.options = sourceBatch.data$

                                            const sourceTypes = res.result._embedded.biomaterialCreationMethods[0].sourceTypes
                                            const toType = res.result._embedded.biomaterialCreationMethods[0].toType

                                            this.creationMethodId = item.value
                                            this.setValueSourceTypes(sourceTypes)
                                            this.setValueToType(toType)
                                            this.sourceBatchErrorMessage(false)
                                        }
                                    })
                                )
                                .subscribe()
                        }
                    },
                },
            },
            {
                key: 'sourceTypesDummy',
                id: 'field_sourceTypes',
                type: 'input',
                defaultValue: this.sourceTypes,
                templateOptions: {
                    label: 'From Type',
                    disabled: true,
                    required: false,
                },
            },
            {
                key: 'toTypeDummy',
                id: 'field_toType',
                type: 'input',
                defaultValue: this.toType,
                templateOptions: {
                    label: 'To Type',
                    disabled: true,
                    required: false,
                },
            },
            {
                key: 'trialId',
                id: 'field_trial_id',
                type: 'ng-select',
                templateOptions: {
                    label: 'Trial',
                    options: trial.data$,
                    provider: trial,
                    isTypeahead: true,
                    required: true,
                    onChange: item => {
                        if (item) {
                            const obs = this.service.apiService(serviceList.GET_TRIAL_OPERATIONS, null, null, `/${item.value}/trialOperationsGroups`)
                            obs.pipe(first()).subscribe(res => {
                                if (res.isOk) {
                                    const trialOperationsGroups = res.result._embedded.trialOperationsGroups
                                    const recipient = DropdownDatasource.fieldMap(trialOperationsGroups, 'operationsGroupName')

                                    this.recipientSubject.next(recipient)
                                    this.form.patchValue({
                                        recipient: undefined,
                                    })
                                }
                            })
                        }
                    },
                },
                modelOptions: {
                    debounce: {
                        default: 500,
                    },
                },
            },
            {
                key: 'purpose',
                id: 'field_request_purpose',
                type: 'textarea',
                templateOptions: {
                    label: 'Purpose',
                    required: true,
                    rows: 3,
                    maxLength: 255,
                    keyup: () => {
                        const isOnlyOneModified = this.isOnlyOneModified('purpose')
                        if (isOnlyOneModified) {
                            this.form.markAsUntouched()
                        }

                        if (this.purposeOriginalText !== this.form.controls['purpose'].value) {
                            this.form.markAsTouched()
                        }
                    },
                    blur: () => {
                        const isOnlyOneModified = this.isOnlyOneModified('purpose')
                        if (this.purposeOriginalText === this.form.controls['purpose'].value && isOnlyOneModified) {
                            this.form.markAsUntouched()
                        } else {
                            this.form.markAsTouched()
                        }
                    },
                },
                hooks: {
                    onInit: () => {
                        this.purposeOriginalText = this.form.controls['purpose'].value
                    },
                },
            },
            {
                key: 'requester',
                id: 'field_requester',
                type: 'ng-select',
                templateOptions: {
                    label: 'Requester',
                    required: true,
                    options: userData.data$,
                    provider: userData,
                    isTypeahead: true,
                },
            },
            {
                key: 'recipient',
                id: 'field_recipient',
                type: 'ng-select',
                templateOptions: {
                    label: 'Recipient (Operations Group)',
                    required: true,
                    options: this.recipientSubject.asObservable(),
                    description: 'Only Trials with associated Operations Group will have Recipient',
                },
                expressionProperties: {
                    'templateOptions.disabled': '!model.trialId' && `${!canEdit}`,
                },
                hooks: {
                    onInit: field => {
                        if (!isRecipient) {
                            field.formControl.reset()
                            field.formControl.markAsTouched()
                        }
                    },
                },
            },
            {
                key: 'requestDate',
                id: 'field_request_date',
                type: 'datepicker',
                className: 'standard-datepicker',
                templateOptions: {
                    label: 'Request Date',
                    required: true,
                    disabled: isCreateMode,
                },
            },
            {
                key: 'sourceBatchId',
                id: 'field_source_batch_id',
                type: 'ng-select',
                templateOptions: {
                    label: 'Source Batch',
                    required: true,
                    provider: sourceBatch,
                    options: sourceBatch.data$,
                    isTypeahead: true,
                    description: 'You must select a creation method before selecting a source batch.',
                    onChange: item => {
                        if (item && this.creationMethodId != '') {
                            const obs = this.service.apiService(
                                serviceList.GET_REQUEST,
                                null,
                                null,
                                `checkBatchAndCreationMethod?creationMethodId=${this.creationMethodId}&batchId=${item.value}`
                            )
                            obs.pipe(first()).subscribe(res => {
                                const error = !res.isOk
                                this.sourceBatchErrorMessage(error)
                                this.sourceBatchError = error
                            })
                        }
                    },
                },
                expressionProperties: {
                    'templateOptions.disabled': '!model.creationMethodId' && `${!canEdit}`,
                },
            },
            {
                key: 'sourceBatchMessageDummy',
                id: 'field_batch_message',
                type: 'input',
                template: '<div class="source-batch-error-message-content"></div>',
                templateOptions: {
                    label: '',
                    disabled: true,
                },
            },
            {
                key: 'completionDate',
                id: 'field_request_completion_date',
                type: 'input',
                templateOptions: {
                    label: 'Completion Date',
                    disabled: true,
                },
            },
            {
                key: 'externalReferences',
                id: 'field_extRef',
                type: 'repeat',
                templateOptions: {
                    addText: 'Add',
                    buttonsDisabled: `${!canEdit}`,
                },
                fieldArray: {
                    fieldGroup: [
                        {
                            key: 'namespaceName',
                            id: 'field_namespace_name',
                            type: 'ng-select',
                            className: 'inline-external-reference',
                            templateOptions: {
                                label: 'External Reference Type',
                                options: externalReferences,
                            },
                            expressionProperties: {
                                'templateOptions.disabled': `${!canEdit}`,
                            },
                        },
                        {
                            key: 'name',
                            id: 'field_extRef_name',
                            type: 'input',
                            className: 'inline-external-reference align-with-type',
                            templateOptions: {
                                label: 'External Reference Value',
                            },
                            expressionProperties: {
                                'templateOptions.disabled': '!model.namespaceName' && `${!canEdit}`,
                            },
                        },
                    ],
                },
            },
        ]
    }

    isOnlyOneModified(field: string) {
        let touched = true
        for (let key in this.form.controls) {
            if (this.form.controls[key].touched && key !== field) {
                touched = false
            }
        }

        return touched
    }

    defaultValueSourceTypes(sourceTypes: Array<string>) {
        this.sourceTypes = this.formatSourceTypes(sourceTypes)
    }

    defaultValueToType(text: string) {
        this.toType = text
    }

    setValueSourceTypes(sourceTypes: Array<string>) {
        this.form.controls['sourceTypesDummy'].setValue(this.formatSourceTypes(sourceTypes))
    }

    setValueToType(text: string) {
        this.form.controls['toTypeDummy'].setValue(text)
    }

    formatSourceTypes(sources: Array<string>) {
        let sourceTypes: string = ''
        sources.map((text: string) => {
            sourceTypes += sourceTypes == '' ? text : `, ${text}`
        })

        return sourceTypes
    }

    sourceBatchErrorMessage(show: boolean) {
        const errorMessageContent = document.querySelector('.source-batch-error-message-content')
        errorMessageContent.innerHTML = ''

        if (show) {
            const errorMessage = document.querySelector('.source-batch-error-message')
            errorMessageContent.innerHTML = errorMessage.innerHTML
        }
    }

    updateCreationMethodId(id: string) {
        this.creationMethodId = id
    }

    batchPrintForm() {
        const batches = new BatchIdDropdownProvider(this.service)

        return (this.fields = [
            {
                key: 'batchId',
                id: 'field_source_batch_id',
                type: 'ng-select',
                templateOptions: {
                    label: 'Biomaterial Batch',
                    required: true,
                    disabled: true,
                    options: DropdownProvider.resolve(batches, { suppressRefresh: false }).data$,
                },
            },
        ])
    }
}
