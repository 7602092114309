<div class="error-container">
    <div>
        <h1 class="error-title">{{ title }}</h1>
        <h2>{{ message }}</h2>
        <button type="submit" color="primary" mat-raised-button routerLink="/" id="error-home-button">Home</button>
    </div>

    <div *ngIf="appCode || appMessage" class="app-message-section">
        <div *ngIf="appCode">
            <strong>{{ appCode }}</strong>
        </div>
        <div *ngIf="appMessage" class="message">{{ appMessage }}</div>
    </div>
</div>
