import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { BiomaterialBatchesService } from '../../app/api/services/biomaterial-batches.service'

@Injectable()
export class BiomaterialBatchesResolver implements Resolve<any> {
    constructor(private service: BiomaterialBatchesService) {}
    resolve(): Observable<any> | Promise<any> {
        return this.service.getBiomaterialBatches()
    }
}
