export enum ImagePath {
    TISSUE_SAMPLE = 'assets/tissue-sample.png',
    DNA_EXTRACTION = 'assets/DNA_extraction.jpg',
    GENOTYPING = 'assets/genotyping.png',
}

export enum RequestType {
    BIOMATERIAL_CREATION_REQUEST = 'BIOMATERIAL_CREATION_REQUEST',
}

export enum RequestStatus {
    OPEN = 'OPEN',
    UNDER_REVIEW = 'UNDER_REVIEW',
    AWAITING_FEEDBACK = 'AWAITING_FEEDBACK',
    CREATING_BIOMATERIALS = 'CREATING_BIOMATERIALS',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum RequestAction {
    SEND_FOR_REVIEW = 'SEND_FOR_REVIEW',
    REQUEST_FEEDBACK = 'REQUEST_FEEDBACK',
    START = 'START_CREATION',
    COMPLETE = 'COMPLETE_REQUEST',
}

export enum RequestMessage {
    SEND_FOR_REVIEW = "Press 'ok' to send to recipient to review your request. They will accept your request or request feedback from you. Please enter any comments below.",
    REQUEST_FEEDBACK = "Press 'ok' to request feedback from requester for additional information. Please enter any comments below.",
    START = "Press 'ok' to generate the new Biomaterial entries for this request. Please enter any comments below.",
    COMPLETE = "Press 'ok' to complete request indicating the Biomaterial entries have been generated/created and labelled correctly. Please enter any comments below.",
}

export enum BiomaterialsView {
    BATCH = 'Batch',
    COLLECTION = 'Collection',
    REMOVE = 'remove',
    UUID = 'uuid',
    ENTITY = 'entity',
}

export const biomaterialTypes = ['CANE', 'SCION', 'PLANT', 'FRUIT', 'SAMPLE']
