import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router'
import { NgxPermissionsGuard } from 'ngx-permissions'
import { Observable } from 'rxjs'
import { AuthService } from '@plantandfood/kup.auth'

/**
 * `AuthGuard` acts as a proxy for `NgxPermissionsGuard`, and should be used in its place throughout the application.
 * This is necessary because `NgxPermissionsGuard` stopped working when applied to routes on lazy-loaded modules.
 * This occurs because application roles are loaded into the `NgxPermissionsService` instance provided on the root-level injector,
 * and lazy-loaded modules use a deeper child injector with it's own `NgxPermissionsService` instance.
 * (This was not always the case, and only began to occur after changes to the kup.common.ui package which appear
 * to be the cause of the issue; the reason for the behaviour remains unknown)
 *
 * Note that global authorisation (including application role check) is performed by `AuthDirector`.

 */
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    private _guard: NgxPermissionsGuard

    constructor(auth: AuthService, router: Router) {
        this._guard = auth.createPermissionsGuard(router)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this._guard.canActivate(route, state)
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this._guard.canActivateChild(childRoute, state)
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        return this._guard.canLoad(route)
    }
}
