import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '../app/shared/shared.module'
import { DashboardViewComponent } from './components/dashboard-view/dashboard-view.component'
import { DashboardComponent } from './containers/dashboard/dashboard.component'
import { DashboardRoutingModule } from './dashboard-routing.module'

@NgModule({
    declarations: [DashboardViewComponent, DashboardComponent],
    imports: [CommonModule, SharedModule, DashboardRoutingModule],
})
export class DashboardModule {}
