import { Injectable } from '@angular/core'
import { KeycloakService } from '@plantandfood/kup.auth'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { UserData, UserDataEntity, ExceptionMessageFormatter, TypeHelper, Disposables, UserProfile, ApiResponse, ApiResponseCode, ApiService } from '@plantandfood/kup.core'

/** @todo */
import { FacadeBase } from './../../facade/facade-base'

@Injectable()
export class UsersMigrationHelper extends FacadeBase {
    private _keycloakProfile: Keycloak.KeycloakProfile
    private _userProfile: UserProfile = emptyProfile()
    private _userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject(emptyProfile())
    readonly userProfile$: Observable<UserProfile>

    constructor(private keycloak: KeycloakService, private apiService: ApiService) {
        super()
        this.userProfile$ = this._userProfile$.asObservable()
    }

    protected init(): Observable<any> {
        return this.initProfileData()
    }

    get currentProfile(): UserProfile {
        return this._userProfile
    }

    // formatDisplayName(user: User): string {
    //   return this.messageFormatter.userDisplayName(user);
    // }

    // setPIN(username?: string, displayName?: string, userId?: string) {
    //   if (!this.auth.enforceApplicationAccess()) return;
    //   const dialogRef = this.dialog.setPinPrompt(displayName);
    //   dialogRef.then(response => {
    //     if (response.value == undefined) return;
    //     Disposables.global.subscribeReplayOnce(
    //       this.currentProfile.userName === username
    //         ? this.api.setOwnPIN(response.value)
    //         : this.api.setPIN(username, response.value, userId),
    //       response => {
    //         if (response.isOk) {
    //           this.dialog.pinSuccess(displayName);
    //         } else {
    //           this.dialog.errorAlert(`PIN change failed, please try again`);
    //         }
    //       }
    //     );
    //   });
    // }

    // getUser(userId: string): Observable<ApiResponse<User>> {
    //   return this.api.getUser(userId);
    // }

    // deprecatedGetUsers(): Observable<ApiResponse<User[]>> {
    //   return this.api.deprecatedGetUsers();
    // }

    // getUsers(params: Params): Observable<TypedRestResponse<User>> {
    //   return this.api.getUsers(params);
    // }

    // filterUsersByName(name: string, maxLength: number = 50): Observable<User[]> {
    //   name = name != undefined ? name.toLowerCase() : '';
    //   const params = {
    //     match: name,
    //     page: 0,
    //     size: maxLength
    //   };

    //   return this.api.getUsers(params).pipe(
    //     map(result => {
    //       return result._embedded;
    //     })
    //   );
    // }

    loadUserData(): Observable<ApiResponse<UserData>> {
        return this.loadUserDataByUsername(this._userProfile.userName).pipe(
            tap(response => {
                if (response.isOk) {
                    this.assignUserProfile(response.result)
                }
            })
        )
    }

    saveUserData(userData: UserData): Observable<ApiResponse<UserData>> {
        deleteRedundantUserData(userData)
        const entity = toUserDataEntity(userData)
        const result =
            userData.uuid != undefined
                ? // ? this.api.updateUserData(entity)
                  this.apiService.apiService({ action: 'UPDATEQUERY', endpoint: 'userDatas' }, entity)
                : // : this.api.createUserData(entity);
                  this.apiService.apiService({ action: 'CREATE', endpoint: 'userDatas' }, entity)

        Disposables.global.subscribeSubjectOnce(result, r => {
            if (r.isOk) {
                this.assignUserProfile(fromUserDataEntity(r.result))
            }
        })

        return Disposables.global.observeReplayOnce(result).pipe(
            map(r => {
                return r.isOk ? ApiResponse.ok(fromUserDataEntity(r.result)) : <ApiResponse<UserData>>r
            })
        )
    }

    saveQueryPageSize(pageSize: number): Observable<ApiResponse<number>> {
        if (pageSize == undefined) {
            throw new Error(ExceptionMessageFormatter.argumentNull('pageSize'))
        }
        if (pageSize <= 0) {
            throw new Error(ExceptionMessageFormatter.argumentBelowBound('pageSize', 0))
        }

        const r = this.loadUserData().pipe(
            map(r => {
                return r.isOk ? this.savePageSizeImp(pageSize, r.result) : <any>r
            })
        )

        return Disposables.global.observeReplayOnce(r)
    }

    // saveFilter(
    //   modelType: FilterModelType,
    //   filterModel: any,
    //   selectors: string[]
    // ): Observable<ApiResponse<SavedFilter>> {
    //   if (modelType == undefined) {
    //     throw new Error(ExceptionMessageFormatter.argumentNull('modelType'));
    //   }
    //   if (filterModel == undefined) {
    //     throw new Error(ExceptionMessageFormatter.argumentNull('filterModel'));
    //   }
    //   if (TypeHelper.isStringNullOrEmpty(modelType)) {
    //     throw new Error(ExceptionMessageFormatter.argument('modelType'));
    //   }
    //   const r = this.loadUserData(true).pipe(
    //     map(r => {
    //       return r.isOk
    //         ? this.saveFilterImp(modelType, filterModel, selectors, r.result)
    //         : <any>r;
    //     })
    //   );

    //   return Disposables.global.observeReplayOnce(r);
    // }

    /**
     * Returns a `SavedFilter` from the current user profile where a filter of the specified type exists, otherwise returns `undefined`.
     * @param modelType The filter model type to get.
     */
    // getSavedFilter(modelType: FilterModelType): SavedFilter {
    //   const filters = this._userProfile.userData.filters;
    //   let model: any = filters.filters ? filters.filters[modelType] : undefined;
    //   let selectors: any = filters.selectors
    //     ? filters.selectors[modelType]
    //     : undefined;
    //   model = model ? TypeHelper.clone(model) : {};
    //   selectors = selectors ? TypeHelper.clone(selectors) : undefined;

    //   return filters != undefined
    //     ? new SavedFilter(modelType, model, selectors)
    //     : undefined;
    // }

    private loadUserDataByUsername(username: string): Observable<ApiResponse<UserData>> {
        // return this.api.getUserData().pipe(
        return this.apiService.apiService({ action: 'GETLIST', endpoint: 'userDatas' }, null).pipe(
            map((response: ApiResponse<UserDataEntity>) => {
                switch (response.code) {
                    case ApiResponseCode.Ok:
                        return ApiResponse.ok(fromUserDataEntity(response.result))

                    case ApiResponseCode.NotFound:
                        return ApiResponse.ok(defaultUserDataset(username))

                    default:
                        return <ApiResponse<UserData>>response
                }
            })
        )
    }

    private initProfileData(): Observable<any> {
        return from(
            this.keycloak
                .loadUserProfile(false)
                .then(p => {
                    this._keycloakProfile = p
                    return this.loadUserDataByUsername(p.username).toPromise()
                })
                .then(response => {
                    this.assignUserProfile(response.result)
                })
        )
    }

    private assignUserProfile(data: UserData) {
        const p = <any>TypeHelper.clone(data)
        delete p.uuid
        p.firstName = TypeHelper.sanitizeString(this._keycloakProfile.firstName)
        p.lastName = TypeHelper.sanitizeString(this._keycloakProfile.lastName)
        p.displayName = TypeHelper.sanitizeString(`${p.firstName} ${p.lastName}`, data.userName)

        if (p.userData == undefined) {
            p.userData = {}
        }

        if (p.userData.preferences == undefined) {
            p.userData.preferences = {}
        }

        if (p.userData.blockPlanFilters == undefined) {
            p.userData.blockPlanFilters = []
        }

        if (p.userData.filters == undefined) {
            p.userData.filters = {}
        }

        this._userProfile = p
        this._userProfile$.next(p)
    }

    // private saveFilterImp(
    //   type: FilterModelType,
    //   filterModel: any,
    //   selectors: string[],
    //   userData: UserData
    // ): Observable<ApiResponse<SavedFilter>> {
    //   if (userData.userData == undefined) {
    //     userData.userData = {};
    //   }

    //   let set = userData.userData.filters;
    //   if (set == undefined) {
    //     set = { filters: {}, selectors: {} };
    //     userData.userData.filters = set;
    //   } else {
    //     if (set.filters == undefined) {
    //       set.filters = {};
    //     }
    //     if (set.selectors == undefined) {
    //       set.selectors = {};
    //     }
    //   }

    //   set.filters[type] = filterModel;
    //   set.selectors[type] = selectors;

    //   const r = this.saveUserData(userData).pipe(
    //     map(r => {
    //       return r.isOk
    //         ? ApiResponse.ok(new SavedFilter(type, filterModel, selectors))
    //         : <any>r;
    //     })
    //   );

    //   return this.notification.handleAsyncApiResponse({
    //     entityType: EntityTypeKey.QueryFilter,
    //     actionType: ApiActionType.Update,
    //     entityName: type,
    //     response: r
    //   });
    // }

    private savePageSizeImp(pageSize: number, userData: UserData): Observable<ApiResponse<number>> {
        if (userData.userData == undefined) userData.userData = {}
        userData.userData.defaultPageSize = pageSize
        return this.saveUserData(userData).pipe(
            map(r => {
                return r.isOk ? ApiResponse.ok(pageSize) : <any>r
            })
        )
    }
}

function fromUserDataEntity(src: UserDataEntity): UserData {
    if (src == undefined) return undefined
    const result = <UserData>TypeHelper.clone(src)
    const json = src.userData != undefined ? src.userData.trim() : ''

    if (json.length > 0) {
        // JSON.parse twice to unescape the persisted JSON (this is because the userData property
        // is serialized as as a string at the remote endpoint).
        result.userData = JSON.parse(JSON.parse(src.userData))
    } else {
        result.userData = undefined
    }

    return result
}

function toUserDataEntity(src: UserData): UserDataEntity {
    if (src == undefined) return undefined
    const result = <UserDataEntity>TypeHelper.clone(src)
    if (src.userData != undefined) {
        // JSON.stringify twice to escape the initial JSON (this is because the userData property
        // is deserialized as as a string at the remote endpoint).
        result.userData = JSON.stringify(JSON.stringify(src.userData))
    }
    return <any>result
}

function defaultUserDataset(username: string): UserData {
    return {
        uuid: undefined,
        userName: username,
    }
}

function emptyProfile(): UserProfile {
    return {
        firstName: '',
        lastName: '',
        displayName: '',
        userName: '',
        userData: {
            preferences: {
                blockPlanFilters: [],
                dataTableColConfig: {},
            },
            filters: { filters: {}, selectors: {} },
        },
    }
}

/**
 * Removes any deprecated/redundant data from the `UserData` entity before it is saved.
 */
function deleteRedundantUserData(userData: UserData): void {
    if (userData.userData == undefined) return
    if (userData.userData.filters && userData.userData.filters.filters) {
        delete userData.userData.filters.filters['Seedlings']
        delete userData.userData.filters.filters['Plant']
        delete userData.userData.filters.filters['Scion']
        delete userData.userData.filters.filters['Cane']
    }
}
