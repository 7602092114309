import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin, Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { FeatureContext } from '../lib/feature-module-state/feature-module-state'
import { ViewStore } from '../services/view-store'
import { AuthFacade } from './auth-facade'
import { FacadeBase } from './facade-base'
import { NavFacade } from './nav-facade/nav-facade'
import { UsersFacade } from './users-facade.service'

interface TableConfig {
    [key: string]: string[]
}

declare const window: any

@Injectable({ providedIn: 'root' })
export class AppFacade extends FacadeBase {
    private static _singleton: AppFacade
    readonly appVersion: string = window.env.version
    readonly featureContext$: Observable<FeatureContext>
    readonly activePath$: Observable<string>
    readonly activeUrl$: Observable<string>

    constructor(readonly router: Router, viewStore: ViewStore, public readonly navigation: NavFacade, public readonly auth: AuthFacade, public readonly users: UsersFacade) {
        super()
        if (AppFacade._singleton == undefined) {
            AppFacade._singleton = this
        }
        this.featureContext$ = viewStore.featureContext$
        this.activePath$ = viewStore.path$
        this.activeUrl$ = viewStore.url$
    }

    async initApp(): Promise<void> {
        return await doInit(this.navigation, this.users)
    }
}

interface Initializable {
    init(): Observable<any>
}

async function doInit(...facades: FacadeBase[]): Promise<any> {
    let items: Observable<any>[] = []
    facades.forEach((f: FacadeBase) => {
        items.push((<Initializable>(<any>f)).init())
    })

    let promise = new Promise((complete, reject) => {
        forkJoin(items)
            .pipe(
                catchError(e => {
                    reject(e)
                    throw e
                })
            )
            .subscribe(() => {
                complete(undefined)
            })
    })

    return promise
}
