import { Observable, of } from 'rxjs'

export abstract class FacadeBase {
    /**
     * When overridden in a derived class, initiates any application initialisation that is the responsibility of that class.
     */
    protected init(): Observable<any> {
        return of(Promise.resolve())
    }
}
