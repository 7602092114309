import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { defaultExportPageSize } from '../../constants/page-size.constant'

import { UserDataEntity, TypedRestResponse, Disposables, UsersResponse, User, ApiResponse, ApiResponseCode, MapiServiceBase } from '@plantandfood/kup.core'

import { managementApi } from '../../constants/path.constants'
import { TrialPermissions } from '../../models/trial-permissions'

@Injectable({ providedIn: 'root' })
export class UsersService extends MapiServiceBase {
    constructor(http: HttpClient) {
        super(http, managementApi)
    }

    getUsers(params?: Params): Observable<TypedRestResponse<User>> {
        return this.getAndMap(
            'keycloak/usermembership',
            result => {
                ;(result._embedded = result.content),
                    (result.page = {
                        size: result.size,
                        totalElements: result.totalElements,
                        totalPages: result.totalPages,
                        number: result.number,
                    })
                return result
            },
            params
        )
    }

    deprecatedGetUsers(): Observable<ApiResponse<User[]>> {
        return this.getAndMapAsApiResponse('keycloak/usermembership', (response: UsersResponse) => response.content, { page: 0, size: defaultExportPageSize })
    }

    getUser(userId: string): Observable<ApiResponse<User>> {
        console.log('WARNING: no API endpoint to GET a single UserGroup entity by ID is currently implemented. Resolving from the "keycloak/groups" endpoint.')
        return Disposables.global.observeReplayOnce(this.deprecatedGetUsers()).pipe(
            map(response => {
                if (!response.isOk) return ApiResponse.error(response.code)

                let g = response.result.find(item => item.id === userId)
                return g != undefined ? ApiResponse.ok(g) : ApiResponse.error(ApiResponseCode.NotFound)
            })
        )
    }

    public getTrialPermissions(): Observable<TrialPermissions> {
        return this.get(`trials`, { permissionType: 'EDIT' })
    }

    getUserData(): Observable<ApiResponse<UserDataEntity>> {
        return this.getAsApiResponse('userDatas')
    }

    updateUserData(userData: UserDataEntity): Observable<ApiResponse<UserDataEntity>> {
        return this.putAsApiResponse('userDatas', userData)
    }

    createUserData(userData: UserDataEntity): Observable<ApiResponse<UserDataEntity>> {
        return this.postAsApiResponse('userDatas', userData)
    }
}
