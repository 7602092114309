import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import {
    EntityTypeKey,
    AppTextService,
    ApiActionType,
    ApiOutcomeDescriptor,
    ApiResponse,
    AsyncApiOutcomeDescriptor,
    NotificationCondition,
    FailureLevel,
    ApiOutcomeHandler,
    defaultApiOutcomeHandler,
} from '@plantandfood/kup.core'

/** @todo */
import { Logger } from '@plantandfood/kup.core'
import { FacadeBase } from './facade-base'

@Injectable({ providedIn: 'root' })
export class UserNotificationFacade extends FacadeBase {
    constructor(public readonly textService: AppTextService, private logger: Logger) {
        super()
    }

    handleEntityActionApiResponse<T>(
        observable: Observable<ApiResponse<T>>,
        actionType: ApiActionType,
        entityType: EntityTypeKey,
        entityName?: string,
        ...handlers: ApiOutcomeHandler<T>[]
    ): Observable<ApiResponse<T>> {
        return this.handleAsyncApiResponse(
            {
                actionType: actionType,
                response: observable,
                entityType: entityType,
                entityName: entityName,
            },
            ...handlers
        )
    }

    /**
     * Notifies the user of the outcome of an asynchronous API response.
     * @param descriptor The descriptor that should be used to contruct the notification message.
     * @param handlers Optional handlers that may be used to modify the descriptor.
     */
    handleAsyncApiResponse<T>(descriptor: AsyncApiOutcomeDescriptor<T>, ...handlers: ApiOutcomeHandler<T>[]): Observable<ApiResponse<T>> {
        let completed = false
        let sub = descriptor.response.subscribe(response => {
            if (sub != undefined) {
                sub.unsubscribe()
                sub = undefined
            }
            completed = true

            const revised: ApiOutcomeDescriptor<T> = {
                actionType: descriptor.actionType,
                response: response,
                condition: descriptor.condition,
                entityType: descriptor.entityType,
                entityName: descriptor.entityName,
                propertyName: descriptor.propertyName,
            }

            this.handleApiResponse(revised, ...handlers)
        })

        if (completed && sub != undefined) sub.unsubscribe()
        return descriptor.response
    }

    /**
     * Notifies the user of the outcome of an API response.
     * @param descriptor The descriptor that should be used to contruct the notification message.
     * @param handlers Optional handlers that may be used to modify the descriptor.
     */
    handleApiResponse<T>(descriptor: ApiOutcomeDescriptor<T>, ...handlers: ApiOutcomeHandler<T>[]) {
        this.applyApiResponse(descriptor, ...handlers)
        const message = descriptor.detailMessage

        if (descriptor.response.isOk) {
            const condition = descriptor.condition != undefined ? descriptor.condition : NotificationCondition.Default
            if (condition === NotificationCondition.Always) {
                this.notifySuccess(message)
            }
        } else {
            let level = descriptor.failureLevel
            if (level == undefined) level = FailureLevel.Default
            switch (level) {
                case FailureLevel.Warn:
                    this.notifyFailure(message)
                    break

                case FailureLevel.Error:
                    this.notifyError(message)
                    break
            }
        }
    }

    applyApiResponse<T>(descriptor: ApiOutcomeDescriptor<T>, ...handlers: ApiOutcomeHandler<T>[]) {
        const condition = descriptor.condition != undefined ? descriptor.condition : NotificationCondition.Default
        if (!(descriptor.response.isOk && condition === NotificationCondition.FailureOnly)) {
            this.processHandlers(descriptor, handlers)
        }
    }

    getApiResponseMessage<T>(descriptor: ApiOutcomeDescriptor<T>, ...handlers: ApiOutcomeHandler<T>[]): string {
        this.applyApiResponse(descriptor, ...handlers)
        return descriptor.detailMessage
    }

    private processHandlers(descriptor: ApiOutcomeDescriptor<any>, handlers: ApiOutcomeHandler<any>[]) {
        let handled = false
        for (let i = 0; !handled && i < handlers.length; i++) {
            handled = handlers[i](this.textService.messageFormatter, descriptor)
        }

        if (!handled) {
            defaultApiOutcomeHandler(this.textService.messageFormatter, descriptor)
        }
    }

    notifySuccess(message: string) {
        // TODO: Katalon - If we abandon the snackbar for such responses
        // for success: .kup-snackbar-success
        setTimeout(() => {
            this.logger.success(message)
        }, 0)
    }

    notifyFailure(message: string, response?: ApiResponse<any> | any) {
        // TODO: Katalon - If we abandon the snackbar for such responses
        // for error: .kup-snackbar-error
        setTimeout(() => {
            this.logger.warn(message, response)
        }, 0)
    }

    notifyError(message: string, response?: ApiResponse<any> | any) {
        // TODO: Katalon - If we abandon the snackbar for such responses
        // for error: .kup-snackbar-error
        setTimeout(() => {
            this.logger.error(message, response)
        }, 0)
    }
}
