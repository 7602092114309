import { BiomaterialRequest, RequestTypes } from '../../entities/modules/requests/requests'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { Observable } from 'rxjs'
import { TypedRestResponse, MapiServiceBase } from '@plantandfood/kup.core'
import { managementApi } from '../../constants/path.constants'

@Injectable({ providedIn: 'root' })
export class BiomaterialRequestsService extends MapiServiceBase {
    constructor(http: HttpClient) {
        super(http, managementApi)
    }

    getBiomaterialRequests(params: Params): Observable<TypedRestResponse<BiomaterialRequest>> {
        return this.getAndMap(
            `biomaterialRequests?projection=biomaterialRequestTable`,
            result => {
                result._embedded = result._embedded.biomaterialRequests
                return result
            },
            params
        )
    }

    updateRequest(requestId: any, body?, params?: Params): Observable<any> {
        return this.postAsApiResponse(`biomaterialRequests/${requestId}/actions`, body, params)
    }
}
