import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ApiResponse, ApiResponseCode } from '../../api'

export type ErrorPageType = 'AppError' | 'Forbidden' | 'NotFound'
export interface ErrorPageInfo {
    code: string
    message: string
}

@Injectable({ providedIn: 'root' })
export class ErrorPageNavFacade {
    constructor(private router: Router) { }

    gotoErrorPageAuto(apiResponse: ApiResponse<any>): Promise<boolean> {
        let target: ErrorPageType
        if (apiResponse) {
            switch (apiResponse.code) {
                case ApiResponseCode.NotFound:
                    target = 'NotFound'
                    break

                case ApiResponseCode.AccessDenied:
                    target = 'Forbidden'
                    break
            }
        }
        return this.gotoErrorPage(target != undefined ? target : 'AppError', apiResponse)
    }

    gotoErrorPage(target: ErrorPageType, details: ApiResponse<any> | ErrorPageInfo): Promise<boolean> {
        let route: string
        switch (target) {
            case 'Forbidden':
                route = '403'
                break

            case 'NotFound':
                route = '404'
                break

            default:
                route = 'error'
                break
        }

        let options: any
        if (details) {
            options = {
                queryParams: {
                    code: details.code,
                    message: details.message,
                },
            }
        }

        return this.router.navigate([route], options)
    }
}
