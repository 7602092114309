import { RolesService } from './../../filter-query-services/filters/roles-services'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { FilterQueryViewModel } from '../../filter-query-services'
import { Batch } from './../models/batch.model'
import { BiomaterialBatchesQueryCoordinator } from './biomaterial-batches-query-coordinator'
import { UserRole } from '@plantandfood/kup.core'
import { BiomaterialsService } from 'app/api/services/biomaterials.service'

@Injectable()
export class BiomaterialBatchesViewModel extends FilterQueryViewModel<Batch, BiomaterialBatchesQueryCoordinator> {
    private _canEdit = false

    constructor(query: BiomaterialBatchesQueryCoordinator, private router: Router, private rolesService: RolesService, private _biomaterialService: BiomaterialsService) {
        super(query)
        this._canEdit = rolesService.isUserInRole([UserRole.KUP_BIOMATERIAL_BATCH_ADMIN, UserRole.KUP_BIOMATERIAL_BATCH_CREATOR])
    }

    edit(batch: Batch): void {
        this._biomaterialService.entityNameSubject.next(batch.name)
        this.router.navigate([`/biomaterial-batches/edit/Batch/${batch.uuid}`])
    }

    create() {
        return this.router.navigate(['/biomaterial-batches/create'])
    }

    get canEdit(): boolean {
        return this._canEdit
    }

    get canCreate(): boolean {
        return this._canEdit
    }
}
