import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { AppTrace, AppTraceTarget } from '../app-lib/app-trace'
import { TraceLogger } from '../lib/trace/trace-logger'
import { FeatureContext } from '../lib/feature-module-state/feature-module-state'

@Injectable({ providedIn: 'root' })
export class ViewStore {
    private readonly _path = new BehaviorSubject('')
    private readonly _url = new BehaviorSubject('')
    private readonly _featureContext = new BehaviorSubject<FeatureContext>({
        context: 'default',
    })
    readonly path$: Observable<string>
    readonly url$: Observable<string>
    readonly featureContext$: Observable<FeatureContext>

    constructor() {
        this.featureContext$ = this._featureContext.asObservable()
        this.path$ = this._path.asObservable()
        this.url$ = this._url.asObservable()
    }

    get featureContext(): FeatureContext {
        return this._featureContext.value
    }

    get path(): string {
        return this._path.value
    }

    get url(): string {
        return this._url.value
    }

    setFeatureContext(context: FeatureContext): void {
        let value = context.context
        if (value == undefined || value === '') value = 'default'
        if (this._featureContext.value.context !== value) {
            if (AppTrace.isActive(AppTraceTarget.ViewStore)) {
                TraceLogger.console(this, `Feature context changed to "${value}".`, context)
            }
            this._featureContext.next(context)
        }
    }

    setCurrentUrl(url: string): void {
        if (url === undefined) url = ''

        let i = url.indexOf('?')
        let path = i >= 0 ? url.substr(0, i) : url

        if (url !== this._url.value) {
            if (AppTrace.isActive(AppTraceTarget.ViewStore)) {
                TraceLogger.console(this, `URL changed to "${url}".`)
            }
            this._url.next(url)
        }

        if (path !== this._path.value) {
            if (AppTrace.isActive(AppTraceTarget.ViewStore)) {
                TraceLogger.console(this, `Path changed to "${path}".`)
            }
            this._path.next(path)
        }
    }
}
