import { Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { FilteredQueryResult, FilterPanelService } from 'kup.common.ui'
import { FilteredQueryBase, FilterModelType, QueryActionHelper, QueryErrorPageHelper, QueryRouteHelper } from '../../filter-query-services'
import { first, map } from 'rxjs/operators'

import { BiomaterialRequestsService } from '../../app/api/services/biomaterial-requests.service'
import { BiomaterialRequest } from './../../app/entities/modules/requests/requests'
import { RequestsConfigService } from './requests-config.service'
import { RequestsFormFactory } from '../factories/requests-form-factory'
import { RequestsFilterDefinition } from '../models/requests-filter-definition'

@Injectable()
export class RequestsQueryCoordinator extends FilteredQueryBase<{}, {}, BiomaterialRequest> {
    constructor(
        private service: BiomaterialRequestsService,
        private formFactory: RequestsFormFactory,
        filterPanelService: FilterPanelService,
        queryRouteHelper: QueryRouteHelper,
        queryErrorPageHelper: QueryErrorPageHelper,
        queryActionHelper: QueryActionHelper,
        requestsConfigService: RequestsConfigService
    ) {
        super(
            FilterModelType.BiomaterialRequests,
            filterPanelService,
            RequestsFilterDefinition.getFields(),
            queryRouteHelper,
            queryErrorPageHelper,
            queryActionHelper,
            requestsConfigService
        )
    }

    protected execute(params?: Params): Promise<FilteredQueryResult<BiomaterialRequest>> {
        params = this.updateParams(['biomaterialRequests.name'], params)
        return this.service
            .getBiomaterialRequests(params)
            .pipe(
                first(),
                map(res => {
                    return this.toFilteredQueryResult(res._embedded, res.page)
                })
            )
            .toPromise()
    }

    protected createFormDefinition(): FormlyFieldConfig[] {
        return this.formFactory.requestsFilterForm()
    }
}
