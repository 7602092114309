import { ApiService, DropdownDatasource, DropdownProvider } from '@plantandfood/kup.core'
import { map } from 'rxjs/operators'

export class TrialIdDropdownProviderNew extends DropdownProvider {
    constructor(service: ApiService, permissionType: string, pageSize?: number) {
        super(
            DropdownDatasource.createMapDynamic((filter: string) => {
                const defaultExportPageSize = 2147483647
                pageSize = Math.max(Math.min(pageSize ? pageSize : defaultExportPageSize), 1)
                filter = filter !== undefined ? filter.toLowerCase() : ''
                const params = `trial.name.contains=${filter}&trial.activeStatus=Active&permissionType=${permissionType}&sort=name,asc&size=${pageSize}`
                const obs = service
                    .apiService(
                        {
                            action: 'GETLIST',
                            endpoint: 'trials',
                        },
                        null,
                        null,
                        `?${params}`
                    )
                    .pipe(map(res => res.result._embedded.trials))
                return obs
            }, 'uuid')
        )
    }
}
