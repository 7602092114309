import { PlantsTableFactory } from './../../plants/factories/plants-table-factory'
import { SamplesTableFactory } from './../../biomaterial-samples/factories/samples-table-factory'
import { CanesTableFactory } from './../../canes/factories/canes-table-factory'
import { FruitsTableFactory } from './../../fruits/factories/fruits-table-factory'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Injectable } from '@angular/core'
import { AppTextService, TableDefinition, UniquelyIdentified } from '@plantandfood/kup.core'
import { ScionsTableFactory } from 'scions/factories/scions-table-factory'

@Injectable({ providedIn: 'root' })
export class CustomDataService {
    private _optionChanged$ = new BehaviorSubject<any>(null)
    readonly optionChanged$: Observable<any>
    private _manageModeChanged$ = new BehaviorSubject<any>(null)
    readonly manageModeChanged$: Observable<any>
    private _biomaterialsChanged$ = new Subject<UniquelyIdentified[]>()
    readonly biomaterialsChanged$: Observable<UniquelyIdentified[]>
    private biomaterials: UniquelyIdentified[] = []
    private currentData: UniquelyIdentified[] = []
    private _pendingChanged$ = new BehaviorSubject<number>(0)
    readonly pendingChanged$: Observable<number>
    private _currentUserName$ = new BehaviorSubject<any>(null)
    readonly currentUserName$: Observable<any>
    private _pageValues$ = new BehaviorSubject<number>(0)
    readonly pageValues$: Observable<any>
    private _tableDefinition$ = new BehaviorSubject<TableDefinition>(null)
    readonly tableDefinition$: Observable<TableDefinition>

    constructor(
        readonly scionsTableFactory: ScionsTableFactory,
        readonly fruitTableFactory: FruitsTableFactory,
        readonly canesTableFactory: CanesTableFactory,
        readonly samplesTableFactory: SamplesTableFactory,
        readonly plantsTableFactory: PlantsTableFactory,
        private textService: AppTextService
    ) {
        this.pendingChanged$ = this._pendingChanged$.asObservable()
        this.biomaterialsChanged$ = this._biomaterialsChanged$.asObservable()
        this.optionChanged$ = this._optionChanged$.asObservable()
        this.manageModeChanged$ = this._manageModeChanged$.asObservable()
        this.currentUserName$ = this._currentUserName$.asObservable()
        this.pageValues$ = this._pageValues$.asObservable()
        this.tableDefinition$ = this._tableDefinition$.asObservable()
    }

    updateTable(type, mode, manage): void {
        let def: any
        switch (type) {
            case 'plants':
                def = this.plantsTableFactory.plants()
                break
            case 'scions':
                def = this.scionsTableFactory.scions()
                break
            case 'fruit':
                def = this.fruitTableFactory.fruits()
                break
            case 'samples':
                def = this.samplesTableFactory.samples()
                break
            case 'canes':
                def = this.canesTableFactory.canes()
                break
            default:
                def = null
        }

        if (manage) {
            def.columns.unshift({
                columnDef: 'selected',
                header: mode === 'add' ? this.textService.getText('label.add-to-batch') : this.textService.getText('label.remove-from-batch'),
                formatter: 'checkbox',
                sortDisabled: true,
                show: true,
                selectable: true,
            })
        }
        this._tableDefinition$.next(def)
    }

    currentUser(userName: string) {
        this._currentUserName$.next(userName)
    }

    changeOption(option) {
        this._optionChanged$.next(option)
    }

    changeMode(mode) {
        this._manageModeChanged$.next(mode)
    }

    addBiomaterial(data) {
        if (Array.isArray(data)) {
            data.forEach(element => {
                const isInArray = this.biomaterials.find(biomaterial => biomaterial.uuid === element.uuid) !== undefined
                if (!isInArray) {
                    this.biomaterials.push(element)
                }
            })
            this._biomaterialsChanged$.next(this.biomaterials.slice())
            const numBiomaterials = this.pendingBiomaterialCount()
            this._pendingChanged$.next(numBiomaterials)
        } else {
            this.biomaterials.push(data)
            this._biomaterialsChanged$.next(this.biomaterials.slice())
            const numBiomaterials = this.pendingBiomaterialCount()
            this._pendingChanged$.next(numBiomaterials)
        }
    }

    get pendingBiomaterials() {
        return this.biomaterials
    }

    removeBiomaterial(data) {
        if (Array.isArray(data)) {
            data.forEach(element => {
                const idx = this.biomaterials.findIndex(item => item.uuid === element.uuid)
                this.biomaterials.splice(idx, 1)
            })
        } else {
            const idx = this.biomaterials.findIndex(item => item.uuid === data.uuid)
            this.biomaterials.splice(idx, 1)
        }
        this._biomaterialsChanged$.next(this.biomaterials.slice())
        const numBiomaterials = this.pendingBiomaterialCount()
        this._pendingChanged$.next(numBiomaterials)
    }

    isPending(biomaterialId: string): boolean {
        const isPending = this.biomaterials.find(biomaterial => biomaterial.uuid === biomaterialId)
        if (isPending) {
            return true
        } else {
            return false
        }
    }

    isCurrentDataPending(data): boolean {
        let pending = false
        let count = 0
        data.forEach(element => {
            const isInArray = this.biomaterials.find(biomaterial => biomaterial.uuid === element.uuid) !== undefined
            if (!isInArray) {
                count++
            }
        })
        if (count == 0 && this.biomaterials.length != 0) {
            pending = true
        }
        return pending
    }

    clearPendingBiomaterials() {
        this.biomaterials = []
        const numBiomaterials = this.pendingBiomaterialCount()
        this._pendingChanged$.next(numBiomaterials)
    }

    pendingBiomaterialCount(): number {
        return this.biomaterials.length
    }

    pageValue(value) {
        this._pageValues$.next(value)
    }
}
