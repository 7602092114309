import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'

import { SimpleTableComponentBaseDirective } from './simple-table-base'
import { TableRowReorderEventArgs, MigrationHelper } from '@plantandfood/kup.core'
import { UsersMigrationHelper } from './users-migration-helper'

@Component({
    selector: 'app-simple-table',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [UsersMigrationHelper],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTableComponent extends SimpleTableComponentBaseDirective implements OnInit, AfterViewInit {
    readonly page: any
    readonly dataSource = new MatTableDataSource<any>()

    @Output() dragRow: EventEmitter<TableRowReorderEventArgs<any>> = new EventEmitter()

    @Input()
    get data(): any[] {
        return this.dataSource.data
    }

    set data(value: any[]) {
        if (value == undefined) {
            value = []
        }
        this.setInitialising(false)
        this.dataSource.data = value
        this.resyncPaginators()
    }

    constructor(changeDetector: ChangeDetectorRef, usersFacade: UsersMigrationHelper, activeNavFacade: MigrationHelper) {
        super(changeDetector, usersFacade, activeNavFacade)
        this.suppressPageParams = true
        this.hasSearch = true
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase()
            }
            return data[sortHeaderId]
        }
        this.changeDetector.detectChanges()
    }

    protected onMasterPaginatorChanged(master: MatPaginator) {
        this.dataSource.paginator = master
    }

    /**
     * Forces the table to rebind to its datasource.
     * This is useful for refreshing the table when elements in the bound data array have changed
     * (otherwise undetected by Angular because the array reference itself has not changed).
     */
    rebindData(): void {
        this.data = this.data
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim()
        filterValue = filterValue.toLowerCase()
        this.dataSource.filter = filterValue
        this.resyncPaginators()
    }

    hasNoResults(): boolean {
        return !this.dataSource.filteredData || this.dataSource.filteredData.length === 0
    }

    dropRow(event: CdkDragDrop<any[]>): void {
        const prevIndex = this.data.findIndex(d => d === event.item.data)
        moveItemInArray(this.data, prevIndex, event.currentIndex)
        // Reassign the array data to force change detection/resync
        this.dataSource.data = this.data
        this.dragRow.emit({
            item: event.item.data,
            currentIndex: event.currentIndex,
            previousIndex: event.previousIndex,
        })
    }
}
