import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { DashboardComponent } from './containers/dashboard/dashboard.component'
import { AuthGuard } from '../app/guards/auth.guard'
import { PageTitles } from '../app/constants/page-titles.constants'
import { UserRole } from '@plantandfood/kup.core'

const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            access: 'authenticated',
            title: `${PageTitles.DASHBOARD}`,
            permissions: { only: [UserRole.KUP_USER] },
        },
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
