import { NavLinks } from '@plantandfood/kup.core'

export const appNavLinks: NavLinks = [
    {
        path: '/biomaterial-collections',
        label: 'Collections',
        svgLigature: 'more_vert',
        id: 'sidenav_link_biomaterial_collection',
    },
    {
        path: '/biomaterial-batches',
        label: 'Batches',
        svgLigature: 'list',
        id: 'sidenav_link_biomaterial-batches',
    },
    {
        path: undefined,
        label: 'Biomaterials',
        svgLigature: 'biomaterial',
        id: 'sidenav_link_biomaterials',
        children: [
            {
                path: 'biomaterials/canes',
                label: 'Canes',
                svgLigature: 'biomaterial',
                id: 'sidenav_link_canes',
            },
            {
                path: 'biomaterials/scions',
                label: 'Scions',
                svgLigature: 'biomaterial',
                id: 'sidenav_link_scions',
            },
            {
                path: 'biomaterials/plants',
                label: 'Plants',
                svgLigature: 'biomaterial',
                id: 'sidenav_link_plants',
            },
            {
                path: 'biomaterials/fruit',
                label: 'Fruit',
                svgLigature: 'biomaterial',
                id: 'sidenav_link_fruits',
            },
            {
                path: 'biomaterials/samples',
                label: 'Samples',
                svgLigature: 'biomaterial',
                id: 'sidenav_link_samples',
            },
        ],
    },
    {
        path: '/biomaterial-creation-methods',
        label: 'Creation Methods',
        svgLigature: 'science',
        id: 'sidenav_link_biomaterial-creation-methods',
    },
    {
        path: '/genotype-registries',
        label: 'Genotype Registries',
        svgLigature: 'genotypes',
        id: 'sidenav_link_genotype-registries',
    },
    {
        path: '/requests',
        label: 'Requests',
        svgLigature: 'receipt',
        id: 'sidenav_link_request',
    },
]
