import { IServiceList } from '@plantandfood/kup.core'

export const serviceList: IServiceList = {
    CREATE_REQUEST: {
        action: 'CREATE',
        endpoint: `biomaterialRequests`,
    },
    UPDATE_REQUEST: {
        action: 'UPDATE',
        endpoint: `biomaterialRequests`,
    },
    GET_REQUEST: {
        action: 'GET',
        endpoint: `biomaterialRequests`,
    },
    GET_REQUESTS: {
        action: 'GETLIST',
        endpoint: `biomaterialRequests?projection=biomaterialRequestTable`,
    },
    GET_SITE: {
        action: 'GET',
        endpoint: `sites`,
    },
    GET_EXTERNAL_REFERENCES: {
        action: 'GETLIST',
        endpoint: `externalReferenceNamespaces`,
    },
    CREATE_BATCH: {
        action: 'CREATE',
        endpoint: `biomaterialBatches`,
    },
    GET_BATCHES: {
        action: 'GETLIST',
        endpoint: `biomaterialBatches`,
    },
    GET_TRIAL_OPERATIONS: {
        action: 'GETLIST',
        endpoint: `trials`,
    },
    GET_BATCH: {
        action: 'GET',
        endpoint: `biomaterialBatches`,
    },
    GET_BATCH_BIOMATERIAL_TYPES: {
        action: 'GET',
        endpoint: `biomaterialBatches`,
    },
    GET_CREATION_METHOD: {
        action: 'GET',
        endpoint: 'biomaterialCreationMethods',
    },
}
