import { PageEvent } from '@angular/material/paginator'
import { Params } from '@angular/router'
import { FilterPageAdapter } from 'kup.common.ui'

/**
 * A FilterPageAdapter implementation that applies the page parameters expected by the Spring system.
 */
export class SpringFilterPageAdapter implements FilterPageAdapter {
    static readonly singleton: SpringFilterPageAdapter = new SpringFilterPageAdapter()
    private constructor() {}

    applyState(params: Params, page: PageEvent) {
        params.page = page.pageIndex.toString()
        params.size = page.pageSize.toString()
    }

    resetPageIndex(params: Params) {
        params.page = 0
    }
}
