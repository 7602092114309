import { SamplesNavFacade } from './samples-nav-facade'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ViewStore } from '../../services/view-store'
import { FacadeBase } from '../facade-base'
import { ActiveRouteNavFacade } from './active-route-nav-facade'
import { ErrorPageNavFacade } from './error-page-nav-facade'
import { UsersNavFacade } from './users-nav-facade'
import { BiomaterialBatchesNavFacade } from './biomaterial-batches-nav-facade'

@Injectable({ providedIn: 'root' })
export class NavFacade extends FacadeBase {
    readonly errorPage: ErrorPageNavFacade
    readonly users: UsersNavFacade
    readonly active: ActiveRouteNavFacade
    readonly biomaterialBatches: BiomaterialBatchesNavFacade
    readonly samples: SamplesNavFacade

    constructor(router: Router, route: ActivatedRoute, view: ViewStore) {
        super()
        this.errorPage = new ErrorPageNavFacade(router)
        this.users = new UsersNavFacade(router)
        this.active = new ActiveRouteNavFacade(router, route, view)
        this.biomaterialBatches = new BiomaterialBatchesNavFacade(router)
        this.samples = new SamplesNavFacade(router)
    }
}
