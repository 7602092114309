import { FilterField, FilterFieldFlags } from 'kup.common.ui'

import { FilterFieldLabel } from '../../app/app-lib/filter/filter-field-label'
import { FilterFieldName } from '../../app/app-lib/filter/filter-field-name'

export class RequestsFilterDefinition {
    static readonly Name = FilterField.createWithOptions(FilterFieldName.RequestName)

    static readonly Requester = FilterField.createWithOptions(FilterFieldName.Requester, {
        flags: FilterFieldFlags.MultiValue,
        selectorGroup: FilterFieldLabel.Requester,
    })

    static readonly RequestType = FilterField.createWithOptions(FilterFieldName.RequestType, {
        flags: FilterFieldFlags.MultiValue,
        selectorGroup: FilterFieldLabel.RequestType,
    })

    static readonly Recipient = FilterField.createWithOptions(FilterFieldName.Recipient, {
        flags: FilterFieldFlags.MultiValue,
        selectorGroup: FilterFieldLabel.Recipient,
    })

    static readonly Status = FilterField.createWithOptions(FilterFieldName.Status, {
        flags: FilterFieldFlags.MultiValue,
        selectorGroup: FilterFieldLabel.Status,
    })

    static readonly CreationMethod = FilterField.createWithOptions(FilterFieldName.BiomaterialCreationMethod, {
      flags: FilterFieldFlags.MultiValue,
      selectorGroup: FilterFieldLabel.BiomaterialCreationMethod,
  })

    static getFields(): FilterField[] {
        return [
            RequestsFilterDefinition.Name,
            RequestsFilterDefinition.Requester,
            RequestsFilterDefinition.RequestType,
            RequestsFilterDefinition.Recipient,
            RequestsFilterDefinition.Status,
            RequestsFilterDefinition.CreationMethod
        ]
    }
}
