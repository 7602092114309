import { ActivatedRoute, Params, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { ExceptionMessageFormatter } from '../../lib/exception-message-formatter'
import { TypeHelper } from '../../lib/type-helper'
import { ViewStore } from '../../services/view-store'

//#region PendingRoute
class PendingRoute {
    readonly promise: Promise<boolean>
    private _resolve: (success: boolean) => void

    constructor() {
        this.promise = new Promise(resolve => {
            this._resolve = resolve
        })
    }

    resolve(success: boolean) {
        this._resolve(success)
    }
}

//#endregion

//#region QueryParamCommandType
enum QueryParamCommandType {
    Nop = 0,
    Add,
    Remove,
    Set,
    Clear,
}

//#endregion

//#region QueryParamCommand

interface QueryParamCommand {
    type: QueryParamCommandType
    params?: Params
}

//#endregion

/**
 * `ActiveRouteNavFacade` enables the serialization of query parameter router actions by the application.
 * This enables the application to maintain a consistent and predictable query parameter state.
 * Because the Angular router operates asynchronously, conventional changes to query params may be lost when multiple
 * components make non-synchronised changes to the active query parameter collection.
 * `ActiveRouteNavFacade` ensures that such loses do not occur.
 */
export class ActiveRouteNavFacade {
    private _paramQueue: QueryParamCommand[] = []
    private _pending: PendingRoute = undefined

    constructor(private router: Router, private route: ActivatedRoute, view: ViewStore) {
        view.path$.subscribe(() => {
            this._paramQueue = []
        })
    }

    /**
     * Adds the specified query parameters to the active route without causing other query parameters to be lost.
     */
    addQuery(params: Params): Promise<boolean> {
        if (params == undefined) throw new Error(ExceptionMessageFormatter.argumentNull('params'))

        return this.queueQueryOp({
            type: QueryParamCommandType.Add,
            params: params,
        })
    }

    /**
     * Removes the specified query parameters from the active route without causing other query parameters to be lost.
     */
    removeQuery(names: string[]): Promise<boolean> {
        if (names == undefined) throw new Error(ExceptionMessageFormatter.argumentNull('names'))
        return this.queueQueryOp({
            type: QueryParamCommandType.Remove,
            params: { keys: names },
        })
    }

    /**
     * Applies the specified query parameters to the active route. All existing query parameters are removed.
     */
    setQuery(params: Params): Promise<boolean> {
        if (params == undefined) throw new Error(ExceptionMessageFormatter.argumentNull('params'))
        return this.queueQueryOp({
            type: QueryParamCommandType.Set,
            params: params,
        })
    }

    /**
     * Clears all existing query parameters from the active route.
     */
    clearQuery(params: Params): Promise<boolean> {
        if (params == undefined) throw new Error(ExceptionMessageFormatter.argumentNull('params'))
        return this.queueQueryOp({
            type: QueryParamCommandType.Set,
            params: params,
        })
    }

    /**
     * Applies all queued operations to the active route, and then returns the set of updated query parameters.
     */
    getQueryParams(): Promise<Params> {
        return this.queueQueryOp({ type: QueryParamCommandType.Nop }).then(() => {
            let obs: Subscription
            let complete = false
            let result = new Promise(resolve => {
                obs = this.route.queryParams.subscribe(p => {
                    // Cloning the params is necessary, because otherwise client code cannot assign/remove properties on the instance
                    // (An "object is not extensible" error is thrown by the framework)
                    p = TypeHelper.clone(p)
                    complete = true
                    if (obs) {
                        obs.unsubscribe()
                    }
                    resolve(p)
                })
            })

            if (complete && obs) {
                obs.unsubscribe()
            }

            return result
        })
    }

    private queueQueryOp(cmd: QueryParamCommand): Promise<boolean> {
        this._paramQueue.push(cmd)

        if (this._pending == undefined) {
            this._pending = new PendingRoute()
        }

        setTimeout(
            pending => {
                if (this._pending === pending) {
                    this._pending = undefined
                    this.processQueue(pending)
                }
            },
            0,
            this._pending
        )

        return this._pending.promise
    }

    private processQueue(pending: PendingRoute): void {
        if (this._paramQueue.length === 0) {
            pending.resolve(true)
        } else {
            let params = { ...this.route.snapshot.queryParams }

            while (this._paramQueue.length > 0) {
                let next = this._paramQueue.shift()
                switch (next.type) {
                    case QueryParamCommandType.Add:
                        params = {
                            ...params,
                            ...next.params,
                        }
                        break

                    case QueryParamCommandType.Remove:
                        let keys: string[] = next.params.keys
                        keys.forEach(k => {
                            delete params[k]
                        })
                        break

                    case QueryParamCommandType.Set:
                        params = next.params
                        break

                    case QueryParamCommandType.Clear:
                        params = {}
                        break
                }
            }

            this.router
                .navigate([], {
                    relativeTo: this.route,
                    queryParams: params,
                })
                .then(success => {
                    pending.resolve(success)
                })
        }
    }

    getParams() {
        this.route.params.subscribe((params: Params) => {
            const uuid = params['uuid']
            return uuid
        })
    }
}
