import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Disposables, User, UserGroup, ApiResponse, ApiResponseCode, MapiServiceBase } from '@plantandfood/kup.core'

import { defaultExportPageSize } from '../../constants/page-size.constant'
import { managementApi } from '../../constants/path.constants'

@Injectable({ providedIn: 'root' })
export class UserGroupsService extends MapiServiceBase {
    constructor(http: HttpClient) {
        super(http, managementApi)
    }

    getUserGroups(): Observable<ApiResponse<UserGroup[]>> {
        return this.getAsApiResponse('keycloak/groups', {
            size: defaultExportPageSize,
        })
    }
}
