import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { AuthService } from '@plantandfood/kup.auth'

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.addAuthorizationHeader(request, next)
    }

    addAuthorizationHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.getToken().pipe(
            switchMap(token => {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'bearer ' + token,
                    },
                })
                return next.handle(request)
            })
        )
    }
}
