export class ExceptionMessageFormatter {
    static argument(parameterName: string): string {
        return `The argument "${parameterName}" is invalid.`
    }

    static argumentNull(parameterName: string): string {
        return `The argument "${parameterName}" cannot be null.`
    }

    static argumentOutOfRange(parameterName: string, lower: number | string, upper: number | string): string {
        return `The argument "${parameterName}" must be between ${lower} and ${upper}.`
    }

    static argumentBelowBound(parameterName: string, lowerBound: number | string): string {
        return `The argument "${parameterName}" must be greater than or equal to ${lowerBound}.`
    }

    static argumentAboveBound(parameterName: string, upperBound: number | string): string {
        return `The argument "${parameterName}" must be less than or equal to ${upperBound}.`
    }

    static argumentMustBeInt(parameterName: string) {
        return `The argument "${parameterName}" must be an integer.`
    }

    static notImplemented(): string {
        return 'Not implemented.'
    }

    static notSupported(operationName: string) {
        return `${operationName} is not supported.`
    }

    static invalidOperation(operationName?: string, condition?: string) {
        if (!operationName) return 'The operation is invalid due to the current state of the object.'
        let result = `The "${operationName}" operation is invalid due to the current state of the object.`
        if (condition) {
            result += ` (${condition})`
        }

        return result
    }

    static resolverFailed(typeName: string): string {
        return `Failed to resolve ${typeName} on the active route.`
    }
}
