<div class="pfr-app-theme app-table-container" [id]="id || 'app_table_container'">
    <!-- Search Filter -->
    <div class="search-table-header no-print" *ngIf="hasSearch">
        <form autocomplete="off">
            <mat-form-field class="table-search">
                <input matInput type="text" name="table-search" #search (keyup)="applyFilter($event.target.value)" placeholder="Search" autocomplete="none" />
            </mat-form-field>
        </form>
    </div>

    <!-- Simple Paginator -->
    <div [hidden]="!showPager">
        <mat-paginator
            class="no-print"
            #topPaginator
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="activePageSize"
            [showFirstLastButtons]="true"
            [pageIndex]="page?.number"
            [length]="page?.totalElements"
        >
        </mat-paginator>
    </div>

    <div>
        <!-- Table -->
        <table
            mat-table
            [dataSource]="dataSource"
            class="table-table"
            [ngClass]="{ selectable: selectable }"
            matSort
            [matSortActive]="sortActive"
            matSortDisableClear
            [matSortDirection]="sortDirection"
            cdkDropList
            [cdkDropListData]="dataSource"
            (cdkDropListDropped)="dropRow($event)"
        >
            <!-- Dynamic Columns -->
            <ng-container *ngFor="let column of columns; trackBy: columnTracker" [matColumnDef]="column.columnDef">
                <th mat-header-cell [id]="'app_table_' + column.header" *matHeaderCellDef>
                    <mat-checkbox
                        *ngIf="column.selectable"
                        [checked]="isColumnSelected(column.columnDef)"
                        [disabled]="false"
                        (change)="onColumnSelectChange($event, column.columnDef)"
                    >
                        {{ column.header }}
                    </mat-checkbox>
                    <span *ngIf="!column.selectable" [mat-sort-header]="column.sortKey" [disabled]="column.sortDisabled" class="sortKey">{{ column.header }}</span>
                </th>
                <td mat-cell class="table-tooltip" *matCellDef="let row" [matTooltip]="getDefaultColumnTooltip(row, column)" matTooltipPosition="above">
                    <!-- Formatters -->
                    <ng-container [ngSwitch]="resolveFormat(row, column)">
                        <span *ngSwitchCase="'html'">
                            <span [innerHTML]="row[column.columnDef]"></span>
                        </span>
                        <div *ngSwitchCase="'truncate'" class="truncate-text" [innerText]="stripHTML(row[column.columnDef])"></div>
                        <div *ngSwitchCase="'text'" class="truncate-text" [innerText]="stripHTML(row[column.columnDef])"></div>
                        <div *ngSwitchCase="'date'" class="truncate-text">{{ renderCellValue(row, column.columnDef) | date: dateFormat }}</div>
                        <div *ngSwitchCase="'date-time'" class="truncate-text">{{ renderCellValue(row, column.columnDef) | date: dateTimeFormat }}</div>
                        <span *ngSwitchCase="'checkbox-ex'">
                            <mat-checkbox
                                [checked]="row[column.columnDef]?.checked"
                                [disabled]="row[column.columnDef]?.disabled || readOnly"
                                (click)="$event.stopPropagation()"
                                (change)="onCheckboxExChange($event, row, column.columnDef)"
                            >
                            </mat-checkbox>
                        </span>
                        <span *ngSwitchCase="'checkbox'">
                            <!-- <input type="checkbox" [attr.checked]="resolveValueAsAttribute(row, column.columnDef)" (change)="onCheckboxChange($event, row, column.columnDef)"> -->
                            <mat-checkbox
                                [checked]="resolveValue(row, column.columnDef) === true"
                                [disabled]="readOnly"
                                (click)="$event.stopPropagation()"
                                (change)="onCheckboxChange($event, row, column.columnDef)"
                            >
                            </mat-checkbox>
                        </span>
                        <span *ngSwitchCase="'chip-list'">
                            <div *ngFor="let chip of row[column.columnDef]" class="pfr-chip-list">
                                <span class="pfr-chip-item">{{ chip }}</span>
                            </div>
                        </span>
                        <span *ngSwitchCase="'file-list'">
                            <div *ngFor="let file of row[column.columnDef]" (click)="onClickFile(file)" class="pfr-file-list">
                                <span class="pfr-file">
                                    <mat-icon>insert_drive_file</mat-icon>
                                </span>
                                <span class="pfr-file-item">{{ file.name }}</span>
                            </div>
                        </span>
                        <span *ngSwitchCase="'list'">
                            <ul class="o-list-bare simple-list">
                                <li class="o-list-bare__item" *ngFor="let item of row[column.columnDef]">
                                    {{ item }}
                                </li>
                            </ul>
                        </span>
                        <span *ngSwitchCase="'status-icon'">
                            <mat-icon [matTooltip]="row.status" matTooltipPosition="above" svgIcon="{{ getStatusIcon(row.status) }}"> </mat-icon>
                        </span>
                        <span *ngSwitchCase="'assigned-icon'">
                            <mat-icon svgIcon="{{ getTrueIcon(row) }}"> </mat-icon>
                        </span>
                        <span *ngSwitchCase="'icon'">
                            <mat-icon [matTooltip]="getIconTooltip(row[column.tooltipKey])" matTooltipPosition="above" svgIcon="{{ getIcon(row[column.columnDef]) }}"> </mat-icon>
                        </span>
                        <span *ngSwitchCase="'boolean'">
                            <mat-icon>{{ row[column.columnDef] ? 'check' : 'clear' }}</mat-icon>
                        </span>
                        <span *ngSwitchDefault>
                            {{ renderCellValue(row, column.columnDef) }}
                        </span>
                    </ng-container>
                </td>
            </ng-container>

            <!-- actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="actions-cell"></th>
                <td mat-cell *matCellDef="let row; let i = index" class="actions-cell">
                    <ul class="inline-action-btn">
                        <li *ngFor="let action of getActions()">
                            <button
                                mat-icon-button
                                [color]="getActionColour(action)"
                                [matTooltip]="getActionTooltip(action)"
                                [disabled]="rowIsReadOnly(row)"
                                (click)="onClickAction(row, action, $event)"
                                [ngClass]="action.class"
                            >
                                <span *ngIf="hasSvg(action)">
                                    <mat-icon svgIcon="{{ getActionIcon(action) }}"></mat-icon>
                                </span>
                                <span *ngIf="!hasSvg(action)">
                                    <mat-icon class="action-mat-icon">{{ getActionIcon(action) }}</mat-icon>
                                </span>
                            </button>
                        </li>
                    </ul>

                    <!-- Warning -->
                    <div *ngIf="rowShowWarningIcon(row)">
                        <button mat-icon-button matTooltip="Needs Checking" matTooltipPosition="above">
                            <mat-icon aria-label="warning">warning</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <!-- Table -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="table-row"
                [ngClass]="{
                    'is-subtle': rowIsSubtle(row),
                    'has-warning': rowShowWarningIcon(row),
                    'is-disabled': rowIsReadOnly(row),
                    'is-dragable': canDrag
                }"
                (click)="onClickRow(row)"
                cdkDrag
                [cdkDragDisabled]="!canDrag"
                [cdkDragData]="row"
                [attr.id]="rowId(row)"
            >
                <div class="drag-placeholder" *cdkDragPlaceholder>
                    <mat-icon aria-label="move">subdirectory_arrow_right</mat-icon>
                </div>
            </tr>
        </table>
    </div>

    <div id="app_table_loading" class="app-table-spinner-container" *ngIf="loading">
        <mat-spinner diameter="40" class="table-spinner"></mat-spinner>
    </div>

    <div id="app_table_no_results" class="no-results no-print" *ngIf="showNoResults">No results</div>

    <!-- Simple Paginator -->
    <div [hidden]="!showPager">
        <mat-paginator
            class="no-print"
            #bottomPaginator
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="activePageSize"
            [showFirstLastButtons]="true"
            [pageIndex]="page?.number"
            [length]="page?.totalElements"
        >
        </mat-paginator>
    </div>
</div>
