<div fxLayout="row" class="app-dashboard-container" fxLayoutAlign="center center">
    <div fxFlex="100%" align="center">
        <img class="logo" src="assets/kiwicloud-logo.png" />
        <h1 class="heading">Welcome to KiwiCloud - BITS</h1>
        <h4 class="sub-heading">
            <strong>Biomaterial Inventory and Tracking System (BITS)</strong> is an extension for <strong>KiwiCloud</strong>. BITS has been designed for managing inventory and
            movement tracking for Biomaterials using Requests and Processes. In the future it will include support for all Kiwifruit breeding and genomics to cover tracking DNA
            samples for Marker Assisted Selection and Molecular Breeding, and genotyping data through <strong>Plant & Food Research </strong> collaboration with the Genomic
            Open-Source Breeding Informatics Initiative <strong>(GOBii)​​</strong>​​.
        </h4>
        <button *ngIf="!hasAccess" type="submit" color="primary" mat-raised-button id="dashboard-request-access" (click)="onRequestAccess()">Request Access</button>
    </div>
</div>
