import { HttpClient } from '@angular/common/http'
import { Params } from '@angular/router'
import { flatten } from 'flat'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as fromUtil from '../../app/util/utils'
import { ApiResponse } from './api-response'
import { HttpClientProxy } from './http-client-proxy'

export abstract class ApiServiceBase {
    protected readonly http: HttpClient

    constructor(protected readonly serviceBase: string, http: HttpClient) {
        this.http = HttpClientProxy.create(http)
    }

    protected get<T>(endPoint: string, params: Params = {}): Observable<T> {
        params = this.flattenParams(params)
        return this.http.get<T>(`${this.serviceBase}/${endPoint}`, { params })
    }

    protected flattenParams(params: Params): Params {
        // const cleanedParams = fromUtil.omitEmptyValues(params);
        const cleanedParams = fromUtil.omitNullOrUndefinedValues(params)
        return flatten<Params, {}>(cleanedParams, { safe: true })
    }

    protected getAsApiResponse<T>(endPoint: string, params: Params = {}): Observable<ApiResponse<T>> {
        return ApiResponse.inverseMap(this.get<T>(endPoint, params))
    }

    protected getAndMapAsApiResponse<T>(endPoint: string, mapFunction: (input: any) => any, params: Params = {}): Observable<ApiResponse<T>> {
        return ApiResponse.inverseMap(this.getAndMap<T>(endPoint, mapFunction, params))
    }

    protected getAndMap<T>(endPoint: string, mapFunction: (input: any) => any, params: Params = {}): Observable<T> {
        // params = this.flattenParams(params);
        return this.http.get<T>(`${this.serviceBase}/${endPoint}`, { params }).pipe(map(mapFunction))
    }

    protected putAsApiResponse<T>(endPoint: string, body: any | null, params: Params = {}): Observable<ApiResponse<T>> {
        return ApiResponse.inverseMap(this.put<T>(endPoint, body, params))
    }

    protected put<T>(endPoint: string, body: any | null, params: Params = {}): Observable<T> {
        return this.http.put<T>(`${this.serviceBase}/${endPoint}`, body, {
            params,
        })
    }

    protected post<T>(endPoint: string, body: any | null, params: Params = {}): Observable<T> {
        return this.http.post<T>(`${this.serviceBase}/${endPoint}`, body, {
            params,
        })
    }

    protected postAsApiResponse<T>(endPoint: string, body: any | null, params: Params = {}): Observable<ApiResponse<T>> {
        return ApiResponse.inverseMap(this.post<T>(endPoint, body, params))
    }
}
