import { HttpErrorResponse } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { ApiResponseCode } from './api-response-code'
import { ApiResponseData } from './api-response-data'
import { httpErrorResponseFactory } from './http-error-response-factory'

/**
 * An `Observable` reference to a typed `ApiResponse`.
 */
export type AsyncApiResponse<T> = Observable<ApiResponse<T>>

/**
 * The structure returned by API methods.
 */
export class ApiResponse<T> implements ApiResponseData<T> {
    readonly code: string
    readonly result: T
    readonly message?: string
    readonly error?: any

    private constructor(response: string | ApiResponseData<T>, result?: T | string) {
        if (typeof response === 'string') {
            this.code = response
            if (this.isOk) {
                this.result = result as T
            } else if (typeof result === 'string') {
                this.message = result
            }
        } else if (response != undefined) {
            this.code = response.code
            this.result = response.result
            this.error = response.error
            this.message = response.message
        }
    }

    get isOk(): boolean {
        return this.code === ApiResponseCode.Ok
    }

    errorFrom(): Error {
        if (this.error) return this.error
        if (this.message) return new Error(`[API Error][${this.code}][${this.message}]`)
        return new Error(`[API Error][${this.code}]`)
    }

    static ok<T>(result?: T): ApiResponse<T> {
        return new ApiResponse(ApiResponseCode.Ok, result)
    }

    static error(error: ApiResponseCode | string | HttpErrorResponse | Error, message?: string): ApiResponse<any> {
        if ((<HttpErrorResponse>error).status != undefined) return ApiResponse.errorFromHttpResponse(<any>error)

        if (error instanceof Error) return new ApiResponse(ApiResponseCode.ApplicationError, error.message)

        return new ApiResponse(<string>error, message)
    }

    static errorFromHttpResponse(response: HttpErrorResponse): ApiResponse<any> {
        let data = httpErrorResponseFactory(response)
        return new ApiResponse(data)
    }

    static resolve<T>(result?: T): Promise<ApiResponse<T>> {
        return Promise.resolve(new ApiResponse(ApiResponseCode.Ok, result))
    }

    static resolve$<T>(result?: T): Observable<ApiResponse<T>> {
        return of(new ApiResponse(ApiResponseCode.Ok, result))
    }

    /** Maps the output of an Observable<ApiResponse<T>> instance to an Observable<T> instance. */
    static map<T>(response: Observable<ApiResponse<T>>, raiseError: boolean = true): Observable<T> {
        return response.pipe(
            tap(response => {
                if (response.isOk || !raiseError) return
                throw response.errorFrom()
            }),
            map(response => response.result)
        )
    }

    /** Maps the output of an Observable<T> instance to an instance of Observable<ApiResponse<T>>. */
    static inverseMap<T>(source: Observable<T>): Observable<ApiResponse<T>> {
        return source.pipe(
            map(result => ApiResponse.ok(result)),
            catchError(error => of(ApiResponse.error(error)))
        )
    }
}
