import { Component, OnInit, Inject, OnChanges } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core'
import { FormGroup } from '@angular/forms'
import { ColumnDefinition } from '@plantandfood/kup.core'

@Component({
    selector: 'app-column-config-modal',
    templateUrl: './column-config-modal.component.html',
    styleUrls: ['./column-config-modal.component.scss'],
})
export class ColumnConfigModalComponent implements OnInit, OnChanges {
    availableColumns: any[] = []

    form = new FormGroup({})
    model: any = {}
    options: FormlyFormOptions = {}
    fields: FormlyFieldConfig[]

    constructor(public dialogRef: MatDialogRef<ColumnConfigModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        this.availableColumns = this.data.columns
    }

    ngOnChanges() {
        this.availableColumns = this.data.columns
    }

    executeQuery() {
        this.dialogRef.close(this.availableColumns)
    }

    onClose() {
        this.dialogRef.close()
    }

    onToggleShow(col: ColumnDefinition) {
        const index = this.availableColumns.indexOf(col)
        this.availableColumns[index].show = !this.availableColumns[index].show
    }
}
