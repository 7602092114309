import { RequestAction, RequestMessage } from './../../../app/constants/request-type.constants'
import { Component, Inject, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core'

@Component({
    selector: 'pfr-change-status-modal',
    templateUrl: './change-status-modal.component.html',
    styleUrls: ['./change-status-modal.component.scss'],
})
export class ChangeStatusModalComponent implements OnInit {
    model = <any>{}
    options: FormlyFormOptions = {}
    fields: FormlyFieldConfig[]
    form = new FormGroup({})
    title: string
    message: string
    regexStr: string

    constructor(public dialogRef: MatDialogRef<ChangeStatusModalComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

    ngOnInit() {
        const str = this.data.action.toLowerCase()
        this.title = str.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, newStr => newStr.toUpperCase())

        switch (this.data.action) {
            case RequestAction.SEND_FOR_REVIEW:
                this.regexStr = this.data.recipient
                this.message = RequestMessage.SEND_FOR_REVIEW.replace('recipient', this.data.recipient)

                break
            case RequestAction.REQUEST_FEEDBACK:
                this.regexStr = this.data.requester
                this.message = RequestMessage.REQUEST_FEEDBACK.replace('requester', this.data.requester)
                break
            case RequestAction.START:
                this.message = RequestMessage.START
                break
            case RequestAction.COMPLETE:
                this.message = RequestMessage.COMPLETE
                break
        }

        this.fields = [
            {
                key: 'notes',
                id: 'field_notes',
                type: 'textarea',
                className: 'change-status-txt',
                templateOptions: {
                    label: 'Comments',
                    rows: 3,
                    maxLength: 255,
                },
            },
        ]
    }

    onClose(model?) {
        this.dialogRef.close(model)
    }
}
