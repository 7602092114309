import { ActivatedRoute } from '@angular/router'

export class ErrorPage {
    appCode: string
    appMessage: string

    constructor(private route: ActivatedRoute, public title: string, public message: string) {
        const params = this.route.snapshot.queryParams
        this.appCode = params.code
        this.appMessage = params.message
    }
}
